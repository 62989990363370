import type { MutationTree } from 'vuex'
import type {
  ISession,
  TMapSession,
  ISessionState,
} from '@/store/modules/client/session/types'

type TSessionMutation = MutationTree<ISessionState>

import Vue from 'vue'
import { setItemStorage } from '@/helpers/local-storage'
import { setLoading } from '@/helpers/mutation-functions'

export const mutations: TSessionMutation = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_SESSION
   * ? Установить сессию для переданного бэкапа или архива
   *
   * @param {ISessionState} state data
   * @param {ISession} session сессия бэкапа или архива
   * @returns {void}
   */
  SET_SESSION: (state: ISessionState, session: ISession): void => {
    const { id, backup_name } = session

    state.session === null
      ? Vue.set(state, 'session', { [backup_name]: id })
      : Vue.set(state.session, backup_name, id)

    setItemStorage('backup-session', state.session)
  },

  /**
   * SET_MAP_SESSION
   * ? Установить карту ранее сохраненных сессий
   *
   * @param {ISessionState} state data
   * @param {TMapSession} mapSession карта ранее сохраненных сессий
   * @returns {void}
   */
  SET_MAP_SESSION: (state: ISessionState, mapSession: TMapSession): void => {
    state.session = mapSession
  },

  /**
   * SET_VISIBILITY_ENCRYPTED_MODAL
   * ? Установить видимость модального окна зашифрованных бэкапов или архивов
   *
   * @param {ISessionState} state data
   * @param {boolean} isVisible признак видимости модального окна зашифрованных бэкапов или архивов
   * @returns {void}
   */
  SET_VISIBILITY_ENCRYPTED_MODAL: (
    state: ISessionState,
    isVisible: boolean
  ): void => {
    state.isVisibleEncryptedModal = isVisible
  },

  /**
   * DELETE_SESSION
   * ? Удалить сессию конкретного бэкапа, если ранее он был создан
   *
   * @param {ISessionState} state data
   * @param {string} backupName имя бэкапа
   * @returns {void}
   */
  DELETE_SESSION: (state: ISessionState, backupName: string): void => {
    if (state.session === null || !(backupName in state.session)) {
      return
    }

    Vue.delete(state.session, backupName)
    setItemStorage('backup-session', state.session)
  },
}
