import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { IWrBackupItemResponse, IWrBackupVersionItem } from '@/api/types'
import type {
  ArchiveState,
  IArchiveItems,
  ArchiveLocalMeta,
  IArchivePathParams,
  IDirectoryArchiveMeta,
  IDirectoryArchiveOperation,
  IWrLocaleArchiveItemResponse,
} from '@/store/modules/client/archive/types'

type ArchiveTree = GetterTree<ArchiveState, RootState>

import cloneDeep from 'lodash/cloneDeep'
import { getWrIcons } from '@/helpers/wr-icons'
import { getTotalSize, getLastUpdatedDate } from '@/helpers/storage'
import {
  getFormattedDate,
  getFormattedSize,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

export const getters: ArchiveTree = {
  /**
   * mapArchiveData
   * ? Объект архивов со списком директорий/файлов, статусом и id операции
   *
   * @param {ArchiveState} state data
   * @returns {Partial<IDirectoryArchiveOperation>} объект архивов со списком директорий/файлов, статусом и id операции
   */
  mapArchiveData: (
    state: ArchiveState
  ): Partial<IDirectoryArchiveOperation> => {
    return state.mapArchiveData
  },

  /**
   * mapArchiveMeta
   * ? Объект архивов с мета данными
   *
   * @param {ArchiveState} state data
   * @returns {Partial<IDirectoryArchiveMeta>} объект архивов с мета данными
   */
  mapArchiveMeta: (state: ArchiveState): Partial<IDirectoryArchiveMeta> => {
    return state.mapArchiveMeta
  },

  /**
   * defaultArchiveMeta
   * ? Дефолтные мета данные архива
   *
   * @param {ArchiveState} state data
   * @returns {ArchiveLocalMeta} дефолтные мета данные архива
   */
  defaultArchiveMeta: (state: ArchiveState): ArchiveLocalMeta => {
    return state.defaultArchiveMeta
  },

  /**
   * treeDirectoryPath
   * ? Список сформированных путей директорий дерева
   *
   * @param {ArchiveState} state data
   * @returns {string[]} список сформированных путей директорий дерева
   */
  treeDirectoryPath: (state: ArchiveState): string[] => {
    return state.treeDirectoryPath
  },

  /**
   * currentView
   * ? Текущий вид архивов
   *
   * @param {ArchiveState} state data
   * @returns {string} текущий вид архивов
   */
  currentView: (state: ArchiveState): string => {
    return state.currentView
  },

  /**
   * currentArchiveFolder
   * ? Имя текущей директории (папки) архива
   *
   * @param {ArchiveState} state data
   * @returns {string} имя текущей директории (папки) архива
   */
  currentArchiveFolder: (state: ArchiveState): string => {
    return state.currentFolder
  },

  /**
   * currentArchiveName
   * ? Имя текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {string} имя текущего архива
   */
  currentArchiveName: (state: ArchiveState): string => {
    return state.currentArchiveName
  },

  /**
   * currentArchiveDisplayName
   * ? Текущее отображаемое имя архива
   *
   * @param {ArchiveState} state data
   * @returns {string} текущее отображаемое имя архива
   */
  currentArchiveDisplayName: (state: ArchiveState): string => {
    return state.currentArchive?.display_name ?? ''
  },

  /**
   * currentArchiveVersion
   * ? Текущая версия архива
   *
   * @param {ArchiveState} state data
   * @returns {IWrBackupVersionItem  | null} текущая версия архива
   */
  currentArchiveVersion: (state: ArchiveState): IWrBackupVersionItem | null => {
    return state.currentArchiveVersion
  },

  /**
   * currentArchiveVersionName
   * ? Название текущей версия резервной копии
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {string} название текущей версия резервной копии
   */
  currentArchiveVersionName: (state: ArchiveState, getters): string => {
    return getters.currentArchiveVersion?.name ?? ''
  },

  /**
   * currentArchiveVersionList
   * ? Список версий текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {IWrBackupVersionItem[]} список версий текущего архива
   */
  currentArchiveVersionList: (state: ArchiveState): IWrBackupVersionItem[] => {
    return cloneDeep(state.currentArchiveVersionList).sort(
      (a: IWrBackupVersionItem, b: IWrBackupVersionItem) =>
        Number(b.name) - Number(a.name)
    )
  },

  /**
   * currentDirectoryArchiveItem
   * ? Текущий элемент директории архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {IArchiveItems} текущий элемент директории архива
   */
  currentDirectoryArchiveItem: (
    state: ArchiveState,
    getters
  ): IArchiveItems => {
    return (
      state.mapArchiveData[getters.currentArchivePathParams.backupPath] ?? {}
    )
  },

  /**
   * currentDirectoryArchiveMeta
   * ? Текущие мета данные директории архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {ArchiveLocalMeta} текущие мета данные директории архива
   */
  currentDirectoryArchiveMeta: (
    state: ArchiveState,
    getters
  ): ArchiveLocalMeta => {
    return (
      state.mapArchiveMeta[getters.currentArchivePathParams.backupPath] ??
      state.defaultArchiveMeta
    )
  },

  /**
   * currentArchive
   * ? Содержимое текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {IWrLocaleArchiveItemResponse | null } содержимое текущего архива
   */
  currentArchive: (
    state: ArchiveState
  ): IWrLocaleArchiveItemResponse | null => {
    if (state.currentArchive === null) {
      return null
    }

    const { size, name, subtype, updated_at, display_name } =
      state.currentArchive

    const params = { name, versions: state.currentArchiveVersionList }
    const totalSize = getTotalSize(params)
    const lastUpdatedDate = getLastUpdatedDate(params)

    return {
      ...state.currentArchive,
      icon: getWrIcons(subtype),
      formatSize: getFormattedSize(size),
      formatTotalArchiveSize: getFormattedSize(totalSize),
      formatUpdatedItemDate: getFormattedDate(updated_at),
      formatDisplayName: getFormattedSimpleValue(display_name),
      formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
      formatLastUpdatedArchiveDateTime: getFormattedDate(lastUpdatedDate, true),
    }
  },

  /**
   * currentArchiveDirectory
   * ? Текущая (выбранная) директория архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {IWrBackupItemResponse[]} текущая (выбранная) директория архива
   */
  currentArchiveDirectory: (
    state: ArchiveState,
    getters
  ): IWrBackupItemResponse[] => {
    const archiveItems = getters.currentDirectoryArchiveItem.items ?? []

    return archiveItems.map((archive: IWrBackupItemResponse) => {
      const { size, type, subtype, updated_at } = archive

      return {
        ...archive,
        icon: getWrIcons(subtype ?? type),
        formatSize: getFormattedSize(size),
        formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
      }
    })
  },

  /**
   * currentArchivePathParams
   * ? Параметры текущего пути директории
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @param {RootState} rootState root data
   * @returns {IArchivePathParams} параметры текущего пути директории
   */
  currentArchivePathParams: (
    state: ArchiveState,
    getters,
    rootState
  ): IArchivePathParams => {
    const pathMatch = rootState.nav?.route?.params?.pathMatch
    const { treeDirectoryPath, currentArchiveName, currentArchiveDisplayName } =
      getters

    const backup = currentArchiveName
    const path = treeDirectoryPath.join('/')
    const queryPath = pathMatch ?? path
    const backupPath = [backup, ...treeDirectoryPath].join('/')
    const folder = treeDirectoryPath.at(-1) ?? currentArchiveDisplayName
    const directories = [currentArchiveDisplayName, ...treeDirectoryPath]
    const queryBackupPath = pathMatch ? `${backup}/${pathMatch}` : backup

    return {
      path,
      backup,
      folder,
      queryPath,
      backupPath,
      directories,
      queryBackupPath,
    }
  },

  /**
   * hasArchiveVersion
   * ? Признак что версия архива существует
   *
   * @param {ArchiveState} state data
   * @returns {boolean} признак что версия архива существует
   */
  hasArchiveVersion: (state: ArchiveState): boolean => {
    return state.currentArchiveVersion !== null
  },

  /**
   * isEncryptedArchive
   * ? Признак зашифрованного архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} признак зашифрованного архива
   */
  isEncryptedArchive: (state: ArchiveState): boolean => {
    return Boolean(state.currentArchive?.encrypted)
  },

  /**
   * isCachedCurrentDirectoryArchiveItem
   * ? Признак того, что элементы архива за кешированы
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {boolean} признак того, что элементы архива за кешированы
   */
  isCachedCurrentDirectoryArchiveItem: (
    state: ArchiveState,
    getters
  ): boolean => {
    return getters.currentArchivePathParams.backupPath in state.mapArchiveData
  },

  /**
   * isLoadingCurrentArchive
   * ? Процесс загрузки текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки текущего архива
   */
  isLoadingCurrentArchive: (state: ArchiveState): boolean => {
    return state.isLoadingCurrentArchive
  },

  /**
   * isLoadingUrlArchiveImage
   * ? Процесс загрузки текущего пути изображения
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки текущего пути изображения
   */
  isLoadingUrlArchiveImage: (state: ArchiveState): boolean => {
    return state.isLoadingUrlArchiveImage
  },

  /**
   * isLoadingCurrentArchiveVersionList
   * ? Процесс загрузки списка версий текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки списка версий текущего архива
   */
  isLoadingCurrentArchiveVersionList: (state: ArchiveState): boolean => {
    return state.isLoadingCurrentArchiveVersionList
  },

  /**
   * isLoadingFirstDirectoryArchiveItems
   * ? Процесс загрузки первых директорий архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки первых директорий архива
   */
  isLoadingFirstDirectoryArchiveItems: (state: ArchiveState): boolean => {
    return state.isLoadingFirstDirectoryArchiveItems
  },

  /**
   * isLoadingNextDirectoryArchiveItems
   * ? Процесс загрузки следующих директорий архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки следующих директорий архива
   */
  isLoadingNextDirectoryArchiveItems: (state: ArchiveState): boolean => {
    return state.isLoadingNextDirectoryArchiveItems
  },

  /**
   * isLoadingCurrentArchiveFileDownload
   * ? Процесс скачивания выбранного файла архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс скачивания выбранного файла архива
   */
  isLoadingCurrentArchiveFileDownload: (state: ArchiveState): boolean => {
    return state.isLoadingCurrentArchiveFileDownload
  },
}
