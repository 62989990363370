import type { SkusState, SkusModule } from '@/store/modules/admin/skus/types'

import { getters } from '@/store/modules/admin/skus/getters'
import { actions } from '@/store/modules/admin/skus/actions'
import { mutations } from '@/store/modules/admin/skus/mutations'

const state: SkusState = {
  skus: [],
  features: [],
  isLoadingFeatures: false,
  isLoadingCategorySkus: false,
}

export const skus: SkusModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
