import store from '@/store'
import { ROLES as R, ACTIONS as A } from '@/config/constants'

const RULES = new Map()

// LAYOUTS
RULES.set(A.VIEW_LAYOUT_ADMINISTRATION, [
  R.ADMIN,
  R.DEVOPS,
  R.VIEWER,
  R.SUPPORT,
])

// PAGES
RULES.set(A.VIEW_PAGE_SKUS, [R.ADMIN])
RULES.set(A.VIEW_PAGE_ORDERS, [R.ADMIN])
RULES.set(A.VIEW_PAGE_CAMPAIGNS, [R.ADMIN])
RULES.set(A.VIEW_PAGE_USERS, [R.ADMIN, R.DEVOPS, R.VIEWER, R.SUPPORT])
RULES.set(A.VIEW_PAGE_LICENSES, [R.ADMIN, R.VIEWER, R.SUPPORT])

// SECTIONS
RULES.set(A.VIEW_SECTION_AVAILABLE_LICENSES, [R.ADMIN])
RULES.set(A.VIEW_SECTION_ASSIGNED_LICENSES, [R.ADMIN, R.VIEWER, R.SUPPORT])
RULES.set(A.VIEW_SECTION_USER_CARD_ORDERS, [R.ADMIN])
RULES.set(A.VIEW_SECTION_USER_CARD_BACKUPS, [R.ADMIN, R.DEVOPS, R.SUPPORT])
RULES.set(A.VIEW_SECTION_USER_CARD_EXPIRED_LICENSES, [
  R.ADMIN,
  R.VIEWER,
  R.SUPPORT,
])
RULES.set(A.VIEW_SECTION_USER_CARD_ACTIVE_LICENSES, [
  R.ADMIN,
  R.VIEWER,
  R.SUPPORT,
])

// ELEMENTS
RULES.set(A.VIEW_ELEMENT_ADD_SKU, [R.ADMIN])
RULES.set(A.VIEW_ELEMENT_ADD_CAMPAIGN, [R.ADMIN])

RULES.set(A.VIEW_ELEMENT_DELETE_BACKUP, [R.ADMIN])
RULES.set(A.VIEW_ELEMENT_DELETE_ACCOUNT, [R.ADMIN])
RULES.set(A.VIEW_ELEMENT_DELETE_BACKUP_WITH_CONDITION, [R.DEVOPS])

RULES.set(A.VIEW_ELEMENT_LICENSE_CHECKBOXES, [R.ADMIN])
RULES.set(A.VIEW_ELEMENT_ACCOUNT_CHECKBOXES, [R.ADMIN, R.DEVOPS])

RULES.set(A.VIEW_ELEMENT_REVOKE_LICENSES, [R.ADMIN])
RULES.set(A.VIEW_ELEMENT_TRANSFER_LICENSES, [R.ADMIN])
RULES.set(A.VIEW_ELEMENT_GENERATE_LICENSE_KEY, [R.ADMIN])
RULES.set(A.VIEW_ELEMENT_VERIFY_EMAIL, [R.ADMIN, R.SUPPORT])
RULES.set(A.VIEW_ELEMENT_ACTIVATE_ACCOUNT, [R.ADMIN, R.SUPPORT])

RULES.set(A.VIEW_ELEMENT_EXPORT_SKUS, [R.ADMIN])
RULES.set(A.VIEW_ELEMENT_EXPORT_ORDERS, [R.ADMIN])
RULES.set(A.VIEW_ELEMENT_EXPORT_CAMPAIGNS, [R.ADMIN])
RULES.set(A.VIEW_ELEMENT_EXPORT_USERS, [R.ADMIN, R.SUPPORT])
RULES.set(A.VIEW_ELEMENT_EXPORT_LICENSES, [R.ADMIN, R.SUPPORT])

// LINKS
RULES.set(A.ACTIVATE_LINK_TO_ORDER, [R.ADMIN])
RULES.set(A.ACTIVATE_LINK_TO_CAMPAIGN, [R.ADMIN])

// REQUESTS
RULES.set(A.GET_MARKET_V1_ORDERS, [R.ADMIN])
RULES.set(A.GET_REPORT_V1_AVAILABLE_LICENSES, [R.ADMIN])
RULES.set(A.GET_WEBRESTORE_V1_BACKUP_FILE, [R.ADMIN, R.DEVOPS, R.SUPPORT])

export default (action: string, additional = true): boolean => {
  const ROLE = store.getters['account/userRole']

  if (!action) {
    console.warn('[PERMISSIONS_ACTION]: You have to pass the action')
    return false
  }

  if (!RULES.has(action)) {
    console.warn(
      `[PERMISSIONS_ACTION]: ${action} does not exist in the list of actions`
    )
    return false
  }

  return RULES.get(action).includes(ROLE) && additional
}
