import type { MutationTree } from 'vuex'
import type { IReportCampaignItem } from '@/api/types'
import type {
  ICampaignsState,
  ICampaignsLocalMeta,
} from '@/store/modules/admin/campaigns/types'

type TCampaignsTree = MutationTree<ICampaignsState>

import { isEmptyObject } from '@/helpers/checks'
import { setLoading } from '@/helpers/mutation-functions'

export const mutations: TCampaignsTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_REPORT_CAMPAIGNS
   * ? Установить список кампаний
   *
   * @param {ICampaignsState} state data
   * @param {IReportCampaignItem[]} campaigns список кампаний
   * @returns {void}
   */
  SET_REPORT_CAMPAIGNS: (
    state: ICampaignsState,
    campaigns: IReportCampaignItem[]
  ): void => {
    state.reportCampaigns = campaigns
  },

  /**
   * CLEAR_REPORT_CAMPAIGNS
   * ? Очистить список кампаний
   *
   * @param {ICampaignsState} state data
   * @returns {void}
   */
  CLEAR_REPORT_CAMPAIGNS: (state: ICampaignsState): void => {
    state.reportCampaigns = []
  },

  /**
   * SET_REPORT_CAMPAIGNS_CURRENT_META
   * ? Установить текущие мета данные списка кампаний
   *
   * @param {ICampaignsState} state data
   * @param {ICampaignsLocalMeta} meta текущие мета данные списка кампаний
   * @returns {void}
   */
  SET_REPORT_CAMPAIGNS_CURRENT_META: (
    state: ICampaignsState,
    meta: ICampaignsLocalMeta
  ): void => {
    if (isEmptyObject(meta)) {
      state.currentReportCampaignsMeta = structuredClone(
        state.defaultReportCampaignsMeta
      )
      return
    }

    state.currentReportCampaignsMeta = {
      ...state.currentReportCampaignsMeta,
      ...meta,
    }
  },
}
