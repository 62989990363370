import Vue from 'vue'
import api from '@/api'
import i18n from '@/locale'
import store from '@/store'
import router from '@/router'
import UIKit from '@uikit/ui-kit'
import VpcApp from '@/vpc-app.vue'
import hasPermission from '@/helpers/permissions'
import UiLoader from '@/components/common/ui-loader'
import UiScrollbar from '@/components/common/ui-scrollbar'

import '@/initializers'

Vue.use(UiLoader)
Vue.use(UiScrollbar)
Vue.use(UIKit, { i18n })

// ? в контексте vue развернуто $api, для отладки методов из консоли
Vue.prototype.$api = api
Vue.prototype.$hasPermission = hasPermission
Vue.config.productionTip = false

store.dispatch('beforeInitApp').finally(
  () =>
    (App = new Vue({
      i18n,
      store,
      router,
      render: (r) => r(VpcApp),
    }).$mount('#app'))
)

export let App = new Vue()
