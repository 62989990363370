import type {
  TApiFormErrors,
  IErrorResponse,
  TBackupErrorResponse,
} from '@/api/types'
import type { TranslateResult } from 'vue-i18n'

import { App } from '@/main'
import { API_ERROR, VALIDATION_LENGTH } from '@/config/constants'

const API_FORM_ERRORS: TApiFormErrors = {
  [API_ERROR.LICENSE_REVOKED]: {
    key: {
      code: API_ERROR.LICENSE_REVOKED,
      message: 'form.field.validate.wrong-license-key',
    },
  },
  [API_ERROR.LICENSE_NOT_FOUND]: {
    key: {
      code: API_ERROR.LICENSE_NOT_FOUND,
      message: 'form.field.validate.wrong-license-key',
    },
  },
  [API_ERROR.NO_LICENSED_STORAGE_QUOTA]: {
    key: {
      code: API_ERROR.NO_LICENSED_STORAGE_QUOTA,
      message: 'form.field.validate.wrong-license-key',
    },
  },
  [API_ERROR.OPERATION_NOT_AVAILABLE]: {
    key: {
      code: API_ERROR.OPERATION_NOT_AVAILABLE,
      message: 'form.field.validate.wrong-license-key',
    },
  },
  [API_ERROR.INVALID_LICENSE_ID]: {
    key: {
      code: API_ERROR.INVALID_LICENSE_ID,
      message: 'form.field.validate.wrong-license-key',
    },
  },
  [API_ERROR.INVALID_PHONE]: {
    phone: {
      code: API_ERROR.INVALID_PHONE,
      message: 'form.field.validate.phone-incorrect',
    },
  },
  [API_ERROR.INVALID_FIRST_NAME]: {
    firstName: {
      code: API_ERROR.INVALID_FIRST_NAME,
      message: 'form.field.validate.max-value',
      messageOptions: { length: VALIDATION_LENGTH.MAX_64 },
    },
  },
  [API_ERROR.INVALID_LAST_NAME]: {
    lastName: {
      code: API_ERROR.INVALID_LAST_NAME,
      message: 'form.field.validate.max-value',
      messageOptions: { length: VALIDATION_LENGTH.MAX_64 },
    },
  },
  [API_ERROR.PARAMETER_LENGTH_TOO_LONG]: {
    subject: {
      code: API_ERROR.PARAMETER_LENGTH_TOO_LONG,
      message: 'form.field.validate.max-value',
      messageOptions: { length: VALIDATION_LENGTH.MAX_256 },
    },
  },
  [API_ERROR.PARAMETER_LENGTH_TOO_LONG]: {
    description: {
      code: API_ERROR.PARAMETER_LENGTH_TOO_LONG,
      message: 'form.field.validate.max-value',
      messageOptions: { length: VALIDATION_LENGTH.MAX_10000 },
    },
  },
  [API_ERROR.INCORRECT_LOGIN]: {
    emailRegister: {
      code: API_ERROR.INCORRECT_LOGIN,
      message: 'form.field.validate.email-incorrect',
    },
  },

  [API_ERROR.INCORRECT_PASSWORD]: {
    emailLogin: {
      code: API_ERROR.INCORRECT_LOGIN,
    },
    passwordEncrypted: {
      code: API_ERROR.INCORRECT_PASSWORD,
      message: 'form.field.validate.password-wrong',
    },
    oldPassword: {
      action: true, // Show button - Forgot password
      code: API_ERROR.INCORRECT_PASSWORD,
      message: 'form.field.validate.old-password-wrong',
    },

    passwordRegister: {
      code: API_ERROR.INCORRECT_PASSWORD,
      message: 'form.field.validate.password-incorrect',
    },
    passwordActivate: {
      code: API_ERROR.INCORRECT_PASSWORD,
      message: 'form.field.validate.password-incorrect',
    },
    passwordLogin: {
      code: API_ERROR.INCORRECT_PASSWORD,
      message: 'form.field.validate.email-or-password-incorrect',
    },
  },
  [API_ERROR.INCORRECT_NEW_PASSWORD]: {
    newPassword: {
      code: API_ERROR.INCORRECT_NEW_PASSWORD,
      message: 'form.field.validate.password-incorrect',
    },
  },
  [API_ERROR.INCORRECT_CONFIRMATION_DATA]: {
    passwordAccountDelete: {
      code: API_ERROR.INCORRECT_CONFIRMATION_DATA,
      message: 'form.field.validate.password-wrong',
    },
  },
  // Совпадение проверятся по ID кампании
  [API_ERROR.CAMPAIGN_ALREADY_EXISTS]: {
    name: {
      code: API_ERROR.CAMPAIGN_ALREADY_EXISTS,
      message: 'form.field.validate.unique-name',
    },
  },
  [API_ERROR.CAMPAIGN_NAME_ALREADY_EXISTS]: {
    name: {
      code: API_ERROR.CAMPAIGN_NAME_ALREADY_EXISTS,
      message: 'form.field.validate.unique-name',
    },
  },
  [API_ERROR.SKU_ID_ALREADY_EXISTS]: {
    id: {
      code: API_ERROR.SKU_ID_ALREADY_EXISTS,
      message: 'form.field.validate.sku.unique-id',
    },
  },
  [API_ERROR.INVALID_SKU_ID]: {
    id: {
      code: API_ERROR.INVALID_SKU_ID,
      message: 'form.field.validate.sku.invalid-id',
    },
  },
}

class ApiDeleteBackupError extends Error {
  private error: TBackupErrorResponse

  constructor(error: TBackupErrorResponse) {
    super()
    this.error = error
  }

  public static hasBackupErrors(
    error: TBackupErrorResponse | IErrorResponse | unknown
  ): error is TBackupErrorResponse {
    return (
      error !== null &&
      typeof error === 'object' &&
      Object.hasOwn(error, 'context')
    )
  }

  /**
   * ? getBackupInUseArchives
   *
   * Возвращает названия архивов которые на текущий момент используются
   *
   * @returns {string[]} названия архивов
   */
  public getBackupInUseArchiveNames(): string[] {
    return Object.keys(this.error.context).filter(
      (key) => this.error.context[key].code === 'BACKUP_IN_USE'
    )
  }

  /**
   * ? getErrorMessage
   *
   * Возвращает подготовленное для пользователя сообщение об ошибке
   *
   * @returns {TranslateResult} сообщение об ошибка
   */
  public getErrorMessage(): TranslateResult {
    const names = this.getBackupInUseArchiveNames()

    return App.$i18n.tc('notify.delete-backup.backup-in-use', names.length, {
      names: names.map((item) => `«${decodeURIComponent(item)}»`).join(', '),
    })
  }
}

export { API_FORM_ERRORS, ApiDeleteBackupError }
