import type { MutationTree } from 'vuex'
import type {
  UsersState,
  IUsersLocalMeta,
  TUserAccountsState,
  TReportUserAccountsState,
} from '@/store/modules/admin/users/types'

import { isEmptyObject } from '@/helpers/checks'
import { setLoading } from '@/helpers/mutation-functions'

type UsersTree = MutationTree<UsersState>

export const mutations: UsersTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_USERS
   * ? Установить список пользовательских аккаунтов
   *
   * @param {UsersState} state data
   * @param {TUserAccountsState} users список пользовательских аккаунтов
   * @returns {void}
   */
  SET_USERS: (state: UsersState, users: TUserAccountsState): void => {
    state.users = users
  },

  /**
   * SET_CURRENT_USERS_META
   * ? Установить текущие мета данные для списка пользователей
   *
   * @param {UsersState} state data
   * @param {IUsersLocalMeta} meta текущие мета данные для списка пользователей
   * @returns {void}
   */
  SET_CURRENT_USERS_META: (state: UsersState, meta: IUsersLocalMeta): void => {
    if (isEmptyObject(meta)) {
      state.currentUsersMeta = structuredClone(state.defaultUsersMeta)

      return
    }

    state.currentUsersMeta = {
      ...state.currentUsersMeta,
      ...meta,
    }
  },

  /**
   * SET_REPORT_USERS
   * ? Установить отчеты списков пользовательских аккаунтов
   *
   * @param {UsersState} state data
   * @param {TReportUserAccountsState} users  отчеты списков пользовательских аккаунтов
   * @returns {void}
   */
  SET_REPORT_USERS: (
    state: UsersState,
    users: TReportUserAccountsState
  ): void => {
    state.reportUsers = users
  },

  /**
   * SET_CURRENT_META_REPORT_USERS
   * ? Установить текущие мета данные для отчетов списка пользователей
   *
   * @param {UsersState} state data
   * @param {IUsersLocalMeta} meta текущие мета данные списка пользователей
   * @returns {void}
   */
  SET_CURRENT_META_REPORT_USERS: (
    state: UsersState,
    meta: IUsersLocalMeta
  ): void => {
    if (isEmptyObject(meta)) {
      state.currentReportUsersMeta = structuredClone(
        state.defaultReportUsersMeta
      )
      return
    }

    state.currentReportUsersMeta = { ...state.currentReportUsersMeta, ...meta }
  },

  /**
   * SET_REPORT_DELETED_ACCOUNT_IDS
   * ? Установить список отчетных идентификаторов удаленных аккаунтов
   *
   * @param {UsersState} state data
   * @param {string[]} ids список отчетных идентификаторов удаленных аккаунтов
   * @returns {void}
   */
  SET_REPORT_DELETED_ACCOUNT_IDS: (state: UsersState, ids: string[]): void => {
    state.deletedAccountIds = ids
  },

  /**
   * CLEAR_REPORT_DELETED_ACCOUNT_IDS
   * ? Очистить список отчетных идентификаторов удаленных аккаунтов
   *
   * @param {UsersState} state data
   * @returns {void}
   */
  CLEAR_REPORT_DELETED_ACCOUNT_IDS: (state: UsersState): void => {
    state.deletedAccountIds = []
  },
}
