export enum EReportType {
  ORDERS = 'orders',
  ACCOUNTS = 'accounts',
  CAMPAIGNS = 'campaigns',
  ASSIGNED_LICENSES = 'assigned_licenses',
  AVAILABLE_LICENSES = 'available_licenses',
}

export enum ELicenseType {
  TRIAL = 'trial',
  SUBSCRIPTION = 'subscribe',
}

export enum EReportSessionState {
  NEW = 'new',
  ERROR = 'error',
  REJECTED = 'rejected',
  COMPLETED = 'completed',
  INPROGRESS = 'inprogress',
}

export enum EOperationState {
  DONE = 'done',
  FAIL = 'fail',
  PROGRESS = 'progress',
}

export enum EDaDataCompanyStatus {
  ACTIVE = 'ACTIVE',
  LIQUIDATED = 'LIQUIDATED',
  LIQUIDATING = 'LIQUIDATING',
}
