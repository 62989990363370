/* eslint-disable jsdoc/check-param-names */
import type { MutationTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { IErrorResponse } from '@/api/types'

type RootTree = MutationTree<RootState>

export const mutations: RootTree = {
  /**
   * SET_LOADING
   * ? Установить статус выполнения запросов
   *
   * @param {RootState} state data
   * @param {boolean} loading статус выполнения запросов
   * @returns {void}
   */
  SET_LOADING: (state: RootState, loading: boolean): void => {
    state.isLoading = loading
  },

  /**
   * CLEAR_LOADERS
   * ? Очистить статусы всех активных запросов
   *
   * @param {RootState} state data
   * @param {?string} currentPage название текущего роута
   * @returns {void}
   */

  CLEAR_LOADERS: (state: RootState, currentPage?: string): void => {
    if (typeof currentPage !== 'string') {
      return
    }

    for (const stateKey in state) {
      const module = state[stateKey as keyof typeof state] as Record<
        string,
        boolean
      >
      const moduleKeys = Object.keys(module ?? {})

      for (const key of moduleKeys) {
        const matchedKey = key.match('isLoading')

        if (
          currentPage !== stateKey &&
          matchedKey?.index === 0 &&
          module[key] === true
        ) {
          module[key] = false
        }
      }
    }
  },

  /**
   * SET_SERVER_ERROR
   * ? Установить ошибку с сервера
   *
   * @param {RootState} state data
   * @param {IErrorResponse} error серверная ошибка
   * @returns {void}
   */
  SET_SERVER_ERROR: (state: RootState, error: IErrorResponse): void => {
    state.serverError = error
  },

  /**
   * CLEAR_SERVER_ERROR
   * ? Очистить ошибку сервера
   *
   * @param {RootState} state data
   * @returns {void}
   */
  CLEAR_SERVER_ERROR: (state: RootState): void => {
    state.serverError = null
  },

  /**
   * SET_DISPLAY_SIZES
   * ? Установить размеры документа
   *
   * @param {RootState} state data
   * @returns {void}
   */
  SET_DISPLAY_SIZES: (state: RootState): void => {
    state.displayWidth = window.innerWidth
    state.displayHeight = window.innerHeight
  },

  /**
   * OPEN_USER_COMMON_ERROR
   * ? Открыть модальное окно общей (статичной) ошибки (а именно ошибки которые не обрабатываются и имеют статус 4xx и 5xx)
   *
   * @param {RootState} state data
   * @param {string | null} message Текст ошибки
   * @returns {void}
   */
  OPEN_USER_COMMON_ERROR: (
    state: RootState,
    message: string | null = null
  ): void => {
    state.hasUserCommonError = true
    state.userCommonErrorMessage = message
  },

  /**
   * CLOSE_USER_COMMON_ERROR
   * ? Закрыть модальное окно общей (статичной) ошибки (а именно ошибки которые не обрабатываются и имеют статус 4xx и 5xx)
   *
   * @param {RootState} state data
   * @returns {void}
   */
  CLOSE_USER_COMMON_ERROR: (state: RootState): void => {
    state.hasUserCommonError = false
    state.userCommonErrorMessage = null
  },

  /**
   * OPEN_ADMIN_COMMON_ERROR
   * ? Открыть notify детализированной ошибки (а именно ошибки которые не обрабатываются и имеют статус 4xx и 5xx)
   *
   * @param {RootState} state data
   * @returns {void}
   */
  OPEN_ADMIN_COMMON_ERROR: (state: RootState): void => {
    state.hasAdminCommonError = true
  },

  /**
   * CLOSE_ADMIN_COMMON_ERROR
   * ? Закрыть notify детализированной ошибки (а именно ошибки которые не обрабатываются и имеют статус 4xx и 5xx)
   *
   * @param {RootState} state data
   * @returns {void}
   */
  CLOSE_ADMIN_COMMON_ERROR: (state: RootState): void => {
    state.hasAdminCommonError = false
  },
}
