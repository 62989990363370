import type {
  ProductState,
  ProductModule,
} from '@/store/modules/client/product/types'

import { getters } from '@/store/modules/client/product/getters'
import { mutations } from '@/store/modules/client/product/mutations'

const state: ProductState = {
  product: null,
}

export const product: ProductModule = {
  namespaced: true,
  state,
  getters,
  mutations,
}
