import type {
  IExternalProductsState,
  TExternalProductsModule,
} from '@/store/modules/business/external-products/types'

import { getters } from '@/store/modules/business/external-products/getters'
import { actions } from '@/store/modules/business/external-products/actions'
import { mutations } from '@/store/modules/business/external-products/mutations'

const state: IExternalProductsState = {
  downloadUrls: [],
  externalLicenses: [],
  externalLicensesMeta: {},
  hasTrialLicenses: false,
  hasExternalLicenses: false,
  isLoadingDownloadUrls: false,
  isLoadingExternalProduct: false,
  isLoadingExternalLicenses: false,
  isLoadingActivateTrialLicense: false,
  isLoadingAssignExternalLicense: false,
}

export const externalProducts: TExternalProductsModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
