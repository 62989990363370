import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type { AutoRenewOptions } from '@/api/types'
import type { ProductsState } from '@/store/modules/client/products/types'

import api from '@/api'

type ProductTree = ActionTree<ProductsState, RootState>
type ProductContext = ActionContext<ProductsState, RootState>

export const actions: ProductTree = {
  /**
   * fetchProducts
   * ? Загрузить список продуктов
   *
   * @param {ProductContext} param0 context
   * @param {Function} param0.commit mutation
   * @param {Function} param0.dispatch action
   * @returns {Promise<void>}
   */
  fetchProducts: ({ commit, dispatch }: ProductContext): Promise<void> => {
    const payload = {
      embed: 'sku',
      include_trial: true,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'products' })

    return api.lk
      .getAccountProducts(payload)
      .then((licenses) => {
        if (licenses.length > 0) {
          dispatch('fetchQuotas')
        }

        commit('SET_PRODUCTS', licenses)
        commit('SET_CATEGORIES')
      })
      .finally(() =>
        commit('SET_LOADING_PROCESS', { loading: false, name: 'products' })
      )
  },

  /**
   * fetchQuotas
   * ? Загрузить список квот
   *
   * @param {ProductContext} param0 context
   * @param {Function} param0.commit mutation
   * @returns {Promise<void>}
   */
  fetchQuotas: ({ commit, rootGetters }): Promise<void> => {
    const accountId = rootGetters['account/userAccountID']

    return api.lk
      .getAccountQuotas(accountId)
      .then((quotas) => commit('SET_QUOTAS', quotas))
      .catch(() => commit('SET_QUOTAS', []))
  },

  /**
   * setAutoRenew
   * ? Включить/выключить авто продление подписки
   *
   * @param {ProductContext} param0 context
   * @param {Function} param0.commit mutation
   * @param {AutoRenewOptions} options опции AutoRenewOptions
   * @returns {Promise<void>}
   */
  setAutoRenew: (
    { commit }: ProductContext,
    options: AutoRenewOptions
  ): Promise<void> => {
    return api.lk.setAutoRenewal(options).then(() => {
      commit('SET_AUTO_RENEW', options)
    })
  },
}
