import type {
  IReportSessionState,
  TReportSessionModule,
} from '@/store/modules/admin/report-session/types'

import { getters } from '@/store/modules/admin/report-session/getters'
import { actions } from '@/store/modules/admin/report-session/actions'
import { mutations } from '@/store/modules/admin/report-session/mutations'

const getSessionParams = () => ({
  id: '',
  status: null,
  timeoutId: 0,
  observe: false,
})

const state: IReportSessionState = {
  sessions: {
    orders: getSessionParams(),
    accounts: getSessionParams(),
    campaigns: getSessionParams(),
    assigned_licenses: getSessionParams(),
    available_licenses: getSessionParams(),
  },
  isLoadingGetReportSession: false,
  isLoadingCreateReportSession: false,
}

export const reportSession: TReportSessionModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
