import type { MutationTree } from 'vuex'
import type {
  BackupsState,
  BackupsLocalMeta,
  BackupVersionPayload,
} from '@/store/modules/client/backups/types'
import type { IWrBackupItemResponse } from '@/api/types'

type BackupsTree = MutationTree<BackupsState>

import Vue from 'vue'
import { isEmptyObject } from '@/helpers/checks'
import { setLoading } from '@/helpers/mutation-functions'

export const mutations: BackupsTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_BACKUPS
   * ? Установить список резервных копий
   *
   * @param {BackupsState} state data
   * @param {IWrBackupItemResponse[]} backups список резервных копий
   * @returns {void}
   */
  SET_BACKUPS: (
    state: BackupsState,
    backups: IWrBackupItemResponse[]
  ): void => {
    state.backups = backups
  },

  /**
   * SET_BACKUP_VERSIONS
   * ? Установить список версий резервных копий
   *
   * @param {BackupsState} state data
   * @param {BackupVersionPayload} payload список версий резервных копий
   * @returns {void}
   */
  SET_BACKUP_VERSIONS: (
    state: BackupsState,
    payload: BackupVersionPayload
  ): void => {
    const { name, versions } = payload
    Vue.set(state.versions, name, versions)
  },

  /**
   * CLEAR_BACKUPS
   * ? Очистить список резервных копий
   *
   * @param {BackupsState} state data
   * @returns {void}
   */
  CLEAR_BACKUPS: (state: BackupsState): void => {
    state.backups = []
  },

  /**
   * SET_BACKUPS_CURRENT_META
   * ? Установить текущие мета данные списка резервных копий
   *
   * @param {BackupsState} state data
   * @param {BackupsLocalMeta} meta текущие мета данные списка резервных копий
   * @returns {void}
   */
  SET_BACKUPS_CURRENT_META: (
    state: BackupsState,
    meta: BackupsLocalMeta
  ): void => {
    if (isEmptyObject(meta)) {
      state.currentBackupsMeta = structuredClone(state.defaultBackupsMeta)
      return
    }

    state.currentBackupsMeta = {
      ...state.currentBackupsMeta,
      ...meta,
    }
  },
}
