/**
 * isEmptyObject
 * ? Признак пустого объекта
 *
 * @param {unknown} value значение
 * @returns {boolean} признак пустого объекта
 */
export const isEmptyObject = (value: unknown): boolean => {
  if (typeof value !== 'object') {
    return true
  }

  return Object.keys(value || {}).length === 0
}

/**
 * isStringAsBoolean
 * ? Является ли "строковое" значение булевым типом
 *
 * @param {unknown} value значение
 * @returns {boolean} признак булева
 */
export const isStringAsBoolean = (value: unknown): boolean => {
  return typeof value === 'string' && ['true', 'false'].includes(value)
}

/**
 * isNumeric
 * ? Является ли значение числом
 *
 * @param {any} value значение
 * @returns {boolean} признак числа
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumeric = (value: any): boolean => {
  return !isNaN(parseFloat(value)) && !isNaN(value - 0)
}

/**
 * isISODate
 * ? Является ли строковое значение датой в формате ISO/JSON
 *
 * @param {unknown} value значение
 * @returns {boolean} признак даты в формате ISO/JSON
 */
export const isISODate = (value: unknown): boolean => {
  if (typeof value !== 'string') {
    return false
  }

  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.?\d*Z$/.test(value)
}
