import type { MutationTree } from 'vuex'
import type { ProductState } from '@/store/modules/client/product/types'
import type { ProductResponse } from '@/api/types'

type ProductTree = MutationTree<ProductState>

export const mutations: ProductTree = {
  /**
   * SET_PRODUCT_ITEM
   * ? Установить продукт
   *
   * @param {ProductState} state data
   * @param {ProductResponse} product продукт
   * @returns {void}
   */
  SET_PRODUCT_ITEM: (state: ProductState, product: ProductResponse): void => {
    state.product = product
  },
}
