import './loader.js'
import Vue from 'vue'
import store from '@/store'

interface GtmOptionPayload {
  goal?: string
  event?: string
  pageType?: string
}

declare global {
  interface Window {
    dataLayer: Array<GtmOptionPayload>
  }
}

const USE_GTM = JSON.parse(process.env.VUE_APP_USE_GTM as string)

/**
 * ? gtmPush
 * Установить данные для GTM (Google Tag Manager)
 * В себе всегда содержится актуальные состояние аутентификации
 *
 * @param {GtmOptionPayload} options опции для gtm
 * @param {string} options.goal триггер действия
 * @param {string} options.event название события
 * @param {string} options.pageType текущая страница
 * @returns {void}
 */
Vue.prototype.$gtmPush = (options: GtmOptionPayload = {}): void => {
  if (!USE_GTM) {
    return
  }

  const account = store.getters['account/gtmAccountProperties']
  const { event, goal } = options
  // Изменяю название свойства "goal" на "Goal" как этого требует отдел аналитики
  const additional = goal ? { event, Goal: goal } : options

  window?.dataLayer.push({
    ...account,
    ...additional,
  })
}
