import type { IExternalProductsState } from './types'
import type { RootState } from '@/store/types'
import type { ActionTree, ActionContext } from 'vuex'
import type {
  TLinkCompanyPayload,
  IExternalLicensesPayload,
  IExternalProductResponse,
  TAssignExternalLicensePayload,
  IExternalLicensesActivateTrialPayload,
} from '@/api/types'

import api from '@/api'
import i18n from '@/locale'
import { App } from '@/main'

type TExternalProductsTree = ActionTree<IExternalProductsState, RootState>
type TExternalProductsContext = ActionContext<IExternalProductsState, RootState>

export const actions: TExternalProductsTree = {
  /**
   * fetchExternalLicenses
   * ? Извлечь список внешних лицензий
   *
   * @param {TExternalProductsContext} ctx context
   * @param {IExternalLicensesPayload} payload параметры запроса внешних лицензий
   * @returns {Promise<void>}
   */
  fetchExternalLicenses: (
    { commit, dispatch }: TExternalProductsContext,
    payload: IExternalLicensesPayload
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'external-licenses' })

    return api.lk
      .getAllExternalLicenses(payload)
      .then(({ data, meta }) => {
        commit('SET_EXTERNAL_LICENSES', data)
        commit('SET_EXTERNAL_LICENSES_META', meta)
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED') {
          return dispatch('fetchExternalLicenses', payload)
        }
        return error
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'external-licenses',
        })
      })
  },

  /**
   * preFetchExternalLicenses
   * ? Получает ограниченный список лицензий для проверки наличия внешних и триальных лицензий
   *
   * @param {TExternalProductsContext} ctx context
   * @returns {Promise<void>}
   */
  preFetchExternalLicenses: ({
    commit,
    rootGetters,
  }: TExternalProductsContext): Promise<void> => {
    if (!rootGetters['account/isUserLoggedIn']) {
      return Promise.resolve()
    }

    return api.lk.getUserExternalProducts().then((products) => {
      commit('SET_HAS_TRIAL_LICENSE', products)
      commit('SET_HAS_EXTERNAL_LICENSES', products)
    })
  },

  assignLicense(
    { commit, rootGetters, dispatch }: TExternalProductsContext,
    payload: TAssignExternalLicensePayload
  ): Promise<void> {
    if (
      rootGetters['account/isRoleBusinessUser'] === false &&
      rootGetters['account/userRole'] !== null
    ) {
      App.$notify({
        type: 'info',
        message: i18n
          .t(
            'component.notify.message-error-invalid-account-form-assign-license'
          )
          .toString(),
      })
      return Promise.resolve()
    }

    const linkCompanyPayload: TLinkCompanyPayload = {
      company_id: payload.company_id,
      token: payload.token,
    }

    const assignPayload: TAssignExternalLicensePayload = {
      ...payload,
      upgrade_product_id:
        typeof payload.upgrade_product_id === 'string'
          ? Number(payload.upgrade_product_id)
          : payload.upgrade_product_id,
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'assign-external-license',
    })

    return (
      api.lk
        // Проверяем возможность привязки лицензии к аккаунту.
        .checkAssignExternalLicense(assignPayload)
        // Привязываем аккаунт к компании
        .then(() =>
          api.account.linkCompany(payload.account_id, linkCompanyPayload)
        )
        // Привязываем лицензии к аккаунту
        .then(() => api.lk.assignExternalLicense(assignPayload))
        // Получаем лицензии для проверки наличия триальных и внешних лицензий
        .then(() => dispatch('preFetchExternalLicenses'))
        .catch(() => {
          App.$notify({
            type: 'error',
            message: i18n
              .t('component.notify.message-assign-license-error')
              .toString(),
          })
        })
        .finally(() =>
          commit('SET_LOADING_PROCESS', {
            loading: false,
            name: 'assign-external-license',
          })
        )
    )
  },

  /**
   * fetchExternalProduct
   * ? Извлечь продукт по его идентификатору
   *
   * @param {TExternalProductsContext} ctx context
   * @param {string} id идентификатор продукта
   * @returns {Promise<IExternalProductResponse>} продукт
   */
  fetchExternalProduct: (
    { commit }: TExternalProductsContext,
    id: number
  ): Promise<IExternalProductResponse> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'external-product' })

    return api.lk.getExternalProduct(id).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'external-product',
      })
    })
  },

  /**
   * fetchExternalDownloadUrls
   * ? Заполнить список ссылок для скачивания дистрибутивов
   *
   * @param {TExternalProductsContext} ctx context
   * @returns {Promise<void>}
   */
  fetchExternalDownloadUrls: ({
    commit,
    getters,
  }: TExternalProductsContext): Promise<void> => {
    if (getters['downloadUrls'].length > 0) {
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'download-urls',
    })

    return api.lk
      .getExternalDownloadUrls()
      .then((urls) => {
        commit('SET_DOWNLOAD_URLS', urls)
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'download-urls',
        })
      })
  },

  /**
   * activateTrial
   * ? Активировать триальную лицензию
   *
   * @param {TExternalProductsContext} ctx context
   * @param {IExternalLicensesActivateTrialPayload} payload параметры на активацию пробной версии
   * @returns {Promise<void>}
   */
  activateTrial: async (
    { commit, dispatch }: TExternalProductsContext,
    payload: IExternalLicensesActivateTrialPayload
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'activate-trial-license',
    })

    return (
      api.lk
        .activateTrialLicense(payload)
        // Получаем лицензии для проверки наличия триальных и внешних лицензий
        .then(() => dispatch('preFetchExternalLicenses'))
        .then(() => dispatch('fetchExternalLicenses'))
        .finally(() => {
          commit('SET_LOADING_PROCESS', {
            loading: false,
            name: 'activate-trial-license',
          })
        })
    )
  },
}
