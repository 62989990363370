import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type { IReportUserAccount } from '@/api/types'
import type {
  UsersState,
  ILicensesLocalResponse,
  IUserAccountsLocalPayload,
  IExportAccountsLocalPayload,
  IBackupsDeletionLocalResponse,
  IReportUserAccountsLocalPayload,
} from '@/store/modules/admin/users/types'

import api from '@/api'
import { getMetaGroups } from '@/helpers'
import { EReportType } from '@/config/enums'
import { exportToXLSX } from '@/helpers/xlsx'
import { escapeCommas } from '@/helpers/strings'
import { replaceRouteQuery } from '@/helpers/router'
import { TABLE_KEY, TABLE_TYPE } from '@/config/constants'
import { getCountActiveLicenses } from '@/helpers/formatted-functions'
import { getItemStorage, setItemStorage } from '@/helpers/local-storage'

type UsersTree = ActionTree<UsersState, RootState>
type UsersContext = ActionContext<UsersState, RootState>

const { TABLE_MODAL_USERS, TABLE_REPORT_USERS } = TABLE_KEY

export const actions: UsersTree = {
  /**
   * fetchUserAccounts
   * ? Извлечь список пользователей с мета данными
   *
   * @param {UsersContext} ctx context
   * @param {IUserAccountsLocalPayload} payload параметры конфигурации запроса кампаний
   * @returns {Promise<void>}
   */

  fetchUserAccounts: async (
    { state, commit }: UsersContext,
    payload: IUserAccountsLocalPayload
  ): Promise<void> => {
    const metaState = state.currentUsersMeta
    const currentMetaGroups = getMetaGroups(
      TABLE_MODAL_USERS,
      payload?.meta,
      metaState.meta
    )
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = { ...paging, ...sort, ...search, ...filters }
    const usersPayload = { ...combinedMetaList }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'users' })
    commit('SET_CURRENT_USERS_META', {
      meta: currentMetaGroups,
    })

    return api.account
      .getUserAccounts(usersPayload)
      .then(({ data, meta }) => {
        commit('SET_USERS', data)
        commit('SET_CURRENT_USERS_META', {
          page: meta.page,
          total: meta.total,
        })
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'users',
        })
      })
  },

  /**
   * fetchReportUserAccounts
   * ? Извлечь отчет со списком пользователей с мета данными
   *
   * @param {UsersContext} param0 context
   * @param {IReportUserAccountsLocalPayload} payload параметры конфигурирования списка пользователей
   * @returns {Promise<void>}
   */
  fetchReportUserAccounts: async (
    { state, commit, dispatch, rootGetters }: UsersContext,
    payload: IReportUserAccountsLocalPayload
  ): Promise<void> => {
    const sessionId =
      rootGetters['reportSession/accountsSession'].id ||
      (await dispatch('reportSession/createSession', EReportType.ACCOUNTS, {
        root: true,
      }))

    const { type, isChangeRoute = true } = payload
    const metaState = state.currentReportUsersMeta
    const deletedAccountIds = getItemStorage('deleted-accounts') ?? []
    const currentMetaGroups = getMetaGroups(type, payload.meta, metaState.meta)
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = { ...paging, ...sort, ...search, ...filters }
    const reportUsersPayload = {
      ...combinedMetaList,
      session_id: sessionId,
    }

    setItemStorage(TABLE_TYPE[type], currentMetaGroups)

    commit('SET_CURRENT_META_REPORT_USERS', { meta: currentMetaGroups })
    commit('SET_REPORT_DELETED_ACCOUNT_IDS', deletedAccountIds)
    commit('SET_LOADING_PROCESS', { loading: true, name: 'report-users' })

    return api.report
      .getReportUserAccounts(reportUsersPayload)
      .then(({ data, meta }) => {
        const searchValue = String(search?.email_query ?? '')
        const users = searchValue.length === 1 ? [] : data

        commit('SET_REPORT_USERS', users)
        commit('SET_CURRENT_META_REPORT_USERS', {
          items: meta.limit,
          total: meta.total,
        })

        if (isChangeRoute) {
          replaceRouteQuery(combinedMetaList)
        }
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', { loading: false, name: 'report-users' })
      })
  },

  /**
   * fetchUserLicenses
   * ? Извлечь лицензии пользователя
   *
   * @param {UsersContext} ctx context
   * @param {string} id идентификатор пользователя
   * @returns {Promise<ILicensesLocalResponse>} лицензии пользователя
   */
  fetchUserLicenses: (
    { commit }: UsersContext,
    id: string
  ): Promise<ILicensesLocalResponse> => {
    const payload = {
      account_id: id,
      include_trial: true,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'licenses' })

    return api.lk
      .getAccountProducts(payload)
      .then((licenses) => ({
        licenses,
        account_id: id,
      }))
      .finally(() =>
        commit('SET_LOADING_PROCESS', { loading: false, name: 'licenses' })
      )
  },

  /**
   * exportUserAccounts
   * ? Экспортировать аккаунты пользователей в файл
   *
   * @param {IExportAccountsLocalPayload} payload промежуточные данные
   * @param {UsersContext} param0 context
   * @returns {Promise<void>}
   */
  exportUserAccounts: (
    { commit, rootGetters }: UsersContext,
    { type, filename }: IExportAccountsLocalPayload
  ) => {
    const { search, filters } = getItemStorage(type) ?? {}
    const accountsPayload = {
      ...search,
      ...filters,
      content_type: 'text/csv',
      session_id: rootGetters['reportSession/accountsSession'].id,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'export-users' })

    return api.report
      .exportAccounts(accountsPayload)
      .then((file) => exportToXLSX(file, filename))
      .finally(() => {
        commit('SET_LOADING_PROCESS', { loading: false, name: 'export-users' })
      })
  },

  /**
   * deleteAccounts
   * ? Удалить один или множество аккаунтов пользователя
   *
   * @param {UsersContext} param0 context
   * @param {string[]} accountIds идентификаторы аккаунтов
   * @returns {Promise<void>}
   */
  deleteAccounts: (
    { commit, dispatch }: UsersContext,
    accountIds: string[]
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { name: 'delete-accounts', loading: true })

    const consistentlyDeleteAccounts = async () => {
      const licenseRequests = accountIds.map(
        (id) => () => dispatch('fetchUserLicenses', id)
      )

      for (const fetchLicense of licenseRequests) {
        await fetchLicense().then(({ account_id, licenses }) => {
          const amountActiveLicenses = getCountActiveLicenses(licenses)

          if (amountActiveLicenses > 0) {
            return Promise.resolve()
          }

          const payload = { account_id }
          const previousDeletedAccountIds =
            getItemStorage('deleted-accounts') ?? []
          const currentDeletedAccountIds = [
            ...new Set([...previousDeletedAccountIds, account_id]),
          ]

          return api.account.deleteAccount(payload).then(() => {
            commit('SET_REPORT_DELETED_ACCOUNT_IDS', currentDeletedAccountIds)
            setItemStorage('deleted-accounts', currentDeletedAccountIds)
          })
        })
      }
    }

    return consistentlyDeleteAccounts()
      .then(() => {
        commit('reportSession/DELETE_REPORT_SESSION_ID', EReportType.ACCOUNTS, {
          root: true,
        })

        return dispatch('fetchReportUserAccounts', { type: TABLE_REPORT_USERS })
      })
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          name: 'delete-accounts',
          loading: false,
        })
      )
  },

  /**
   * deleteAllBackups
   * ? Удалить все резервные копии у одного или множества пользователей
   *
   * @param {UsersContext} param0 context
   * @param {IReportUserAccount[]} accounts аккаунты пользователей
   * @returns {Promise<void>}
   */
  deleteAllBackups: (
    { commit, dispatch }: UsersContext,
    accounts: IReportUserAccount[]
  ): Promise<void> => {
    const consistentlyDeleteBackups = async () => {
      const backupRequests = accounts.map(
        (account) => () => dispatch('fetchUserBackups', account)
      )

      for (const fetchBackup of backupRequests) {
        await fetchBackup().then(
          ({ backups, account_id }: IBackupsDeletionLocalResponse) => {
            const backupNames = backups.map(({ name }) => name)
            const params = {
              account_id,
              archives: escapeCommas(backupNames),
            }

            return api.webrestore.deleteUserBackups(params)
          }
        )
      }
    }

    commit('SET_LOADING_PROCESS', {
      name: 'delete-all-backups',
      loading: true,
    })

    return consistentlyDeleteBackups()
      .then(() => {
        commit('reportSession/DELETE_REPORT_SESSION_ID', EReportType.ACCOUNTS, {
          root: true,
        })

        dispatch(
          'users/fetchReportUserAccounts',
          { type: TABLE_REPORT_USERS },
          { root: true }
        )
      })
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          name: 'delete-all-backups',
          loading: false,
        })
      )
  },

  /**
   * fetchUserBackups
   * ? Извлечь список резервных копий
   *
   * @param {UsersContext} ctx context
   * @param {IReportUserAccount} user пользовательские данные
   * @returns {Promise<IBackupsDeletionLocalResponse | void>} список резервных копий
   */
  fetchUserBackups: (
    { commit }: UsersContext,
    user: IReportUserAccount
  ): Promise<IBackupsDeletionLocalResponse | void> => {
    if (!user.is_active) {
      return Promise.resolve()
    }

    const params = { account_id: user.id }
    commit('SET_LOADING_PROCESS', { loading: true, name: 'backups' })

    return api.webrestore
      .getUserBackups(params)
      .then(({ data }) => ({ backups: data, account_id: params.account_id }))
      .finally(() =>
        commit('SET_LOADING_PROCESS', { loading: false, name: 'backups' })
      )
  },
}
