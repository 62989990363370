import type { SkuModule, SkuState } from '@/store/modules/admin/sku/types'
import type { SkuForm } from '@/components/forms/admin/types'
import type { SkuPayload, TSkuTtl } from '@/api/types'
import { getters } from '@/store/modules/admin/sku/getters'
import { actions } from '@/store/modules/admin/sku/actions'
import { mutations } from '@/store/modules/admin/sku/mutations'
import { convertToGigaBytes } from '@/helpers'
import { ELicenseType } from '@/config/enums'

export const FEATURES_LIMIT = 10

const state: SkuState = {
  currentSku: null,

  isLoadingAddSku: false,
  isLoadingEditSku: false,
  isLoadingDeleteSku: false,
  isLoadingCurrentSku: false,
  isLoadingPublishSku: false,
  isLoadingArchivingSku: false,
  isLoadingEditingPriceSku: false,
  isLoadingEditingUpgradesSku: false,
}

export const sku: SkuModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}

const getFeatureIds = (ids: string[], isTrial: boolean): string[] => {
  if (isTrial && !ids.includes(ELicenseType.TRIAL)) {
    return [...ids, ELicenseType.TRIAL]
  }

  if (!isTrial && ids.includes(ELicenseType.TRIAL)) {
    return ids.filter((id) => id !== ELicenseType.TRIAL)
  }

  return ids
}

export const getSkuPayload = (
  form: SkuForm,
  featureIds: string[]
): SkuPayload => {
  const cloudLimit = convertToGigaBytes(
    form.cloudQuota?.value || 0,
    form.cloudQuota?.type || 'gb'
  ) // Необходимо отправлять размер квоты в гигабайтах

  const isTrial = form.licenseType === ELicenseType.TRIAL

  const price: number = isTrial ? 0 : form.price

  const vat_included: boolean = isTrial ? false : form.includedVat

  return {
    id: form.id,
    ttl: `${form.duration.value}${form.duration.type}` as TSkuTtl,
    name: form.name,
    store_id: null,
    product_id: form.productId,
    description: form.description,
    activations_limit: form.pcQuota,
    price,
    vat_included,
    quotas: [
      {
        id: 'cloud',
        hard_limit: cloudLimit,
        soft_limit: cloudLimit,
      },
    ],
    features: getFeatureIds(featureIds, isTrial).map((id) => ({ id })),
  }
}
