const gtm = {
  storage: 'dataLayer',
  id: process.env.VUE_APP_GTM_ID,
  host: 'https://www.googletagmanager.com',
  use: JSON.parse(process.env.VUE_APP_USE_GTM),
}

const gtmLoader = () => {
  window[gtm.storage] = window[gtm.storage] || []
  window[gtm.storage].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  })

  const f = document.getElementsByTagName('script')[0]
  const j = document.createElement('script')
  const dl = gtm.storage != 'dataLayer' ? '&l=' + gtm.storage : ''

  j.async = true
  j.src = `${gtm.host}/gtm.js?id=${gtm.id}${dl}`
  f.parentNode.insertBefore(j, f)
}

const gtmFrame = () => {
  const noScriptNode = document.body.querySelector('noscript')
  const iFrameText = `
    <iframe
      width="0"
      height="0"
      src="${gtm.host}/ns.html?id= ${gtm.id}"
      style="display: none; visibility: hidden"
    ></iframe>
  `

  noScriptNode.innerHTML = `
    ${iFrameText}
    ${noScriptNode.innerHTML}
  `
}

if (gtm.use) {
  Promise.resolve().then(gtmLoader).then(gtmFrame)
}
