import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  IReportSession,
  IReportSessionState,
} from '@/store/modules/admin/report-session/types'

type TReportSessionGetter = GetterTree<IReportSessionState, RootState>

export const getters: TReportSessionGetter = {
  /**
   * ordersSession
   * ? Сессия ордеров
   *
   * @param {IReportSessionState} state data
   * @returns {IReportSession} сессия ордеров
   */
  ordersSession: (state: IReportSessionState): IReportSession => {
    return state.sessions.orders
  },

  /**
   * accountsSession
   * ? Сессия аккаунтов
   *
   * @param {IReportSessionState} state data
   * @returns {IReportSession} сессия аккаунтов
   */
  accountsSession: (state: IReportSessionState): IReportSession => {
    return state.sessions.accounts
  },

  /**
   * campaignsSession
   * ? Сессия кампаний
   *
   * @param {IReportSessionState} state data
   * @returns {IReportSession} сессия кампаний
   */
  campaignsSession: (state: IReportSessionState): IReportSession => {
    return state.sessions.campaigns
  },

  /**
   * assignedLicenseSession
   * ? Сессия привязанных лицензий
   *
   * @param {IReportSessionState} state data
   * @returns {IReportSession} сессия привязанных лицензий
   */
  assignedLicensesSession: (state: IReportSessionState): IReportSession => {
    return state.sessions.assigned_licenses
  },

  /**
   * availableLicenseSession
   * ? Сессия свободных лицензий
   *
   * @param {IReportSessionState} state data
   * @returns {IReportSession} сессия свободных лицензий
   */
  availableLicensesSession: (state: IReportSessionState): IReportSession => {
    return state.sessions.available_licenses
  },

  /**
   * isLoadingGetReportSession
   * ? Процесс загрузки получения сессия
   *
   * @param {IReportSessionState} state data
   * @returns {boolean} процесс загрузки получения сессия
   */
  isLoadingGetReportSession: (state): boolean => {
    return state.isLoadingGetReportSession
  },

  /**
   * isLoadingCreateReportSession
   * ? Процесс создания сессия
   *
   * @param {IReportSessionState} state data
   * @returns {boolean} процесс создания сессия
   */
  isLoadingCreateReportSession: (state): boolean => {
    return state.isLoadingCreateReportSession
  },
}
