import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type { NavState } from '@/store/modules/nav/types'
import type { StorageInfoResponse } from '@/api/types'

import api from '@/api'

type NavTree = ActionTree<NavState, RootState>
type NavContext = ActionContext<NavState, RootState>

export const actions: NavTree = {
  /**
   * fetchUrlWebRestore
   * ? Извлечь ссылку внешнего хоста Web Restore
   *
   * @param {NavContext} param0 context
   * @returns {Promise<StorageInfoResponse>} информация о хранилище аккаунта в межсервисном взаимодействии
   */
  fetchUrlWebRestore({ commit }: NavContext): Promise<StorageInfoResponse> {
    return api.account
      .getUrlWebRestore()
      .then((result) => {
        const { wr_url, is_new_webrestore } = result

        commit('SET_URL_WEB_RESTORE', wr_url ?? '')
        commit('SET_ATTRIBUTE_VISIBLE_NEW_WEB_RESTORE', is_new_webrestore)

        return result
      })
      .catch((error) => error)
  },
}
