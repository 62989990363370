import type { MutationTree } from 'vuex'
import type {
  IReportSessionState,
  IReportSessionParams,
} from '@/store/modules/admin/report-session/types'

type TReportSessionMutation = MutationTree<IReportSessionState>

import { setLoading } from '@/helpers/mutation-functions'

export const mutations: TReportSessionMutation = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_REPORT_SESSION_ID
   * ? Установить идентификатор сессии
   *
   * @param {IReportSessionState} state data
   * @param {IReportSessionParams} params параметры сессии
   * @returns {void}
   */
  SET_REPORT_SESSION_ID: (
    state: IReportSessionState,
    { id, reportType }: IReportSessionParams
  ): void => {
    state.sessions[reportType].id = id
  },

  /**
   * SET_REPORT_SESSION_STATUS
   * ? Установить статус сессии
   *
   * @param {IReportSessionState} state data
   * @param {IReportSessionParams} params параметры сессии
   * @returns {void}
   */
  SET_REPORT_SESSION_STATUS: (
    state: IReportSessionState,
    { status, reportType }: IReportSessionParams
  ): void => {
    state.sessions[reportType].status = status
  },

  /**
   * SET_REPORT_SESSION_OBSERVE
   * ? Установить статус сессии
   *
   * @param {IReportSessionState} state data
   * @param {IReportSessionParams} params параметры сессии
   * @returns {void}
   */
  SET_REPORT_SESSION_OBSERVE: (
    state: IReportSessionState,
    { observe, reportType }: IReportSessionParams
  ): void => {
    state.sessions[reportType].observe = observe
  },
  /**
   * SET_REPORT_SESSION_TIMEOUT_ID
   * ? Установить идентификатор таймаута
   *
   * @param {IReportSessionState} state data
   * @param {IReportSessionParams} params параметры сессии
   * @returns {void}
   */
  SET_REPORT_SESSION_TIMEOUT_ID: (
    state: IReportSessionState,
    { reportType, timeoutId }: IReportSessionParams
  ): void => {
    state.sessions[reportType].timeoutId = timeoutId
  },

  /**
   * DELETE_REPORT_SESSION_ID
   * ? Удалить идентификатор сессии
   *
   * @param {IReportSessionState} state data
   * @param {string} reportType тип репорта
   * @returns {void}
   */
  DELETE_REPORT_SESSION_ID: (
    state: IReportSessionState,
    reportType: string
  ): void => {
    state.sessions[reportType].id = ''
  },
  /**
   * DELETE_ALL_REPORT_SESSION_ID
   * ? Удалить все идентификаторы сессий
   *
   * @param {IReportSessionState} state data
   * @returns {void}
   */
  DELETE_ALL_REPORT_SESSION_ID: (state: IReportSessionState): void => {
    for (const session in state.sessions) {
      state.sessions[session].id = ''
    }
  },
}
