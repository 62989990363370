export const ru = {
  // ? Meta
  'app.meta.title': 'Личный кабинет | Киберпротект',

  // ? Unit
  'unit.b': 'Б',
  'unit.kb': 'КБ',
  'unit.mb': 'МБ',
  'unit.gb': 'ГБ',
  'unit.tb': 'ТБ',
  'unit.pb': 'ПБ',
  'unit.eb': 'ЭБ',
  'unit.zb': 'ЗБ',
  'unit.yb': 'ЙБ',

  // ? Words
  'word.and': 'и',
  'word.no': 'Нет',
  'word.and-more': 'и еще {count}',

  // ? Countries
  'country.russia': 'Российская Федерация',
  'country.belarus': 'Республика Беларусь',

  // ? Dictionary
  'dictionary.selected': 'Выбрано | Выбран | Выбраны',
  'dictionary.ruble':
    '0 рублей | {count} рубль | {count} рубля | {count} рублей',
  'dictionary.file': '0 файлов | {count} файл | {count} файла | {count} файлов',
  'dictionary.year': '0 лет | {count} год | {count} года | {count} лет',
  'dictionary.day': '0 дней | {count} день | {count} дня | {count} дней',
  'dictionary.machine': '0 машин | 1 машины | {count} машин',
  'dictionary.version':
    '0 версий | {count} версия | {count} версии | {count} версий',
  'dictionary.seconds':
    '0 секунд | {count} секунда | {count} секунды | {count} секунд',
  'dictionary.in.seconds':
    '0 секунд | {count} секунду | {count} секунды | {count} секунд | {count} секунду',
  'dictionary.computer':
    '0 компьютеров | {count} компьютер | {count} компьютера | {count} компьютеров',
  'dictionary.of-computers':
    '0 компьютеров | {count} компьютера | {count} компьютеров',
  'dictionary.elements':
    '0 элементов | {count} элемент | {count} элемента | {count} элементов',
  'dictionary.element-selected':
    '0 элементов выбрано | {count} элемент выбран | {count} элемента выбраны | {count} элементов выбраны',
  'dictionary.selected-elements':
    'Выбрано 0 элементов | Выбран {count} элемент | Выбраны {count} элемента | Выбраны {count} элементов',
  'dictionary.selected-elements-size':
    'Выбрано 0 элементов | Выбран {count} элемент ({size}) | Выбраны {count} элемента ({size}) | Выбраны {count} элементов ({size})',

  // ? Documents
  'document.label.wr-file': 'Файл',
  'document.label.wr-folder': 'Папка',
  'document.label.wr-qt': 'Файл "QT"',
  'document.label.wr-webp': 'Файл "WEBP"',
  'document.label.wr-wm': 'Файл "WM"',
  'document.label.wr-7z': 'Файл "7Z"',
  'document.label.wr-ota': 'Файл "OTA"',
  'document.label.wr-apk': 'Файл "APK"',
  'document.label.wr-zip': 'Файл "ZIP"',
  'document.label.wr-rar': 'Файл "RAR"',
  'document.label.wr-tar': 'Файл "TAR"',
  'document.label.wr-bz2': 'Файл "BZ2"',
  'document.label.wr-gzip': 'Файл "GZ"',
  'document.label.wr-cab': 'Файл "CAB"',
  'document.label.wr-jar': 'Файл "JAR"',
  'document.label.wr-csv': 'Файл "CSV"',
  'document.label.wr-iso': 'Файл "ISO"',
  'document.label.wr-ttc': 'Файл "TTC"',
  'document.label.wr-ttf': 'Файл "TTF"',
  'document.label.wr-otf': 'Файл "OTF"',
  'document.label.wr-exe': 'Файл "EXE"',
  'document.label.wr-dll': 'Файл "DLL"',
  'document.label.wr-dot': 'Файл "DOT"',
  'document.label.wr-jpg': 'Файл "JPG"',
  'document.label.wr-gif': 'Файл "GIF"',
  'document.label.wr-png': 'Файл "PNG"',
  'document.label.wr-bmp': 'Файл "BMP"',
  'document.label.wr-mp3': 'Файл "MP3"',
  'document.label.wr-x-au': 'Файл "AU"',
  'document.label.wr-mp4': 'Файл "MP4"',
  'document.label.wr-mpa': 'Файл "MPA"',
  'document.label.wr-ac3': 'Файл "AC3"',
  'document.label.wr-pdf': 'Файл "PDF"',
  'document.label.wr-odp': 'Файл "ODP"',
  'document.label.wr-ods': 'Файл "ODS"',
  'document.label.wr-odt': 'Файл "ODT"',
  'document.label.wr-rtf': 'Файл "RTF"',
  'document.label.wr-txt': 'Файл "TXT"',
  'document.label.wr-svg': 'Файл "SVG"',
  'document.label.wr-eps': 'Файл "EPS"',
  'document.label.wr-cdr': 'Файл "CDR"',
  'document.label.wr-cdt': 'Файл "CDT"',
  'document.label.wr-wmf': 'Файл "WMF"',
  'document.label.wr-raw': 'Файл "RAW"',
  'document.label.wr-mkv': 'Файл "MKV"',
  'document.label.wr-avi': 'Файл "AVI"',
  'document.label.wr-wmv': 'Файл "WMV"',
  'document.label.wr-wmx': 'Файл "WMX"',
  'document.label.wr-fb2': 'Файл "FB2"',
  'document.label.wr-epub': 'Файл "EPUB"',
  'document.label.wr-mobi': 'Файл "MOBI"',
  'document.label.wr-mpeg': 'Файл "MPEG"',
  'document.label.wr-potx': 'Файл "POTX"',
  'document.label.wr-ppsx': 'Файл "PPSX"',
  'document.label.wr-xodp': 'Файл "XODP"',
  'document.label.wr-xods': 'Файл "XODS"',
  'document.label.wr-xodt': 'Файл "XODT"',
  'document.label.wr-x-wav': 'Файл "WAV"',
  'document.label.wr-flac': 'Файл "FLAC"',
  'document.label.wr-jpeg': 'Файл "JPEG"',
  'document.label.wr-tiff': 'Файл "TIFF"',
  'document.label.wr-dotx': 'Файл "DOTX"',
  'document.label.wr-sfnt': 'Файл "SFNT"',
  'document.label.wr-html': 'Файл "HTML"',
  'document.label.wr-woff': 'Файл "WOFF"',
  'document.label.wr-woff2': 'Файл "WOFF2"',
  'document.label.wr-gtar': 'Файл "TAR.GZ"',
  'document.label.wr-movie': 'Файл "MOVIE"',
  'document.label.wr-vcard': 'Файл "VCARD"',
  'document.label.wr-x-aiff': 'Файл "AIFF"',
  'document.label.wr-basic': 'Файл "BASIC"',
  'document.label.wr-sp-midi': 'Файл "MIDI"',
  'document.label.wr-x-ms-wma': 'Файл "WMA"',
  'document.label.wr-sony-arw': 'Файл "ARW"',
  'document.label.wr-fuji-raf': 'Файл "RAF"',
  'document.label.wr-sony-srf': 'Файл "SRF"',
  'document.label.wr-sony-sr2': 'Файл "SR2"',
  'document.label.wr-photoshop': 'Файл "PSD"',
  'document.label.wr-canon-cr2': 'Файл "CR2"',
  'document.label.wr-canon-crw': 'Файл "CRW"',
  'document.label.wr-adobe-dng': 'Файл "DNG"',
  'document.label.wr-kodak-kdc': 'Файл "KDC"',
  'document.label.wr-kodak-dcr': 'Файл "DCR"',
  'document.label.wr-epson-erf': 'Файл "ERF"',
  'document.label.wr-nikon-nef': 'Файл "NEF"',
  'document.label.wr-nikon-nrw': 'Файл "NRW"',
  'document.label.wr-pentax-pef': 'Файл "PEF"',
  'document.label.wr-minolta-mrw': 'Файл "MRW"',
  'document.label.wr-olympus-orf': 'Файл "ORF"',
  'document.label.wr-keynote': 'Файл "Keynote"',
  'document.label.wr-tiff-fx': 'Файл "TIFF-FX"',
  'document.label.wr-panasonic-raw': 'Файл "RAW"',
  'document.label.no-data': 'Нет данных',
  'document.label.txt': 'Текстовый документ',
  'document.label.wr-disk-backup': 'Дисковая',
  'document.label.wr-file-backup': 'Файловая',
  'document.label.wr-email': 'Элемент Outlook',
  'document.label.wr-mobile-backup': 'Мобильная',
  'document.label.wr-full-backup': 'Полная копия ПК',
  'document.label.wr-xls': 'Лист Microsoft Excel',
  'document.label.wr-doc': 'Документ Microsoft Word',
  'document.label.wr-docx': 'Документ Microsoft Word',
  'document.label.wr-ppt': 'Презентация Microsoft Power Point',

  // ? Fields label
  'field.label.disk': 'Диск',
  'field.label.path:': 'Путь:',
  'field.label.search': 'Поиск',
  'field.label.types': 'Все типы',
  'field.label.name': 'Имя',
  'field.label.type': 'Тип',
  'field.label.type:': 'Тип:',
  'field.label.size': 'Размер',
  'field.label.size:': 'Размер:',
  'field.label.list': 'Список',
  'field.label.tile': 'Плитка',
  'field.label.created': 'Создан',
  'field.label.he-created:': 'Создан:',
  'field.label.she-created:': 'Создана:',
  'field.label.last-created:': 'Последняя создана:',
  'field.label.versions': 'Версий',
  'field.label.versions:': 'Версий:',
  'field.label.release': 'Версия',
  'field.label.modified': 'Изменен',
  'field.label.device': 'Устройство',
  'field.label.release:': 'Версия:',
  'field.label.total-size': 'Общий размер',
  'field.label.total-size:': 'Общий размер:',
  'field.label.last-size:': 'Размер последней:',
  'field.label.large-tile': 'Крупная плитка',
  'field.label.latest-version': 'Последняя версия',

  // ? Actions (button)
  'button.action.open': 'Открыть',
  'button.action.close': 'Закрыть',
  'button.action.delete': 'Удалить',
  'button.action.cancel': 'Отменить',
  'button.action.collapse': 'Свернуть',
  'button.action.browse': 'Просмотреть',
  'button.action.continue': 'Продолжить',
  'button.action.load-more': 'Загрузить еще',
  'button.action.download': 'Скачать',
  'button.action.download.all': 'Скачать все',
  'button.action.download.vcard': 'Скачать vCard',
  'button.action.unselect': 'Отменить выбор',
  'button.action.delete.archive': 'Удалить архив',
  'button.action.only-gallery': 'Только галерею',
  'button.action.only-contacts': 'Только контакты',
  'button.action.delete.backup': 'Удалить резервную копию',
  'button.action.delete.all-backup': 'Удалить резервную копию целиком',
  'button.action.download.backup': 'Скачать резервную копию',
  'button.action.download.backup-version': 'Скачать всю версию',
  'button.action.download.all-archive': 'Скачать весь архив',

  // ? Dropdown
  'dropdown.item-name.all-backup': 'Всю резервную копию',
  'dropdown.item-name.contacts': 'Контакты',
  'dropdown.item-name.gallery': 'Галерею',

  // ? Table
  'component.table.label.no-data': 'Нет данных',
  'component.table.content.empty-folder': 'Эта папка пуста.',
  'component.table.content.search-without-results': 'Поиск не дал результатов.',
  'component.table.content.no-data': 'Нет данных для отображения.',
  'component.table.content.search-two-symbols':
    'Для поиска введите не менее 2-х символов.',
  'component.table.content.empty-search': 'Поиск не дал результатов.',
  'component.table.content.empty-backups': 'Сейчас у вас нет резервных копий.',
  'component.table.content.empty-archives': 'Сейчас у вас нет архивов.',
  'component.table.content.loading-archives': 'Список архивов загружается...',
  'component.table.content.loading-backups':
    'Список резервных копий загружается...',

  // ? Feedback
  'feedback.title.registered': 'Подтвердите регистрацию',
  'feedback.title.error': 'Произошла ошибка',
  'feedback.title.reset-password':
    'Ссылка на смену пароля больше недействительна.',
  'feedback.title.not-activated': 'Регистрация не завершена',
  'feedback.title.message-sent': 'Сообщение отправлено',
  'feedback.title.account-already-registered.link-no-valid':
    'Ссылка больше не действительна.',
  'feedback.text.account-already-registered.try-to-login':
    'Войдите в ваш аккаунт или подтвердите регистрацию.',
  'feedback.subtitle.error': 'Мы хотим помочь ее решить.',
  'feedback.text.error': 'Пожалуйста, обратитесь к своему дистрибьютору или в',
  'feedback.text.support': 'службу поддержки.',
  'feedback.text.registered':
    'Мы отправили на ваш адрес {email} сообщение со ссылкой. Перейдите по ней, чтобы завершить регистрацию и начать пользоваться личным кабинетом.',
  'feedback.text.not-activated':
    'При регистрации на ваш адрес {email} было отправлено сообщение со ссылкой. Перейдите по ней, чтобы завершить регистрацию и начать пользоваться личным кабинетом.',
  'feedback.text.sent-message':
    'Мы отправили на ваш адрес {email} сообщение со ссылкой. Перейдите по ней, чтобы задать новый пароль.',
  'feedback.text.no-message-came':
    'Если сообщение не пришло, проверьте папку «Спам», запросите повторную отправку сообщения  или',
  'feedback.text.step.support': 'обратитесь в службу поддержки',
  'feedback.text.resend-messages':
    'Сообщение отправлено. Повторить отправку можно через {time}',
  'feedback.button.resent-message': 'Получить сообщение повторно',
  'feedback.link.to-forgot': 'Запросить ссылку еще раз',
  'feedback.link.account-already-registered': 'Войти',

  // ? Notify
  'notify.backup.reject.invalid-version-id':
    'Недопустимый идентификатор версии резервной копии «{version}»',
  'notify.archive.reject.invalid-version-id':
    'Недопустимый идентификатор версии архива «{version}»',
  'notify.backup.reject.archive-not-found': 'Архив «{name}» не найден',
  'notify.backup.reject.backup-not-found':
    'Резервная копия «{name}» не найдена',
  'notify.backup.reject.file-not-found': 'Файл «{name}» не найден',
  'notify.backup.reject.version-not-found':
    'Версия резервной копии «{version}» не найдена',
  'notify.download.success': 'Скачивание завершено',
  'notify.download-backup-file.success': 'Скачивание файла «{name}» завершено',
  'notify.download-backup-folder.success':
    'Скачивание папки «{name}» завершено',
  'notify.delete-profile.pending': 'Удаление профиля',
  'notify.delete-profile.resolve': 'Профиль удален',
  'notify.delete-profile.reject': 'Отказано в доступе',
  'notify.change-password.pending': 'Изменение пароля',
  'notify.change-password.resolve': 'Пароль учетной записи был изменен.',
  'notify.change-password.go-to-cyber-backup':
    'Перейдите в Кибер Бэкап Персональный, выйдите из учетной записи и войдите в нее снова.',
  'notify.edit-profile.pending': 'Сохранение сведений о пользователе',
  'notify.edit-profile.resolve': 'Сведения о пользователе успешно сохранены',
  'notify.products.button.auto-renewal-enabled': 'Автопродление включено',
  'notify.products.button.auto-renewal-disabled': 'Автопродление выключено',
  'notify.profile.subscribe-newsletter-enabled':
    'Вы успешно подписались на рассылку',
  'notify.profile.subscribe-newsletter-disabled':
    'Вы успешно отписались от рассылки',
  'notify.delete-backup.backup-in-use':
    'Файл резервной копии используется. Повторите попытку позже. | Файл резервной копии {names} используется. Повторите попытку позже. | Файлы резервных копий {names} используются. Повторите попытку позже.',

  // ? Modals
  // Delete profile
  'modal.delete-profile.header.title': 'Удалить профиль?',
  'modal.delete-profile.content.description':
    'Это действие необратимо. Введите пароль для подтверждения.',
  'modal.delete-profile.action.cancel': 'Отменить',
  'modal.delete-profile.action.delete': 'Удалить',

  // License key
  'modal.license-key.title.adding-license-key':
    'Добавление лицензионного ключа',
  'modal.license-key.title.adding-license-file':
    'Добавление лицензионного файла',
  'modal.license-key.sub-title.added-license-key': 'Добавлен лицензионный ключ',
  'modal.license-key.sub-title.added-license-file':
    'Добавлен лицензионный файл',
  'modal.license-key.button.submit.done': 'Готово',
  'modal.license-key.button.submit.try-again': 'Попробовать снова',
  'modal.license-key.status.checking': 'Проверка',
  'modal.license-key.status.checking-key': 'Проверка ключа',
  'modal.license-key.status.checked': 'Проверен',
  'modal.license-key.status.wrong-file': 'Неверный файл',
  'modal.license-key.description.not-available':
    'Вы не можете активировать этот ключ, обратитесь, пожалуйста, в службу поддержки.',

  // Plan expansion
  'modal.plan-expansion.title': 'Расширение лицензии',
  'modal.plan-expansion.current-plan': 'Текущая лицензия',
  'modal.plan-expansion.new-plan': 'Новая лицензия',
  'modal.plan-expansion.word.year': 'год',
  'modal.plan-expansion.word.disabled': 'отключено',
  'modal.plan-expansion.word.current': 'текущее',
  'modal.plan-expansion.word.upper-current': 'Текущее',
  'modal.plan-expansion.sub-title.pc': 'Компьютеры',
  'modal.plan-expansion.info.pc': 'Выберите количество компьютеров',
  'modal.plan-expansion.info.maximum-pc': 'Достигнуто максимальное количество',
  'modal.plan-expansion.info.maximum-cloud': 'Достигнут максимальный объем',
  'modal.plan-expansion.sub-title.cloud': 'Облачное хранилище',
  'modal.plan-expansion.info.cloud': 'Выберите объем облачного хранилища',
  'modal.plan-expansion.description.cloud':
    'Включите в свою лицензию место в облачном хранилище. Это даст вам возможность хранить резервные копии в облаке.',
  'modal.plan-expansion.info.duration':
    'Таким образом, продолжительность подписки составит',
  'modal.plan-expansion.info.subscription-creation':
    'Текущая подписка будет прекращена и создана новая.',
  'modal.plan-expansion.info.unused-period':
    'за неиспользованный период будут добавлены к новой подписке в виде дополнительных дней.',
  'modal.plan-expansion.submit.cancel': 'Отменить',
  'modal.plan-expansion.submit.buy': 'Купить',

  // Delete backup
  'modal.delete-backup.title': 'Удалить резервную копию?',
  'modal.delete-all-backup.title': 'Удалить резервную копию целиком?',
  'modal.delete-backup.description':
    'Резервная копия «{name}» будет удалена безвозвратно, включая все её версии',
  'modal.delete-backup.deleted': 'Резервная копия «{name}» удалена',

  // Delete archive
  'modal.delete-archive.title': 'Удалить архив?',
  'modal.delete-archive.description':
    'Архив «{name}» будет удален безвозвратно',
  'modal.delete-archive.deleted': 'Архив «{name}» удален',

  // Common error
  'modal.common-error.title': 'Произошла ошибка',
  'modal.common-error.text.work-with-problem':
    'Мы уже работаем над устранением проблемы.',
  'modal.common-error.text.try-after':
    'Пожалуйста, попробуйте еще раз через некоторое время.',

  // Backup versions
  'modal.backup-versions.title': 'Выбор версии',

  // Phone mobile backup category
  'modal.phone.backup-category.title': 'Часть мобильной резервной копии',

  // Phone mobile backup menu
  'modal.phone.backup-menu.title': 'Мобильная резервная копия',

  // Phone mobile backup contact
  'modal.phone.backup-contact.title':
    'Чтобы скопировать, коснитесь номера телефона, email или адреса',

  // Encrypted backup password
  'modal.encrypted-backup-password.title': 'Ввести пароль',
  'modal.encrypted-backup-password.description':
    'Резервная копия защищена паролем',

  // ? Forms
  'form.button.submit.next': 'Далее',
  'form.button.submit.cancel': 'Отменить',
  'form.button.submit.send': 'Отправить',
  'form.button.submit.send-request': 'Отправить запрос',

  // FORM: Login
  'form.login.title': 'Вход',
  'form.login.question.has-account': 'Нет аккаунта?',
  'form.login.link.register': 'Зарегистрироваться',
  'form.login.link.has-account-register': 'Нет аккаунта? Зарегистрироваться',
  'form.login.link.forgot-password': 'Забыли пароль?',
  'form.login.button.submit': 'Войти',

  // FORM: Register
  'form.register.title': 'Регистрация',
  'form.register.tab.personal-account': 'Персональный аккаунт',
  'form.register.tab.business-account': 'Бизнес-аккаунт',
  'form.register.question.has-account': 'Уже есть аккаунт?',
  'form.register.link.login': 'Войти',
  'form.register.link.agree': 'Я даю согласие на',
  'form.register.link.data-handling': 'обработку моих персональных данных',
  'form.register.link.agreement':
    'Я даю согласие на получение рассылки с новостями и скидками от Киберпротекта',
  'form.register.button.submit': 'Зарегистрироваться',

  'form.activate.title': 'Регистрация по ссылке',
  'form.activate.button.submit': 'Задать пароль',

  // FORM: Deleted
  'form.deleted.title': 'Вы удалили ваш аккаунт',
  'form.deleted.link.sign-in': 'войти',
  'form.deleted.link.create': 'создать',
  'form.deleted.word.new': 'новый',
  'form.deleted.phrase.you-can': 'Вы можете',
  'form.deleted.phrase.another-account': 'под другим аккаунтом или',

  // FORM: Forgot
  'form.forgot.title': 'Восстановление пароля',
  'form.forgot.link.root': 'Войти',
  'form.forgot.button.submit': 'Отправить',
  'form.forgot.text.forgot-info-message':
    'Введите email-адрес, на который регистрировали личный кабинет, и мы отправим вам ссылку для восстановления пароля.',

  // FORM: Reset
  'form.reset.title': 'Восстановление пароля',
  'form.reset.text.reset-info-message': 'Придумайте новый пароль',
  'form.reset.button.submit': 'Изменить пароль',

  // FORM: Change password
  'form.change-password.title': 'Изменение пароля',
  'form.change-password.link.sign-in': 'Войти',
  'form.change-password.button.change': 'Изменить',
  'form.change-password.link.forgot-password': 'Забыли пароль?',

  // FORM: Delete profile
  'form.delete-profile.button.cancel': 'Отменить',
  'form.delete-profile.button.delete': 'Удалить',

  // FORM: Edit profile
  'form.edit-profile.title': 'Изменение сведений о пользователе',
  'form.edit-profile.button.cancel': 'Отменить',
  'form.edit-profile.button.submit': 'Сохранить',

  // FORM: Add license
  'form.add-license.button.submit': 'Добавить',
  'form.add-license.button.close': 'Закрыть',

  // FORM: Problem report
  'form.problem-report.info.screenshot':
    'Вы можете приложить скриншоты и системный отчет продукта. Узнать о том, как создать системный отчет, можно в соответствующей статье базы знаний.',
  'form.problem-report.button.system-report': 'Как создать системный отчет',

  // FORM: Request
  'form.request.info.additional-files':
    'При необходимости прикрепите дополнительные файлы, которые могут быть полезны для обработки вашего обращения.',

  // FIELD: Label
  'form.field.label.dates': 'Даты',
  'form.field.label.phone': 'Телефон',
  'form.field.label.phones': 'Телефоны',
  'form.field.label.product': 'Продукт',
  'form.field.label.product-version': 'Версия продукта',
  'form.field.label.os': 'Операционная система',
  'form.field.label.priority': 'Приоритет',
  'form.field.label.priority.urgent': 'Срочно',
  'form.field.label.priority.high': 'Высокий',
  'form.field.label.priority.normal': 'Обычный',
  'form.field.label.priority.low': 'Низкий',
  'form.field.label.subject': 'Тема запроса',
  'form.field.label.type': 'Тип обращения',
  'form.field.label.description': 'Описание проблемы',
  'form.field.label.address': 'Адрес',
  'form.field.label.country': 'Страна',
  'form.field.label.company': 'Компания',
  'form.field.label.email': 'Email',
  'form.field.label.emails': 'Emails',
  'form.field.label.password': 'Пароль',
  'form.field.label.old-password': 'Старый пароль',
  'form.field.label.new-password': 'Новый пароль',
  'form.field.label.confirm-password': 'Подтверждение пароля',
  'form.field.label.first-name': 'Имя',
  'form.field.label.last-name': 'Фамилия',
  'form.field.label.company-name': 'Название компании',
  'form.field.label.inn': 'ИНН',
  'form.field.label.unp': 'УНП',
  'form.field.label.kpp': 'КПП',
  'form.field.label.ogrn': 'ОГРН',
  'form.field.label.oked': 'ОКЕД',
  'form.field.label.license-key': 'Лицензионный ключ',
  'form.field.label.license-file': 'Лицензионный файл',

  // FIELD: placeholder
  'form.field.placeholder.select': 'Выбрать',
  'form.field.placeholder.search-archive-name': 'Поиск по имени архива',
  'form.field.placeholder.search-backup-name': 'Поиск по имени резервной копии',

  // FIELD: Validate
  'form.field.validate.min-value': 'Минимальное количество {length} символов.',
  'form.field.validate.max-value': 'Максимальное количество {length} символов.',
  'form.field.validate.wrong-license-key': 'Неверный ключ',
  'form.field.validate.phone-length':
    'Телефон должен содержать {length} символов.',
  'form.field.validate.phone-min-length': 'Введите не менее {length} символов.',
  'form.field.validate.phone-max-length': 'Введите не более {length} символов.',
  'form.field.validate.phone-incorrect': 'Некорректный телефон.',
  'form.field.validate.phone-required': 'Введите телефон.',
  'form.field.validate.subject-required': 'Введите тему.',
  'form.field.validate.country-required': 'Выберите страну.',
  'form.field.validate.system-required': 'Выберите операционную систему.',
  'form.field.validate.product-required': 'Выберите продукт.',
  'form.field.validate.field-required': 'Поле должно быть заполнено.',
  'form.field.validate.email-required': 'Введите email.',
  'form.field.validate.email-incorrect': 'Некорректный email.',
  'form.field.validate.email-not-activated': 'Почта не активирована.',
  'form.field.validate.email-or-password-incorrect':
    'Неверное имя пользователя или пароль',
  'form.field.validate.email-match':
    'Пользователя с таким email не существует.',
  'form.field.validate.email-used':
    'Пользователь с таким email уже зарегистрирован.',
  'form.field.validate.cloud-email-used':
    'Такой аккаунт зарегистрирован в Кибер Облаке. Используйте другой email.',
  'form.field.validate.password-required': 'Введите пароль.',
  'form.field.validate.new-password-required': 'Введите новый пароль.',
  'form.field.validate.password-default': 'Ошибка ввода.',
  'form.field.validate.password-match': 'Пароли не совпадают.',
  'form.field.validate.password-used':
    'Этот пароль уже используется. Придумайте другой пароль.',
  'form.field.validate.password-wrong': 'Неверный пароль.',
  'form.field.validate.password-incorrect': 'Некорректный пароль.',
  'form.field.validate.password-invalid':
    'Пароль может содержать только латинские буквы, цифры и базовые спецсимволы.',
  'form.field.validate.old-password-wrong': 'Старый пароль введен не верно.',
  'form.field.validate.confirm-password-required':
    'Введите подтвержденный пароль.',
  'form.field.validate.password-min':
    'Минимальная длина пароля {length} символа.',
  'form.field.validate.password-max':
    'Максимальная длина пароля {length} символа.',
  'form.field.validate.email-max':
    'Максимальная длина email {length} символов.',
  'form.field.validate.first-name-required': 'Введите имя.',
  'form.field.validate.first-name-max':
    'Максимальная длина имени {length} символа.',
  'form.field.validate.last-name-required': 'Введите фамилию.',
  'form.field.validate.last-name-max':
    'Максимальная длина фамилии {length} символа.',
  'form.field.validate.company-name-required': 'Введите название компании.',
  'form.field.validate.inn-min': 'Введите не менее {length} чисел.',

  // FIELD: Value
  'form.field.value.another-problem': 'Другая проблема',
  'form.field.value.product-problem': 'Проблема с продуктом',

  /**
   * ? PAGE: Products
   */
  'product.cyber-backup-personal': 'Кибер Бэкап Персональный',
  'product.cyber-backup': 'Кибер Бэкап',
  'product.cyber-infrastructure': 'Кибер Инфраструктура',
  'page.products.progress.license-until': 'Доступно до {date}',
  'page.products.category.button.download': 'Скачать',

  'page.products.product.word.or': 'или',
  'page.products.product.word.sub-until': 'до',
  'page.products.product.word.manually': 'вручную.',
  'page.products.product.word.trial': 'Пробная версия',
  'page.products.product.word.subscribe': 'Подписка',
  'page.products.product.word.permanent': 'Постоянная',
  'page.products.product.word.auto-renewal': 'Автопродление',
  'page.products.product.word.ended': 'истекла',
  'page.products.product.word.not-renewed': 'не продлена',
  'page.products.product.word.update': 'обновится',
  'page.products.product.phrase.used-pc': 'Использовано компьютеров',
  'page.products.product.phrase.you-can': 'Вы можете',
  'page.products.product.phrase.auto-renewed':
    'Подписка будет автоматически продлена через {days}.',
  'page.products.product.phrase.auto-renewed-today':
    'Подписка будет автоматически продлена сегодня.',
  'page.products.product.phrase.charge-failed': 'Не удалось списать оплату.',
  'page.products.product.phrase.subscription-expired':
    'Подписка истекла {date}.',
  'page.products.product.phrase.subscription-suspended':
    'Подписка приостановлена {date}.',
  'page.products.product.phrase.trial-expired':
    'Пробная версия истекла {date}.',
  'page.products.product.phrase.trial-expires-in':
    'Пробная версия истекает через {days}.',
  'page.products.product.phrase.subscription-expires-in':
    'Подписка истекает через {days}.',
  'page.products.product.phrase.subscription-expires-today':
    'Подписка истекает сегодня.',
  'page.products.product.phrase.trial-expires-today':
    'Пробная версия истекает сегодня.',
  'page.products.product.phrase.left-day': 'еще {days}',
  'page.products.product.phrase.subscribe-will-be-updated':
    'Подписка обновится',
  'page.products.product.button.enable-auto-renewal-subscription':
    'Автопродление подписки',
  'page.products.product.info.disable-auto-renewal':
    'У вас включено автопродление подписки. Вы можете отключить его в любой момент.',
  'page.products.product.button.done': 'Готово',
  'page.products.product.button.close': 'Закрыть',
  'page.products.product.button.renew': 'Продлить',
  'page.products.product.button.enable-auto-renewal': 'Включить автопродление',
  'page.products.product.button.popup-enable-auto-renewal':
    'включить автопродление',
  'page.products.product.button.increase-quota': 'Увеличить квоту',
  'page.products.product.button.buy-license': 'Купить лицензию',
  'page.products.product.button.popup-buy-license': 'купить лицензию.',
  'page.products.product.button.popup-buy-new-license':
    'купить новую лицензию.',
  'page.products.product.button.popup-renew': 'продлить подписку',
  'page.products.detail.word.out-of': 'из',
  'page.products.detail.cell-name-license': 'Лицензия',
  'page.products.detail.cell-name-dist': 'Дистрибутив',
  'page.products.detail.cell-title-maximum-licenses':
    'Выбрана максимальная лицензия',
  'page.products.detail.word.manage': 'Управлять',
  'page.products.detail.phrase.manage': 'Управлять лицензией',
  'page.products.detail.phrase.computers-used': '{pc} используется',
  'page.products.detail.phrase.unlimited-amount':
    'Безлимитное количество компьютеров',
  'page.products.detail.phrase.unlimited-amount-short': 'Безлимитное',
  'page.products.detail.phrase.cloud-off': 'Без облачного хранилища',
  'page.products.detail.phrase.cloud-storage': '{cloud} облачного хранилища',
  'page.products.detail.button.buy-license': 'Купить еще одну лицензию',
  'page.products.detail.button.increase-quota': 'Увеличить квоту',
  'page.products.detail.button.expand-license': 'Расширить лицензию',
  'page.products.detail.button.download-dist': 'Скачать',
  'page.products.detail.button.open-docs': 'Открыть документацию',
  'page.products.detail.modal.title.license-management': 'Управление лицензией',
  'page.products.detail.modal.empty-pc':
    'Эта лицензия не активирована ни на одном из компьютеров',
  'page.products.detail.modal.sub-title.activated-pc':
    'Активировано компьютеров',
  'page.products.detail.modal.table-th.name-pc': 'Имя компьютера',
  'page.products.detail.modal.table-th.address': 'Адрес',
  'page.products.detail.modal.table-th.activation-date': 'Дата активации',
  'page.products.detail.modal.table-td.deactivate': 'Деактивировать',

  /**
   * ? PAGE: Profile
   */
  'page.profile.title.user-info': 'Сведения о пользователе',
  'page.profile.title.newsletter': 'Рассылка',
  'page.profile.title.password': 'Пароль',
  'page.profile.text.password-modified': 'Изменен {date}',
  'page.profile.text.subscribed': 'Вы подписаны на рассылку',
  'page.profile.text.not-subscribed': 'Вы не подписаны на рассылку',
  'page.profile.button.remove-profile': 'Удалить профиль',
  'page.profile.button.edit': 'Редактировать',
  'page.profile.button.change': 'Изменить',
  'page.profile.button.subscribe': 'Подписаться',
  'page.profile.button.unsubscribe': 'Отписаться',
  'page.profile.cell.first-name': 'Имя',
  'page.profile.cell.last-name': 'Фамилия',
  'page.profile.cell.country': 'Страна',
  'page.profile.cell.email': 'Email',
  'page.profile.cell.phone': 'Телефон',
  'page.profile.phone.not-specified': `Не указан`,
  'page.profile.first-name.not-specified': `Не указано`,
  'page.profile.last-name.not-specified': `Не указана`,

  /**
   * ? PAGE: Backup
   */
  'page.backup.mobile-name.contacts': 'Контакты',
  'page.backup.mobile-name.messages': 'Сообщения',
  'page.backup.mobile-name.gallery': 'Галерея',
  'page.backup.mobile-name.calendars': 'Календари',
  'page.backup.mobile.download-only': 'Только скачивание',
  'page.backup.mobile.view-and-download': 'Просмотр и скачивание',
  'page.backup.version.date': 'Версия: {date}',
  'page.backup.device.not-recognized': 'Устройство не определено',
  'page.backup.info.files-not-found':
    'Папка «{folder}» отсутствует в версии резервной копии {version}',
  'page.mobile-backup.info.you-can-do':
    'Контакты и содержимое галереи можно просмотреть и скачать, сообщения и календари – только скачать',

  /**
   * ? PAGE: Backups
   */
  'page.backups.backup.title': 'Резервная копия',
  'page.backups.title': 'Кибер Бэкап Персональный',
  'page.backups.info.no-backups': 'Сейчас у вас нет резервных копий',

  /**
   * ? PAGE: Archive
   */
  'page.archive.info.archive-not-found': 'Архив «{name}» не найден',
  'page.archive.info.files-not-found': 'Папка «{folder}» отсутствует',

  /**
   * ? PAGE: Archives
   */
  'page.archives.archive.title': 'Архив',
  'page.archives.title': 'Кибер Бэкап Персональный',
  'page.archives.info.no-archives': 'Сейчас у вас нет архивов',

  /**
   * ? PAGE: Error
   */
  'page.errors.link.go-home': 'Перейти на главную',
  'page.errors.title.page-not-found': 'Эта страница не найдена',

  /**
   * ? COMPONENT: Header
   */
  'component.header.title.undefined': 'Раздел не найден',
  'component.header.title.products': 'Продукты',
  'component.header.title.profile': 'Профиль',
  'component.header.title.distributions': 'Дистрибутивы',
  'component.header.title.backups': 'Резервные копии',
  'component.header.title.archives': 'Архивы',
  'component.header.title.support': 'Поддержка',
  'component.header.title.control': 'Консоль управления',
  'component.header.button.title.add-key': 'Добавить ключ',
  'component.header.icon.title.order-history': 'История заказов',
  'component.header.icon.title.support': 'Поддержка',
  'component.header.icon.title.profile-menu': 'Меню профиля',

  /**
   * ? COMPONENT: Footer
   */
  'component.footer.title.cyberprotect': '© ООО «Киберпротект»',

  /**
   * ? COMPONENT: File manager
   */
  'component.file-manager.button.delete': 'Удалить файл',
  'component.file-manager.submit.attach-file': 'Прикрепить файл',
  'component.file-manager.submit.attach-certificate': 'Прикрепить сертификат',
  'component.file-manager.submit.license-file': 'Загрузить лицензионный файл',
  'component.file-manager.phrase.warning-amount': 'Можно загрузить только',
  'component.file-manager.phrase.warning-size':
    'Общий размер файлов не может быть больше',

  /**
   * ? COMPONENT: Profile menu
   */
  'component.profile.menu.title.empty-products':
    'Сейчас у вас нет активных продуктов',
  'component.profile.menu.button.change-password': 'Изменить пароль',
  'component.profile.menu.button.language': 'Язык',
  'component.profile.menu.button.sign-out': 'Выйти',
  'component.profile.menu.button.buy-product': 'Купить продукт',
  'component.profile.menu.button.has-key': 'У меня уже есть ключ',

  /**
   * ? COMPONENT: Support
   */
  'component.support-menu.nav.title.support': 'Техническая поддержка',
  'component.support-menu.nav.title.sales-dep': 'Отдел продаж',
  'component.support-menu.nav.title.history': 'История обращений',
  'component.support-menu.header.title.support': 'Обращение в техподдержку',
  'component.support-menu.header.title.sales-dep': 'Обращение в отдел продаж',
  'component.support-menu.header.title.history': 'История обращений',
  'component.support-menu.content.title.contact': 'Контактные данные',
  'component.support-menu.content.title.product': 'Тип обращения',
  'component.support-menu.content.title.report': 'Сообщение о проблеме',
  'component.support-menu.content.title.request': 'Запрос',
  'component.support-menu.content.word.thanks': 'Спасибо!',
  'component.support-menu.content.word.appeal': 'Ваше обращение',
  'component.support-menu.content.word.registered': 'зарегистрировано.',
  'component.support-menu.content.word.attach-certificate':
    'Прикрепите действующий сертификат технической поддержки',
  'component.support-menu.content.phrase.contact-you':
    'В ближайшее время мы с вами свяжемся. Вы можете отслеживать статус своего обращения в разделе «История обращений».',
  'component.support-menu.content.phrase.contact-you-mobile':
    'В ближайшее время мы с вами свяжемся. Вы можете отслеживать статус вашего обращения на его странице.',
  'component.support-menu.content.button.support-short-request':
    'В техподдержку',
  'component.support-menu.content.button.support-request':
    'Обратиться в техподдержку',
  'component.support-menu.content.button.sales-short-request': 'В отдел продаж',
  'component.support-menu.content.button.sales-request':
    'Обратиться в отдел продаж',
  'component.support-menu.content.button.new-message':
    'Написать еще одно сообщение',
  'component.support-menu.content.button.go-to-appeals': 'Перейти к обращениям',
  'component.support-menu.content.description.contact':
    'Проверьте правильность указанных данных. Вы можете изменить информацию о себе в разделе «Профиль».',
  'component.support-menu.content.description.product':
    'Выберите продукт, в котором возникла проблема. Это позволит решить ваш вопрос быстрее.',
  'component.support-menu.content.description.report':
    'Максимально подробно опишите свою проблему и приложите файлы, которые помогут нам разобраться.',
  'component.support-menu.content.description.request':
    'Выберите продукт и задайте интересующий вас вопрос.',
  'component.support-menu.footer.button.call-department':
    'Позвонить в отдел продаж',
  'component.support-menu.footer.text.phone-department':
    'Номер телефона отдела продаж',
  'component.support-menu.footer.button.call-technical':
    'Позвонить в техподдержку',
  'component.support-menu.footer.text.phone-technical':
    'Номер телефона техподдержки',
  'component.support-menu.history.table-column-name.contact': 'Обращение',
  'component.support-menu.history.table-column-name.created': 'Создано',
  'component.support-menu.history.table-column-name.status': 'Статус',
  'component.support-menu.history.status-name.resolve': 'Решение предоставлено',
  'component.support-menu.history.status-name.hold': 'Отложено',
  'component.support-menu.history.status-name.in_work': 'В работе',
  'component.support-menu.history.status-name.new': 'Новое',
  'component.support-menu.history.status-name.closed': 'Обращение закрыто',
  'component.support-menu.history.status-name.reject': 'Не решено, закрыто',
  'component.support-menu.history.status-name.pending':
    'Ожидает ответа от клиента',
  'component.support-menu.history.status-name.undefined': 'Не определен',
  'component.support-menu.history.detail.title.overview': 'Общие сведения',
  'component.support-menu.history.detail.title.contact': 'Контактные данные',
  'component.support-menu.history.detail.title.status': 'Текущий статус',
  'component.support-menu.history.detail.label.type': 'Тип обращения',
  'component.support-menu.history.detail.label.created': 'Создано',
  'component.support-menu.history.detail.label.product': 'Продукт',
  'component.support-menu.history.detail.label.problem': 'Описание проблемы',
  'component.support-menu.history.detail.label.attach': 'Прикрепленные файлы',
  'component.support-menu.history.detail.label.user': 'Пользователь',
  'component.support-menu.history.detail.label.phone': 'Телефон',
  'component.support-menu.history.detail.label.email': 'Email',
  'component.support-menu.history.detail.label.order-number': 'Номер заявки',
  'component.support-menu.history.empty-content.title':
    'Сейчас у вас нет обращений',
  'component.support-menu.history.empty-content.link':
    'Обратиться в техподдержку',
  'component.support-menu.error.product-not-found': 'Продукт не найден',

  /**
   * ? COMPONENT: Tooltip
   */
  'component.tooltip.cloud.description':
    'В день окончания действия одной или нескольких лицензий доступный объем облачного хранилища уменьшится.',
  'component.tooltip.plan-expansion.description':
    'Скидка действует только на первый год покупки.',
  'component.tooltip.support-lock-down.lock-down.not-available':
    'Нам очень жаль, но техническая поддержка временно недоступна.',
  'component.tooltip.support-lock-down.you-can': 'Вы можете',
  'component.tooltip.support-lock-down.leave-request': 'оставить запрос',
  'component.tooltip.support-lock-down.through-website': 'через сайт.',

  /**
   * ? COMPONENT: StepBack
   */
  'component.step-back.action-label.go-back': 'Вернуться назад',

  /**
   * ? COMPONENT: Notify
   */
  'component.notify.message-error-invalid-account-form-assign-license':
    'На этот email зарегистрирован персональный аккаунт. Используйте другой email.',
  'component.notify.message-assign-license-error':
    'Не удалось привязать ключи к аккаунту. Обратитесь в техническую поддержку.',
  'component.notify.message-create-account-error':
    'Невозможно создать аккаунт. Обратитесь в техническую поддержку.',
  'component.notify.message-success.copy-phone':
    'Мобильный телефон был скопирован',
  'component.notify.message-success.copy-email':
    'Почтовый адрес был скопирован',
  'component.notify.message-success.copy-address': 'Адрес был скопирован',
  'component.notify.message-error.copy-failed':
    'Не удалось скопировать элемент',
}
