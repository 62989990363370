import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  TMapSession,
  ISessionState,
} from '@/store/modules/client/session/types'

type TSessionGetter = GetterTree<ISessionState, RootState>

export const getters: TSessionGetter = {
  /**
   * session
   * ? Мапа бэкапов с привязанными сессиями
   *
   * @param {ISessionState} state data
   * @returns {TMapSession | null} мапа бэкапов с привязанными сессиями
   */
  session: (state: ISessionState): TMapSession | null => {
    return state.session
  },

  /**
   * sessionId
   * ? Получить идентификатор сессии
   *
   * @param {ISessionState} state data
   * @param {object} getters computed
   * @returns {string} идентификатор сессии
   */
  sessionId: (state: ISessionState, getters): string => {
    return state.session?.[getters.currentDecodedFullBackupOrArchiveName] ?? ''
  },

  /**
   * currentBackupOrArchiveName
   * ? Имя бекапа или архива
   *
   * @param {ISessionState} state data
   * @param {object} getters computed
   * @param {RootState} rootState root state
   * @param {object} rootGetters root computed
   * @returns {string} имя бекапа или архива
   */
  currentBackupOrArchiveName: (
    state: ISessionState,
    getters,
    rootState,
    rootGetters
  ): string => {
    return getters.isBackupPage
      ? rootGetters['backup/currentBackupName']
      : rootGetters['archive/currentArchiveName']
  },

  /**
   * currentDecodedFullBackupOrArchiveName
   * ? Полное декодированное имя текущей резервной копии
   *
   * @param {ISessionState} state data
   * @param {object} getters computed
   * @returns {string} полное имя текущей резервной копии
   */
  currentDecodedFullBackupOrArchiveName: (
    state: ISessionState,
    getters
  ): string => {
    return decodeURIComponent(getters.currentBackupOrArchiveName)
  },

  /**
   * hasSessionId
   * ? Признак существования идентификатора сессии
   *
   * @param {ISessionState} state data
   * @param {object} getters computed
   * @returns {boolean} признак существования идентификатора сессии
   */
  hasSessionId: (state: ISessionState, getters): boolean => {
    return state.session !== null && getters.sessionId !== ''
  },

  /**
   * isBackupPage
   * ? Признак что текущие страницы входят в состав бэкапа
   *
   * @param {ISessionState} state data
   * @param {object} getters computed
   * @param {RootState} rootState root state
   * @param {object} rootGetters root computed
   * @returns {boolean} признак что текущие страницы входят в состав бэкапа
   */
  isBackupPage: (
    state: ISessionState,
    getters,
    rootState,
    rootGetters
  ): boolean => {
    return ['backup', 'backups'].includes(rootGetters['nav/currentRouteName'])
  },

  /**
   * isEncryptedBackupOrArchive
   * ? Признак зашифрованного бэкапа или архива
   *
   * @param {ISessionState} state data
   * @param {object} getters computed
   * @param {RootState} rootState root state
   * @param {object} rootGetters root computed
   * @returns {string} признак зашифрованного бэкапа или архива
   */
  isEncryptedBackupOrArchive: (
    state: ISessionState,
    getters,
    rootState,
    rootGetters
  ): string => {
    return getters.isBackupPage
      ? rootGetters['backup/isEncryptedBackup']
      : rootGetters['archive/isEncryptedArchive']
  },

  /**
   * isVisibleEncryptedModal
   * ? Признак видимости модального окна зашифрованных бэкапов
   *
   * @param {ISessionState} state data
   * @returns {boolean} признак видимости модального окна зашифрованных бэкапов
   */
  isVisibleEncryptedModal: (state: ISessionState): boolean => {
    return state.isVisibleEncryptedModal
  },

  /**
   * isLoadingGetSession
   * ? Процесс получения сессии
   *
   * @param {ISessionState} state data
   * @returns {boolean} процесс получения сессии
   */
  isLoadingGetSession: (state: ISessionState): boolean => {
    return state.isLoadingGetSession
  },

  /**
   * isLoadingCreateSession
   * ? Процесс создания сессии
   *
   * @param {ISessionState} state data
   * @returns {boolean} процесс создания сессии
   */
  isLoadingCreateSession: (state: ISessionState): boolean => {
    return state.isLoadingCreateSession
  },
}
