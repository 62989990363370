import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  UsersState,
  IUsersLocalMeta,
  TUserAccountsState,
  TReportUserAccountsState,
} from '@/store/modules/admin/users/types'
import {
  hasDate,
  hasUserName,
  getFormattedDate,
  getFormattedUserName,
  hasExpiredTrialLicense,
  getFormattedSimpleValue,
  getStatusExpirationDate,
  getFormattedReportCloudStorage,
  getFormattedReportLicensedMachines,
} from '@/helpers/formatted-functions'

type UsersTree = GetterTree<UsersState, RootState>

export const getters: UsersTree = {
  /**
   * users
   * ? Список пользователей
   *
   * @param {UsersState} state data
   * @returns {TUserAccountsState} список пользователей
   */
  users: (state): TUserAccountsState => {
    return state.users.map((user) => {
      const { email, is_active, created_date } = user

      return {
        ...user,
        hasUserName: hasUserName(user),
        formatUserName: getFormattedUserName(user),
        formatUserEmail: getFormattedSimpleValue(email),
        formatCreatedDate: getFormattedDate(created_date),
        formatIsActive: getFormattedSimpleValue(is_active),
      }
    })
  },

  /**
   * users
   * ? Список пользователей
   *
   * @param {UsersState} state data
   * @returns {TReportUserAccountsState} список пользователей
   */
  reportUsers: (state): TReportUserAccountsState => {
    return state.reportUsers.map((user) => {
      const {
        email,
        is_active,
        sum_orders,
        created_date,
        has_trial_license,
        license_active_count,
        license_expired_count,
        license_max_expired_date,
        last_cloud_quota_usage_date,
      } = user

      return {
        ...user,
        hasUserName: hasUserName(user),
        formatUserName: getFormattedUserName(user),
        formatUserEmail: getFormattedSimpleValue(email),
        formatCreatedDate: getFormattedDate(created_date),
        formatIsActive: getFormattedSimpleValue(is_active),
        isExpiredTrialLicense: hasExpiredTrialLicense(user),
        formatSumOrders: getFormattedSimpleValue(sum_orders),
        hasUserBackups: hasDate(last_cloud_quota_usage_date),
        hasMaxExpirationDate: hasDate(license_max_expired_date),
        formatCloudStorage: getFormattedReportCloudStorage(user),
        formatLicensedMachine: getFormattedReportLicensedMachines(user),
        formatHasTrialLicense: getFormattedSimpleValue(has_trial_license),
        formatLastBackupDate: getFormattedDate(last_cloud_quota_usage_date),
        formatMaxExpirationDate: getFormattedDate(license_max_expired_date),
        formatLicenseActiveCount: getFormattedSimpleValue(license_active_count),
        formatLicenseExpiredCount: getFormattedSimpleValue(
          license_expired_count
        ),
        statusExpirationDate: getStatusExpirationDate(license_max_expired_date),
      }
    })
  },

  /**
   * deletedAccountIds
   * ? Список идентификаторов удаленных аккаунтов
   *
   * @param {UsersState} state data
   * @returns {string[]} список идентификаторов удаленных аккаунтов
   */
  deletedAccountIds: (state): string[] => {
    return state.deletedAccountIds
  },

  /**
   * currentReportUsersMeta
   * ? Текущие мета данные списка пользователей
   *
   * @param {UsersState} state data
   * @returns {IUsersLocalMeta} текущие мета данные списка пользователей
   */
  currentReportUsersMeta: (state): IUsersLocalMeta => {
    return state.currentReportUsersMeta
  },

  /**
   * currentUsersMeta
   * ? Текущие мета данные списка пользователей для переноса лицензий
   *
   * @param {UsersState} state data
   * @returns {IUsersLocalMeta} текущие мета данные списка пользователей для переноса лицензий
   */
  currentUsersMeta: (state): IUsersLocalMeta => {
    return state.currentUsersMeta
  },

  /**
   * isLoadingUsers
   * ? Процесс загрузки пользователей
   *
   * @param {UsersState} state data
   * @returns {boolean} процесс загрузки пользователей
   */
  isLoadingUsers: (state): boolean => {
    return state.isLoadingUsers
  },

  /**
   * isLoadingReportUsers
   * ? Процесс загрузки отчетов с пользователями
   *
   * @param {UsersState} state data
   * @returns {boolean} процесс загрузки отчетов с пользователями
   */
  isLoadingReportUsers: (state): boolean => {
    return state.isLoadingReportUsers
  },

  /**
   * isLoadingExportUsers
   * ? Процесс экспорта пользователей
   *
   * @param {UsersState} state data
   * @returns {boolean} процесс экспорта пользователей
   */
  isLoadingExportUsers: (state): boolean => {
    return state.isLoadingExportUsers
  },

  /**
   * isLoadingDeleteAccounts
   * ? Процесс удаления аккаунтов
   *
   * @param {UsersState} state data
   * @returns {boolean} процесс удаления аккаунтов
   */
  isLoadingDeleteAccounts: (state): boolean => {
    return state.isLoadingDeleteAccounts
  },

  /**
   * isLoadingDeleteAllBackups
   * ? Процесс удаления резервных копий
   *
   * @param {UsersState} state data
   * @returns {boolean} процесс удаления резервных копий
   */
  isLoadingDeleteAllBackups: (state): boolean => {
    return state.isLoadingDeleteAllBackups
  },
}
