var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',_vm._g({staticClass:"vpc-icon-container",style:({
    '--width': `${_vm.iconWidth}px`,
    '--height': `${_vm.iconHeight}px`,
  }),attrs:{"title":_vm.title}},_vm.$listeners),[_c('svg',{class:{
      'vpc-icon': true,
      'vpc-icon_mask': !!_vm.currentColor,
    },style:({
      '--color': _vm.currentColor,
      '--url': `url(${require(`@/assets/img/svg/${_vm.name}.svg`)})`,
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }