import type {
  SupportState,
  SupportModule,
} from '@/store/modules/client/support/types'

import { SUPPORT_STATUS } from '@/config/constants'
import { getters } from '@/store/modules/client/support/getters'
import { actions } from '@/store/modules/client/support/actions'
import { mutations } from '@/store/modules/client/support/mutations'
import style from '@/assets/styles/_export-var.module.scss'

const state: SupportState = {
  support: null,
  supports: [],
  isLoading: false,
  isVisibleSupportMenu: false,
  swarmicaProductInfo: {
    products: [],
    editions: [],
    platforms: [],
    priorities: [],
    versions: [],
    types: [],
  },
  statuses: {
    resolve: {
      list: [SUPPORT_STATUS.SOLVED],
      icon: {
        name: 'circle-info',
        status: 'resolve',
        color: style.colorAvFixedSuccess,
      },
    },
    closed: {
      list: [SUPPORT_STATUS.CLOSED],
      icon: {
        name: 'circle-success',
        status: 'closed',
        color: style.colorAvFixedSuccess,
      },
    },
    reject: {
      list: [SUPPORT_STATUS.DELETED],
      icon: {
        name: 'circle-error',
        status: 'reject',
        color: style.colorAvFixedDanger,
      },
    },
    pending: {
      list: [SUPPORT_STATUS.PENDING],
      icon: {
        name: 'user',
        status: 'pending',
        color: 'var(--av-fixed-warning)',
      },
    },
    hold: {
      list: [SUPPORT_STATUS.HOLD],
      icon: {
        name: 'pause',
        status: 'hold',
        color: 'var(--av-solid-fixed-lighter)',
      },
    },
    in_work: {
      list: [SUPPORT_STATUS.OPEN],
      icon: {
        name: 'play',
        status: 'in_work',
        color: 'var(--av-brand-primary)',
      },
    },
    new: {
      list: [SUPPORT_STATUS.NEW],
      icon: {
        name: 'watch',
        status: 'new',
        color: style.colorAvBrandPrimary,
      },
    },
  },
}

export const support: SupportModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
