import type { MutationTree } from 'vuex'
import type { ProductResponse } from '@/api/types'
import type { ICampaignState } from '@/store/modules/admin/campaign/types'
import type { TCampaignPartialItem } from '@/store/modules/admin/campaign/types'

type TCampaignTree = MutationTree<ICampaignState>

import { setLoading } from '@/helpers/mutation-functions'

export const mutations: TCampaignTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_CURRENT_CAMPAIGN
   * ? Установить текущую кампанию
   *
   * @param {ICampaignState} state data
   * @param {TCampaignPartialItem} campaign текущая кампания
   * @returns {void}
   */
  SET_CURRENT_CAMPAIGN: (
    state: ICampaignState,
    campaign: TCampaignPartialItem
  ): void => {
    state.campaign = campaign
  },

  /**
   * SET_CURRENT_LICENSES
   * ? Установить список лицензий текущей кампании
   *
   * @param {ICampaignState} state data
   * @param {ProductResponse[]} licenses текущая кампания
   * @returns {void}
   */
  SET_CURRENT_LICENSES: (
    state: ICampaignState,
    licenses: ProductResponse[]
  ): void => {
    state.licenses = licenses
  },

  /**
   * CLEAR_CAMPAIGN_STATES
   * ? Очистить состояние текущей кампании
   *
   * @param {ICampaignState} state data
   * @returns {void}
   */
  CLEAR_CAMPAIGN_STATES: (state: ICampaignState): void => {
    state.campaign = {}
  },
}
