import type { RootState } from '@/store/types'
import type { GetterTree } from 'vuex'
import type { TDisplayOrientation } from '@/store/types'
import type { IErrorResponse, ApiFormError } from '@/api/types'

import { API_FORM_ERRORS } from '@/api/errors'
import style from '@/assets/styles/_export-var.module.scss'

type RootTree = GetterTree<RootState, RootState>

export const getters: RootTree = {
  /**
   * isLoading
   * ? Статус выполнения запросов
   *
   * @param {RootState} state data
   * @returns {boolean} isLoading
   */
  isLoading: (state: RootState): boolean => state.isLoading,

  /**
   * serverError
   * ? Серверная ошибка
   *
   * @param {RootState} state data
   * @returns {IErrorResponse | null} ошибки с сервера
   */
  serverError: (state: RootState): IErrorResponse | null => state.serverError,

  /**
   * apiFormError
   * ? Сформированная серверная ошибка для формы
   *
   * @param {RootState} state data
   * @returns {ApiFormError | null} ошибка для формы
   */
  formError: (state: RootState): ApiFormError | null => {
    return state.serverError
      ? API_FORM_ERRORS[state.serverError?.code] ?? null
      : null
  },

  /**
   * displayWidth
   * ? Ширина экрана
   *
   * @param {RootState} state data
   * @returns {number} ширина экрана
   */
  displayWidth: (state: RootState): number => state.displayWidth,

  /**
   * displayHeight
   * ? Высота экрана
   *
   * @param {RootState} state data
   * @returns {number} высота экрана
   */
  displayHeight: (state: RootState): number => state.displayHeight,

  /**
   * displayType
   * ? Тип текущего дисплея
   *
   * @param {RootState} state data
   * @returns {string} тип текущего дисплея
   */
  displayType: (state: RootState): string => {
    const mediaSizeTablet = parseInt(style.mediaSizeTablet, 10)
    const mediaSizeMobile = parseInt(style.mediaSizeMobile, 10)

    if (
      state.displayWidth <= mediaSizeMobile ||
      state.displayHeight <= mediaSizeMobile
    ) {
      return 'mobile'
    }

    if (state.displayWidth <= mediaSizeTablet) {
      return 'tablet'
    }

    return 'desktop'
  },

  /**
   * displayOrientation
   * ? Ориентация экрана
   *
   * @param {RootState} state data
   * @returns {TDisplayOrientation} ориентация экрана
   */
  displayOrientation: (state: RootState): TDisplayOrientation =>
    state.displayHeight > state.displayWidth ? 'landscape' : 'portrait',

  /**
   * isMobile
   * ? Тип дисплея смартфон
   *
   * @param {RootState} state data
   * @param {object} getters computed
   * @returns {string} тип дисплея смартфон
   */
  isMobile: (state: RootState, getters): boolean => {
    return getters.displayType === 'mobile'
  },

  /**
   * isTablet
   * ? Тип дисплея планшет
   *
   * @param {RootState} state data
   * @param {object} getters computed
   * @returns {string} тип дисплея планшет
   */
  isTablet: (state: RootState, getters): boolean => {
    return getters.displayType === 'tablet'
  },

  /**
   * isDesktop
   * ? Тип дисплея экран
   *
   * @param {RootState} state data
   * @param {object} getters computed
   * @returns {string} тип дисплея экран
   */
  isDesktop: (state: RootState, getters): boolean => {
    return getters.displayType === 'desktop'
  },

  /**
   * visibleUserCommonError
   * ? Признак открытия/закрытия общего модального окна с шаблоном ошибок (а именно ошибки которые не обрабатываются и имеют статус 4xx и 5xx)
   *
   * @param {RootState} state data
   * @returns {boolean} признак открытия/закрытия общего модального окна
   */
  visibleUserCommonError: (state: RootState): boolean => {
    return state.hasUserCommonError
  },

  /**
   * userCommonErrorMessage
   * ? Возвращает кастомный текст ошибки.
   *
   * @param {RootState} state data
   * @returns {boolean} текст ошибки
   */
  userCommonErrorMessage: (state: RootState): string | null => {
    return state.userCommonErrorMessage
  },

  /**
   * visibleAdminCommonError
   * ? Признак открытия/закрытия общего notify с детальными ошибками (а именно ошибки которые не обрабатываются и имеют статус 4xx и 5xx)
   *
   * @param {RootState} state data
   * @returns {boolean} признак открытия/закрытия общего notify
   */
  visibleAdminCommonError: (state: RootState): boolean => {
    return state.hasAdminCommonError
  },
}
