import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  TCountry,
  TAccount,
  ICompany,
  EmailPayload,
  TBaseAccount,
  LoginPayload,
  TBusinessAccount,
  NewPasswordPayload,
  IRegisterAccountResponse,
} from '@/api/types'
import type {
  AccountState,
  AccountUpdateForm,
  IPersonalRegisterAccountLocalPayload,
  IBusinessRegisterAccountLocalPayload,
  ActivateAccountPayload,
  SubscriptionProfileStatus,
} from '@/store/modules/account/types'

type AccountTree = ActionTree<AccountState, RootState>
type AccountContext = ActionContext<AccountState, RootState>

import api from '@/api'
import Cookies from '@/helpers/cookies'
import { clearStates } from '@/store/manager'
import { reloadPage, initQueryParams } from '@/helpers'
import { ROLES, TABLE_KEY, TABLE_TYPE, COOKIE_NAMES } from '@/config/constants'
import {
  hasItemStorage,
  getItemStorage,
  setItemStorage,
  removeItemStorage,
} from '@/helpers/local-storage'

const {
  TABLE_USER_ORDERS,
  TABLE_USER_BACKUPS,
  TABLE_REPORT_USERS,
  TABLE_REPORT_ORDERS,
  TABLE_REPORT_LICENSES,
  TABLE_REPORT_CAMPAIGNS,
} = TABLE_KEY

export const actions: AccountTree = {
  /**
   * registerPersonalAccount
   * ? Регистрация персонального аккаунта
   *
   * @param {AccountContext} ctx context
   * @param {IPersonalRegisterAccountLocalPayload} form форма для регистрации персонального аккаунта
   * @returns {Promise<IRegisterAccountResponse>} идентификатор клиентского аккаунта
   */
  registerPersonalAccount: (
    { commit }: AccountContext,
    form: IPersonalRegisterAccountLocalPayload
  ): Promise<IRegisterAccountResponse> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'register-customer' })

    const formData = {
      email: form.email,
      password: form.password,
      last_name: form.lastName,
      first_name: form.firstName,
      is_subscribed: form.isSubscribed,
    }

    return (
      api.account
        .registerPersonalAccount(formData)
        .finally(() => {
          commit('SET_LOADING_PROCESS', {
            loading: false,
            name: 'register-customer',
          })
        })
        // Вернуть данные после процесса регистрации 'register-customer'
        .then((data) => data)
    )
  },

  /**
   * fetchIsEnabledBusinessRegister
   * ? Включена ли регистрация бизнес аккаунта на площадке
   *
   * @param {AccountContext} ctx context
   * @returns {Promise<void>}
   */
  fetchIsEnabledBusinessRegister: ({
    commit,
  }: AccountContext): Promise<void> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'enabled-business-register',
    })

    return api.lk
      .getIsEnabledBusinessRegister()
      .then((isEnabledBusinessRegister: boolean) => {
        commit('SET_IS_ENABLED_BUSINESS_REGISTER', isEnabledBusinessRegister)
      })
      .catch(() => {
        commit('SET_IS_ENABLED_BUSINESS_REGISTER', false)
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'enabled-business-register',
        })
      })
  },

  /**
   * registerBusinessAccount
   * ? Регистрация бизнес аккаунта
   *
   * @param {AccountContext} ctx context
   * @param {IBusinessRegisterAccountLocalPayload} form форма для регистрации бизнес аккаунта
   * @returns {Promise<IRegisterAccountResponse>} идентификатор бизнес аккаунта
   */
  registerBusinessAccount: (
    { commit }: AccountContext,
    form: IBusinessRegisterAccountLocalPayload
  ): Promise<IRegisterAccountResponse> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'register-business' })

    const formData = {
      inn: form.inn,
      phone: form.phone,
      email: form.email,
      address: form.address,
      password: form.password,
      last_name: form.lastName,
      first_name: form.firstName,
      company_type: form.companyType,
      company_name: form.companyName,
      is_subscribed: form.isSubscribed,
      country_code: form.companyCountry.toUpperCase(),
      organization_type: form.organizationType || '',
      ogrn: form.ogrn,
      kpp: form.kpp,
      oked: form.oked,
    }

    return (
      api.account
        .registerBusinessAccount(formData)
        .finally(() => {
          commit('SET_LOADING_PROCESS', {
            loading: false,
            name: 'register-business',
          })
        })
        // Вернуть данные после процесса регистрации 'register-business'
        .then((data) => data)
    )
  },

  /**
   * activate
   * ? Активация аккаунта (регистрация по ссылке)
   *
   * @param {AccountContext} param0 context
   * @param {Function} param0.dispatch action
   * @param {ActivateAccountPayload} form форма для активации аккаунта
   * @returns {void}
   */
  activate: (
    { dispatch }: AccountContext,
    form: ActivateAccountPayload
  ): void => {
    const formData = {
      token: form.token,
      account_id: form.account_id,
      last_name: form.lastName,
      first_name: form.firstName,
      password: form.password,
      is_subscribed: form.isSubscribed,
    }

    api.account
      .accountActivateWithForm(formData)
      .then(() => dispatch('setAccount'))
      .catch(() => null)
  },

  /**
   * login
   * ? Аутентификация аккаунта
   *
   * @param {AccountContext} param0 context
   * @param {Function} param0.commit mutation
   * @param {Function} param0.dispatch action
   * @param {LoginPayload} form форма для аутентификации аккаунта
   * @returns {Promise<void>}
   */
  login: (
    { commit, dispatch }: AccountContext,
    form: LoginPayload
  ): Promise<void> => {
    commit('SET_LOADING', true, { root: true })

    return api.idp
      .accountLogin(form)
      .then(() => dispatch('setAccount'))
      .finally(() => commit('SET_LOADING', false, { root: true }))
  },

  /**
   * logout
   * ? Закрыть сессию и выйти из аккаунта
   *
   * @param {AccountContext} context context
   * @param {boolean} reload признак перезагрузки страницы (по умолчанию false)
   * @returns {void}
   */
  logout: ({ dispatch }: AccountContext, reload: false): void => {
    const isReloadPage = typeof reload === 'boolean' ? reload : false

    api.idp.accountLogout().finally(() => dispatch('resetApp', isReloadPage))
  },

  /**
   * resetApp
   * ? Обнулить все данные приложения
   *
   * @param {AccountContext} ctx context
   * @param {boolean} isReloadPage признак перезагрузки страницы
   * @returns {void}
   */
  resetApp: (ctx: AccountContext, isReloadPage: boolean): void => {
    removeItemStorage(TABLE_TYPE[TABLE_USER_ORDERS])
    removeItemStorage(TABLE_TYPE[TABLE_USER_BACKUPS])
    removeItemStorage(TABLE_TYPE[TABLE_REPORT_USERS])
    removeItemStorage(TABLE_TYPE[TABLE_REPORT_ORDERS])
    removeItemStorage(TABLE_TYPE[TABLE_REPORT_LICENSES])
    removeItemStorage(TABLE_TYPE[TABLE_REPORT_CAMPAIGNS])

    isReloadPage ? reloadPage() : clearStates()
  },

  /**
   * changePassword
   * ? Изменить пароль
   *
   * @param {AccountContext} context context
   * @param {NewPasswordPayload} form форма для изменения пароля
   * @returns {Promise<void>}
   */
  changePassword: (
    { commit }: AccountContext,
    form: NewPasswordPayload
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'change-password' })

    return api.idp.setAccountPassword(form).finally(() => {
      commit('SET_LOADING_PROCESS', { loading: false, name: 'change-password' })
    })
  },

  /**
   * resetPassword
   * ? Запрос на предоставление ссылки для сброса пароля
   *
   * @param {AccountContext} context context
   * @param {EmailPayload} form форма для сброса пароля от аккаунта
   * @returns {Promise<void>}
   */
  resetPassword: (
    context: AccountContext,
    form: EmailPayload
  ): Promise<void> => {
    return api.idp.resetAccountPassword(form)
  },

  /**
   * setAccount
   * ? Установить аккаунт пользователя
   *
   * @param {AccountContext} param0 context
   * @param {object} param0.state data
   * @param {Function} param0.commit mutation
   * @param {Function} param0.dispatch action
   * @returns {Promise<TAccount | null>} аккаунт пользователя
   */
  setAccount: ({
    state,
    commit,
    getters,
    dispatch,
    rootGetters,
  }: AccountContext): Promise<TAccount | null> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'account' })

    return api.account
      .getCurrentAccount()
      .then(async (account) => {
        if (!state.email) {
          commit('SET_USER_EMAIL', account.email)
        }

        commit('SET_USER_ACCOUNT', account)
        Cookies.set(COOKIE_NAMES.ACCOUNT_ID, account.id)

        const role = account.client_type
        const isRoleUser = getters.isRoleUser
        const isRoleBusinessUser = getters.isRoleBusinessUser
        const { page } = initQueryParams()
        const { pages, name } = rootGetters['nav/homePage']
        const isProductHomePage = page && pages.includes(page) && page === name

        setItemStorage('role', role)

        if (isRoleBusinessUser) {
          /**
           * При инициализации и обновлении страниц загрузить часть лицензий
           *
           * Обрабатываем данный кейс исходя из этой задачи https://jira.aip.ooo/browse/CPS08-1305
           */
          await dispatch('externalProducts/preFetchExternalLicenses', null, {
            root: true,
          })
        }

        if (isRoleUser) {
          if (hasItemStorage('backup-session')) {
            commit(
              'session/SET_MAP_SESSION',
              getItemStorage('backup-session'),
              { root: true }
            )
          }

          /**
           * Запрос только для пользователя ЛК, необходимо дождаться информации о хранилище аккаунта в межсервисном взаимодействии
           *
           * Функционал отвечает за переключение старого и нового WR (необходимо дождаться ответа и применить вернувшийся признак для корректной работы маршрутов и навигационного меню)
           */
          await dispatch('nav/fetchUrlWebRestore', null, { root: true })

          /**
           * При инициализации и обновлении страниц загрузить список продуктов ТОЛЬКО в том случае, если мы логинимся НЕ на главную страницу "products" и текущая страница ВХОДИТ в список зарезервированных страниц ЛК (см. store: nav/index homePages)
           *
           * Обрабатываем данный кейс исходя из этой задачи https://jira.aip.ooo/browse/OS-1243
           */
          if (!isProductHomePage) {
            dispatch('products/fetchProducts', null, { root: true })
          }
        }

        return account
      })
      .catch(() => null)
      .finally(() =>
        commit('SET_LOADING_PROCESS', { loading: false, name: 'account' })
      )
  },

  /**
   * rememberEmail
   * ? Установить почту и сбросить серверную ошибку
   *
   * @param {AccountContext} param0 context
   * @param {Function} param0.commit mutation
   * @param {string} email почта от аккаунта
   * @returns {void}
   */
  rememberEmail: ({ commit }: AccountContext, email: string): void => {
    commit('SET_USER_EMAIL', email)
    commit('CLEAR_SERVER_ERROR', null, { root: true })
  },

  /**
   * rememberPassword
   * ? Установить пароль и сбросить серверную ошибку
   *
   * @param {AccountContext} param0 context
   * @param {Function} param0.commit mutation
   * @param {string} password пароль от аккаунта
   * @returns {void}
   */
  rememberPassword: ({ commit }: AccountContext, password: string): void => {
    commit('SET_USER_PASSWORD', password)
    commit('CLEAR_SERVER_ERROR', null, { root: true })
  },

  /**
   * updateAccount
   * ? Обновить данные о пользователе
   *
   * @param {AccountContext} param0 context
   * @param {AccountUpdateForm} formData поля формы
   * @returns {Promise<void>}
   */
  updateAccount: (
    { commit, getters }: AccountContext,
    formData: AccountUpdateForm
  ): Promise<void> => {
    const { phone, lastName, firstName } = formData

    const account: Partial<TAccount> = {
      phone,
      last_name: lastName,
      first_name: firstName,
      // Для редактирования компании и данных пользователя используется один и тот же api.
      // По этому необходимо передавать текущие данные компании при редактировании пользователя.
      company_name: getters.userAccount.company_name,
      organization_type: getters.userAccount.organization_type,
      company_type: getters.userAccount.company_type,
      address: getters.userAccount.address,
      country_code: getters.userAccount.country_code,
      inn: getters.userAccount.inn,
      kpp: getters.userAccount.kpp,
      oked: getters.userAccount.oked,
      ogrn: getters.userAccount.ogrn,
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'update-account',
    })

    return api.account
      .updateAccount(getters.userAccountID, account)
      .then((result) => {
        commit('SET_USER_PHONE', phone)
        commit('SET_USER_LAST_NAME', lastName)
        commit('SET_USER_FIRST_NAME', firstName)

        return result
      })
      .catch((error) => error)
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'update-account',
        })
      })
  },

  /**
   * updateAccountCompany
   * ? Обновить данные о компании пользователя
   *
   * @param {AccountContext} param0 context
   * @param {ICompany} company данные компании
   * @returns {Promise<void>}
   */
  updateAccountCompany: (
    { commit, getters, dispatch }: AccountContext,
    company: ICompany
  ): Promise<void> => {
    type CompanyPayload = Omit<
      TBusinessAccount,
      keyof TBaseAccount<typeof ROLES.BUSINESS_USER> | 'company_id'
    >

    const accountPayload: Partial<TAccount> = {
      phone: getters.userAccount.phone,
      last_name: getters.userAccount.last_name,
      first_name: getters.userAccount.first_name,
      country_code: company.country.toUpperCase() as Uppercase<TCountry>,
      client_type: getters.userAccount.client_type,
    }

    const companyPayload: CompanyPayload = {
      company_type: getters.userAccount.company_type,
      inn: company.inn || company.unp,
      kpp: company.kpp,
      oked: company.oked,
      ogrn: company.ogrn,
      address: company.address,
      company_name: company.name,
      organization_type: company.type,
    }

    // Для редактирования компании и данных пользователя используется один и тот же api.
    // По этому необходимо передавать текущие данные пользователя при редактировании компании.
    const account = Object.assign({}, accountPayload, companyPayload)

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'update-account',
    })

    return api.account
      .updateAccount(getters.userAccountID, account)
      .then(() => dispatch('setAccount'))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'update-account',
        })
      })
  },

  /**
   * switchSubscribe
   * ? Подписаться/отписаться на рассылку
   *
   * @param {AccountContext} param0 context
   * @returns {Promise<SubscriptionProfileStatus>} статус подписки/отписки
   */
  switchSubscribe: ({
    commit,
    getters,
  }: AccountContext): Promise<SubscriptionProfileStatus> => {
    const enabled = !getters.userAccount.is_subscribed

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'newsletter-subscription',
    })

    return api.account
      .switchSubscribe(getters.userAccountID, enabled)
      .then(() => {
        commit('SET_SUBSCRIPTION_STATUS', enabled)

        return { enabled }
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'newsletter-subscription',
        })
      })
  },
}
