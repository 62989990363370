import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  UserState,
  RevokeLicenseLocal,
  DeleteUserBackupsLocal,
  IUserOrdersLocalPayload,
  IUserBackupsLocalPayload,
} from '@/store/modules/admin/user/types'
import type { TAccount } from '@/api/types'

type UserTree = ActionTree<UserState, RootState>
type UserContext = ActionContext<UserState, RootState>

import api from '@/api'
import { getMetaGroups } from '@/helpers'
import { EReportType } from '@/config/enums'
import hasPermission from '@/helpers/permissions'
import { TABLE_KEY, TABLE_TYPE } from '@/config/constants'
import { setItemStorage } from '@/helpers/local-storage'
import { ApiDeleteBackupError } from '@/api/errors'

const { TABLE_USER_ORDERS, TABLE_USER_BACKUPS, TABLE_REPORT_USERS } = TABLE_KEY

export const actions: UserTree = {
  /**
   * setUser
   * ? Установить все данные пользователя
   *
   * @param {UserContext} param0 context
   * @param {string} id идентификатор пользователя
   * @returns {Promise<PromiseSettledResult<void>[]>} результат в формате settledResult
   */
  setUser: (
    { dispatch, commit }: UserContext,
    id: string
  ): Promise<PromiseSettledResult<void>[]> => {
    commit('SET_LOADING_PROCESS', {
      name: 'all-data-user',
      loading: true,
    })

    return dispatch('fetchUser', id)
      .then((user) =>
        Promise.allSettled([
          dispatch('fetchUserQuotas', id),
          dispatch('fetchUserLicenses', id),
          dispatch('fetchUserBackups', { user }),
          dispatch('fetchUserActivationType', user),
          dispatch('fetchUserOrders', { account_id: id }),
        ])
      )
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          name: 'all-data-user',
          loading: false,
        })
      )
  },

  /**
   * fetchUser
   * ? Извлечь данные пользователя
   *
   * @param {UserContext} ctx context
   * @param {string} id идентификатор пользователя
   * @returns {Promise<TAccount>} текущие данные пользователя
   */
  fetchUser: ({ commit }: UserContext, id: string): Promise<TAccount> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'user' })

    return api.account
      .getUserAccount(id)
      .then((user) => {
        commit('SET_USER', user)

        return user
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', { loading: false, name: 'user' })
      })
  },

  /**
   * fetchUserLicenses
   * ? Извлечь список лицензий пользователя
   *
   * @param {UserContext} ctx context
   * @param {string} id идентификатор пользователя
   * @returns {Promise<void>}
   */
  fetchUserLicenses: async (
    { commit }: UserContext,
    id: string
  ): Promise<void> => {
    const payload = {
      embed: 'sku',
      account_id: id,
      include_trial: true,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'licenses' })

    return api.lk
      .getAccountProducts(payload)
      .then((licenses) => commit('SET_USER_LICENSES', licenses))
      .finally(() =>
        commit('SET_LOADING_PROCESS', { loading: false, name: 'licenses' })
      )
  },

  /**
   * fetchUserQuotas
   * ? Извлечь список квот пользователя
   *
   * @param {UserContext} ctx context
   * @param {string} id идентификатор пользователя
   * @returns {Promise<void>}
   */
  fetchUserQuotas: ({ commit }: UserContext, id: string): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'quotas' })

    return api.lk
      .getAccountQuotas(id)
      .then((quotas) => commit('SET_USER_QUOTAS', quotas))
      .finally(() =>
        commit('SET_LOADING_PROCESS', { loading: false, name: 'quotas' })
      )
  },

  /**
   * fetchUserOrders
   * ? Извлечь список ордеров пользователя
   *
   * @param {UserContext} ctx context
   * @param {IUserOrdersLocalPayload} payload параметры конфигурирования списка ордеров
   * @returns {Promise<void>}
   */
  fetchUserOrders: (
    { state, commit }: UserContext,
    payload: IUserOrdersLocalPayload
  ): Promise<void> => {
    if (!hasPermission('GET_MARKET_V1_ORDERS')) {
      return Promise.resolve()
    }

    const metaState = state.currentOrdersMeta
    const currentMetaGroups = getMetaGroups(
      TABLE_USER_ORDERS,
      payload?.meta,
      metaState.meta
    )
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = { ...paging, ...sort, ...search, ...filters }
    const userOrdersPayload = {
      ...combinedMetaList,
      account_id: payload.account_id,
    }

    setItemStorage(TABLE_TYPE[TABLE_USER_ORDERS], currentMetaGroups)

    commit('SET_USER_ORDERS_CURRENT_META', { meta: currentMetaGroups })
    commit('SET_LOADING_PROCESS', { loading: true, name: 'orders' })

    return api.lk
      .getOrders(userOrdersPayload)
      .then(({ data, meta }) => {
        commit('SET_USER_ORDERS', data)
        commit('SET_USER_ORDERS_META', meta)
        commit('SET_USER_ORDERS_CURRENT_META', {
          items: meta.limit,
          total: meta.total,
        })
      })
      .finally(() =>
        commit('SET_LOADING_PROCESS', { loading: false, name: 'orders' })
      )
  },

  /**
   * fetchUserBackups
   * ? Извлечь список резервных копий
   *
   * @param {UserContext} ctx context
   * @param {IUserBackupsLocalPayload} payload параметры конфигурирования списка бэкапов
   * @returns {Promise<void>}
   */
  fetchUserBackups: (
    { state, commit }: UserContext,
    { meta, user }: IUserBackupsLocalPayload
  ): Promise<void> => {
    if (!hasPermission('GET_WEBRESTORE_V1_BACKUP_FILE') || !user.is_active) {
      return Promise.resolve()
    }

    const metaState = state.currentBackupsMeta
    const currentMetaGroups = getMetaGroups(
      TABLE_USER_BACKUPS,
      meta,
      metaState.meta
    )
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = { ...paging, ...sort, ...search, ...filters }
    const userBackupsPayload = {
      ...combinedMetaList,
      account_id: user.id,
    }

    setItemStorage(TABLE_TYPE[TABLE_USER_BACKUPS], currentMetaGroups)

    commit('SET_LOADING_PROCESS', { loading: true, name: 'backups' })
    commit('SET_USER_BACKUPS_CURRENT_META', { meta: currentMetaGroups })

    return api.webrestore
      .getUserBackups(userBackupsPayload)
      .then(({ data, meta }) => {
        commit('SET_USER_BACKUPS', data)
        commit('SET_USER_BACKUPS_META', meta)
        commit('SET_USER_BACKUPS_CURRENT_META', {
          items: meta.limit,
          total: meta.total,
        })
      })
      .finally(() =>
        commit('SET_LOADING_PROCESS', { loading: false, name: 'backups' })
      )
  },

  /**
   * fetchUserActivationType
   * ? Извлечь тип активации для аккаунта (подтверждение email, либо первичное задание пароля)
   *
   * @param {UserContext} commit mutation
   * @param {TAccount} user пользовательские данные
   * @returns {Promise<void>}
   */

  fetchUserActivationType: (
    { commit }: UserContext,
    user: TAccount
  ): Promise<void> => {
    const { email, is_active } = user

    if (is_active || !email) {
      commit('SET_ACCOUNT_ACTIVATION_TYPE', null)
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      groupId: email,
      name: 'activation-type',
    })

    return api.idp
      .getAccountActivationType({ email })
      .then((data) => commit('SET_ACCOUNT_ACTIVATION_TYPE', data))
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          groupId: email,
          name: 'activation-type',
        })
      )
  },

  /**
   * revokeLicense
   * ? Отозвать (удалить) лицензию на аккаунте
   *
   * @param {UserContext} ctx context
   * @param {RevokeLicenseLocal} payload параметры запроса удаления лицензии(ий)
   * @returns {Promise<void>}
   */
  revokeLicense: (
    { commit, dispatch }: UserContext,
    payload: RevokeLicenseLocal
  ): Promise<void> => {
    const { account_id, license_ids } = payload
    commit('SET_LOADING_PROCESS', { loading: true, name: 'revoke' })

    return api.lk
      .revokeLicense({ license_ids })
      .then(() => dispatch('setUser', account_id))
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'revoke',
        })
      )
  },

  /**
   * deleteMultipleBackupItems
   * ? Удалить одну или множество резервных копий пользователя
   *
   * @param {UserContext} param0 context
   * @param {DeleteUserBackupsLocal} payload параметры запроса удаления резервных копий
   * @returns {Promise<void>}
   */
  deleteMultipleBackupItems: (
    { commit, dispatch }: UserContext,
    payload: DeleteUserBackupsLocal
  ): Promise<void> => {
    const { params, backupNames } = payload

    commit('SET_LOADING_PROCESS', {
      name: 'delete-multiple-backup-items',
      loading: true,
    })

    return api.webrestore
      .deleteUserBackups(params)
      .then(() => {
        if (backupNames.length > 0) {
          commit('DELETE_USER_BACKUPS', backupNames)

          return Promise.resolve()
        }

        commit('reportSession/DELETE_REPORT_SESSION_ID', EReportType.ACCOUNTS, {
          root: true,
        })

        return dispatch(
          'users/fetchReportUserAccounts',
          { type: TABLE_REPORT_USERS },
          { root: true }
        )
      })
      .catch((error) => {
        if (error instanceof ApiDeleteBackupError && backupNames.length > 0) {
          const backupNamesWithErrors = error.getBackupInUseArchiveNames()
          commit(
            'DELETE_USER_BACKUPS',
            backupNames.filter((item) => !backupNamesWithErrors.includes(item))
          )
        }
        throw error
      })
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          name: 'delete-multiple-backup-items',
          loading: false,
        })
      )
  },
}
