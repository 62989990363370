import type {
  LicenseState,
  LicenseModule,
} from '@/store/modules/admin/license/types'

import { getters } from '@/store/modules/admin/license/getters'
import { actions } from '@/store/modules/admin/license/actions'
import { mutations } from '@/store/modules/admin/license/mutations'

const state: LicenseState = {
  sku: null,
  user: null,
  order: null,
  license: null,
  campaign: null,
  isLoadingSku: false,
  isLoadingOrder: false,
  isLoadingLicense: false,
  isLoadingUserAccount: false,
  isLoadingAllDataLicense: false,
}

export const license: LicenseModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
