import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  IBackupDeletePayload,
  IWrBackupVersionsPayload,
} from '@/api/types'
import type {
  BackupsState,
  BackupsLocalMeta,
} from '@/store/modules/client/backups/types'

type BackupsTree = ActionTree<BackupsState, RootState>
type BackupsContext = ActionContext<BackupsState, RootState>

import api from '@/api'
import { API_ERROR } from '@/config/constants'

export const actions: BackupsTree = {
  /**
   * fetchBackups
   * ? Извлечь список резервных копий
   *
   * @param {BackupsContext} ctx context
   * @param {BackupsLocalMeta} params параметры запроса
   * @returns {Promise<void>}
   */
  fetchBackups: (
    { commit, dispatch }: BackupsContext,
    params: BackupsLocalMeta
  ): Promise<void> => {
    commit('SET_BACKUPS_CURRENT_META', params)
    commit('SET_LOADING_PROCESS', { loading: true, name: 'backups' })

    return api.webrestore
      .getBackups(params)
      .then(async ({ data, meta }) => {
        commit('SET_BACKUPS', data)
        commit('SET_BACKUPS_CURRENT_META', meta)

        await Promise.all(
          data.map(({ name }) =>
            dispatch('fetchBackupVersions', { backup: name })
          )
        )
      })
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'backups',
        })
      )
  },

  /**
   * fetchBackupVersions
   * ? Извлечь список версий резервных копий
   *
   * @param {BackupsContext} ctx context
   * @param {IWrBackupVersionsPayload} payload параметры запроса
   * @returns {Promise<void>}
   */
  fetchBackupVersions: (
    { commit }: BackupsContext,
    payload: IWrBackupVersionsPayload
  ): Promise<void> => {
    const { backup } = payload

    return api.webrestore.getBackupVersions(payload).then(({ data }) =>
      commit('SET_BACKUP_VERSIONS', {
        name: backup,
        versions: data,
      })
    )
  },

  /**
   * deleteBackup
   * ? Удалить резервную копию
   *
   * @param {BackupsContext} param0 context
   * @param {IBackupDeletePayload} payload параметры запроса удаления архива
   * @returns {Promise<void>}
   */
  deleteBackup: (
    { commit, dispatch, getters, rootGetters }: BackupsContext,
    payload: IBackupDeletePayload
  ): Promise<void> => {
    const { page, order_by, direction } = getters.currentBackupsMeta
    const params = {
      page,
      order_by,
      direction,
    }

    commit('SET_LOADING_PROCESS', {
      name: 'backup-delete',
      loading: true,
    })

    const { archives } = payload
    const decodedBackupName = archives ? decodeURIComponent(archives) : ''
    const sessionId =
      decodedBackupName in rootGetters['session/session']
        ? rootGetters['session/session'][decodedBackupName]
        : null
    const deleteBackup = () => {
      return api.webrestore
        .deleteUserBackups(payload)
        .then((data) => {
          commit('session/DELETE_SESSION', decodedBackupName, {
            root: true,
          })

          if (rootGetters['nav/currentRouteName'] === 'backups') {
            return dispatch('fetchBackups', params)
          }

          return data
        })
        .finally(() =>
          commit('SET_LOADING_PROCESS', {
            name: 'backup-delete',
            loading: false,
          })
        )
    }

    if (sessionId) {
      return api.webrestore
        .deleteSession(sessionId)
        .then(() =>
          new Promise((r) => setTimeout(r, 2000)).then(() => deleteBackup())
        )
        .catch((error) => {
          if (error.code === API_ERROR.ENTITY_NOT_FOUND) {
            commit('session/DELETE_SESSION', decodedBackupName, {
              root: true,
            })

            return dispatch('deleteBackup', payload)
          }

          return Promise.reject(error)
        })
    }

    return deleteBackup()
  },
}
