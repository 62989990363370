import type { RootState } from '@/store/types'
import type { ActionTree, ActionContext } from 'vuex'
import type {
  ICampaignsState,
  ICampaignsLocalPayload,
  IAddCampaignLocalParams,
  IExportCampaignsLocalPayload,
} from '@/store/modules/admin/campaigns/types'

import api from '@/api'
import { getMetaGroups } from '@/helpers'
import { EReportType } from '@/config/enums'
import { exportToXLSX } from '@/helpers/xlsx'
import { replaceRouteQuery } from '@/helpers/router'
import { TABLE_KEY, TABLE_TYPE } from '@/config/constants'
import { getItemStorage, setItemStorage } from '@/helpers/local-storage'

type TCampaignsTree = ActionTree<ICampaignsState, RootState>
type TCampaignsContext = ActionContext<ICampaignsState, RootState>

const { TABLE_REPORT_CAMPAIGNS } = TABLE_KEY

export const actions: TCampaignsTree = {
  /**
   * fetchReportCampaigns
   * ? Извлечь список кампаний
   *
   * @param {TCampaignsContext} ctx context
   * @param {ICampaignsLocalPayload} payload параметры конфигурации запроса кампаний
   * @returns {Promise<void>}
   */
  fetchReportCampaigns: async (
    { state, commit, dispatch, rootGetters }: TCampaignsContext,
    payload: ICampaignsLocalPayload
  ): Promise<void> => {
    const sessionId =
      rootGetters['reportSession/campaignsSession'].id ||
      (await dispatch('reportSession/createSession', EReportType.CAMPAIGNS, {
        root: true,
      }))
    const metaState = state.currentReportCampaignsMeta
    const currentMetaGroups = getMetaGroups(
      TABLE_REPORT_CAMPAIGNS,
      payload?.meta,
      metaState.meta
    )
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = { ...paging, ...sort, ...search, ...filters }
    const campaignsPayload = { ...combinedMetaList, session_id: sessionId }

    setItemStorage(TABLE_TYPE[TABLE_REPORT_CAMPAIGNS], currentMetaGroups)

    commit('SET_REPORT_CAMPAIGNS_CURRENT_META', { meta: currentMetaGroups })
    commit('SET_LOADING_PROCESS', { loading: true, name: 'report-campaigns' })

    return api.report
      .getReportCampaigns(campaignsPayload)
      .then(({ data, meta }) => {
        const searchValue = String(search?.query ?? '')
        const campaigns = searchValue.length === 1 ? [] : data

        commit('SET_REPORT_CAMPAIGNS', campaigns)
        commit('SET_REPORT_CAMPAIGNS_CURRENT_META', {
          items: meta.limit,
          total: meta.total,
        })

        replaceRouteQuery(combinedMetaList)
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'report-campaigns',
        })
      })
  },

  /**
   * addCampaign
   * ? Добавить кампанию
   *
   * @param {TCampaignsContext} ctx context
   * @param {IAddCampaignLocalParams} payload параметры добавления кампании
   * @returns {Promise<void>}
   */
  addCampaign: (
    { commit, dispatch }: TCampaignsContext,
    payload: IAddCampaignLocalParams
  ): Promise<void> => {
    const { id, name, product, finishDate, startDate, description } = payload
    const campaignPayload = {
      id,
      name,
      description,
      product_id: product,
      start_date: startDate.toJSON(),
      finish_date: finishDate.toJSON(),
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'add-campaign',
    })

    return (
      api.lk
        .addCampaign(campaignPayload)
        .finally(() => {
          commit('SET_LOADING_PROCESS', {
            loading: false,
            name: 'add-campaign',
          })
        })
        // then находится на своем месте, обновляем список после добавления кампании
        .then(() => dispatch('fetchReportCampaigns'))
    )
  },

  /**
   * exportCampaigns
   * ? Экспортировать кампании в файл
   *
   * @param {IExportCampaignsLocalPayload} payload промежуточные данные
   * @param {TCampaignsContext} param0 context
   * @returns {Promise<void>}
   */
  exportCampaigns: (
    { commit, rootGetters }: TCampaignsContext,
    { type, filename }: IExportCampaignsLocalPayload
  ) => {
    const { search, filters } = getItemStorage(type) ?? {}
    const campaignsPayload = {
      ...search,
      ...filters,
      content_type: 'text/csv',
      session_id: rootGetters['reportSession/campaignsSession'].id,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'export-campaigns' })

    return api.report
      .exportCampaigns(campaignsPayload)
      .then((file) => exportToXLSX(file, filename))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'export-campaigns',
        })
      })
  },
}
