import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'

type RootTree = ActionTree<RootState, RootState>
type RootContext = ActionContext<RootState, RootState>

import debounce from 'lodash/debounce'
import { DELAY } from '@/config/constants'

export const actions: RootTree = {
  /**
   * beforeInitApp
   * ? Возможность выполнять n запросов до инициализации приложения
   *
   * @param {RootContext} param0 context
   * @param {Function} param0.dispatch action
   * @returns {Promise<void>}
   */
  beforeInitApp: async ({ dispatch }: RootContext): Promise<void> => {
    await dispatch('listenEvents')
    await dispatch('account/setAccount')
  },

  /**
   * listenEvents
   * ? Подписаться на события, для установки размера документа
   *
   * @param {RootContext} param0 context
   * @returns {void}
   */
  listenEvents: ({ commit }: RootContext): void => {
    const setDisplaySize = () => commit('SET_DISPLAY_SIZES')
    const setDelayDisplaySize = debounce(setDisplaySize, DELAY.RESIZE)

    window.addEventListener('resize', setDelayDisplaySize)
    window.addEventListener('DOMContentLoaded', setDisplaySize)

    /**
     * IOS не поддерживает свойство мета тега user-scalable
     * Регистрируем событие touchmove и отслеживаем scale, что бы избежать масштабирования для мобильной версии
     */
    if (/iPad|iPhone/.test(navigator.userAgent)) {
      window.document.addEventListener(
        'touchmove',
        (event: TouchEvent) => {
          if ('scale' in event && event.scale !== 1) {
            event.preventDefault()
          }
        },
        { passive: false }
      )
    }
  },
}
