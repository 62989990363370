export const ru = {
  // ? Unit
  'unit.b': 'Б',
  'unit.kb': 'КБ',
  'unit.mb': 'МБ',
  'unit.gb': 'ГБ',
  'unit.tb': 'ТБ',
  'unit.pb': 'ПБ',
  'unit.eb': 'ЭБ',
  'unit.zb': 'ЗБ',
  'unit.yb': 'ЙБ',

  // ? Words
  'word.of': 'из',
  'word.max': 'макс.',
  'word.rub': 'руб',
  'word.search': 'Поиск',
  'word.chronology': 'Хронология',
  'word.added': 'Добавлен',
  'word.upgrade': 'обновится',
  'word.paging': '{page} - {items} из {total}',
  'word.paging-light': '{items} из {total}',

  // ? XLSX
  'xlsx.column.generated-keys': 'Сгенерированные ключи',

  // ? Dictionary
  'dictionary.was': 'Было | Был | Было ',
  'dictionary.product':
    'Ничего не выбрано | {count} продукт | {count} продукта | {count} продуктов',
  'dictionary.backup':
    'Ничего не выбрано | {count} резервная копия | {count} резервные копии | {count} резервных копий',
  'dictionary.select-product':
    'Ничего не выбрано | {count} Выбран | {count} Выбрано',
  'dictionary.select-backup':
    'Ничего не выбрано | {count} Выбрана | {count} Выбрано',
  'dictionary.license-keys':
    'Ничего не выбрано | {count} лицензионный ключ | {count} лицензионных ключа | {count} лицензионных ключей',
  'dictionary.key': 'Ничего не выбрано | ключ | ключа | ключей',
  'dictionary.key-count':
    'Ничего не выбрано | {count} ключ | {count} ключа | {count} ключей',
  'dictionary.generated-key':
    'Ничего не выбрано | был сгенерирован {count} ключ | было сгенерировано {count} ключа | было сгенерировано {count} ключей',
  'dictionary.selected-accounts':
    'Ничего не выбрано | Выбран {count} пользователь | Выбрано {count} пользователя | Выбрано {count} пользователей',
  'dictionary.selected-products':
    'Ничего не выбрано | Выбран {count} продукт | Выбрано {count} продукта | Выбрано {count} продуктов',
  'dictionary.selected-backups':
    'Ничего не выбрано | Выбрана {count} резервная копия | Выбрано {count} резервные копии | Выбрано {count} резервных копий',
  'dictionary.label.delete-backup':
    'Ничего не выбрано | Удалить резервную копию | Удалить резервные копии',
  'dictionary.alert-backup-deletion':
    'Ничего не выбрано | Подтвердите удаление резервной копии выше | Подтвердите удаление резервных копий выше',
  'dictionary.title.backup-delete':
    'Ничего не выбрано | Удалить все резервные копии пользователя? | Удалить все резервные копии пользователей?',
  'dictionary.description.user-data-delete':
    'Ничего не выбрано | Данные пользователя {user} будут удалены безвозвратно | Данные пользователей {user} будут удалены безвозвратно',
  'dictionary.backup-deleted':
    'Ничего не выбрано | Резервная копия был удалена | Резервные копии был удалены',
  'dictionary.failed-backup-delete':
    'Ничего не выбрано | Не удалось удалить резервную копию | Не удалось удалить резервные копии',
  'notify.delete-backup.backup-in-use':
    'Файл резервной копии используется. Повторите попытку позже. | Файл резервной копии {names} используется. Повторите попытку позже. | Файлы резервных копий {names} используются. Повторите попытку позже.',
  'dictionary.title.account-delete':
    'Ничего не выбрано | Удалить аккаунт пользователя? | Удалить аккаунты пользователей?',
  'dictionary.label.delete-account':
    'Ничего не выбрано | Удалить пользователя | Удалить пользователей',
  'dictionary.alert-account-deletion':
    'Ничего не выбрано | Подтвердите удаление пользователя выше | Подтвердите удаление пользователей выше',
  'dictionary.account-deleted':
    'Ничего не выбрано | Пользователь был удален | Пользователи были удалены',
  'dictionary.failed-account-delete':
    'Ничего не выбрано | Не удалось удалить пользователя | Не удалось удалить пользователей',

  // ? Account
  'account.status.no-activated': 'Не активирован',
  'account.status.no-confirmed': 'Не подтвержден',

  // ? Licenses
  'license.name.trial': 'Пробная',
  'license.name.permanent': 'Постоянная',
  'license.name.subscribe': 'Подписка',
  'license.name.unspecified': 'Не определена',

  // ? Fields placeholder
  'field.placeholder.select': 'Выбрать',
  'field.placeholder.select-data': 'Выберите дату',

  // ? Periods
  'period.year': 'Год',
  'period.month': 'Месяц',
  'period.day': 'День',

  // ? Data
  'data.type.gift': 'Подарок',
  'data.status.draft': 'Черновик',
  'data.status.archived': 'В архиве',
  'data.status.published': 'Опубликован',

  // ? Fields label
  'field.label.to': 'до',
  'field.label.id': 'ID',
  'field.label.sku': 'SKU',
  'field.label.key': 'Ключ',
  'field.label.name': 'Имя',
  'field.label.type': 'Тип',
  'field.label.keys': 'Ключи',
  'field.label.price': 'Цена',
  'field.label.date-by': 'по',
  'field.label.email': 'Email',
  'field.label.total': 'Итого',
  'field.label.order': 'Ордер',
  'field.label.amount': 'Сумма',
  'field.label.cloud': 'Облако',
  'field.label.volume': 'Объем',
  'field.label.coupon': 'Купон',
  'field.label.gift': 'Подарок',
  'field.label.trial': 'Пробная',
  'field.label.sku-id': 'ID SKU',
  'field.label.online': 'Онлайн',
  'field.label.phone': 'Телефон',
  'field.label.status': 'Статус',
  'field.label.done': 'Выполнен',
  'field.label.orders': 'Ордеры',
  'field.label.created': 'Создан',
  'field.label.buying': 'Покупка',
  'field.label.added': 'Добавлен',
  'field.label.update': 'Апгрейд',
  'field.label.discount': 'Скидка',
  'field.label.product': 'Продукт',
  'field.label.partner': 'Партнер',
  'field.label.active': 'Активные',
  'field.label.modified': 'Изменен',
  'field.label.position': 'Позиций',
  'field.label.mailing': 'Рассылка',
  'field.label.expires': 'Истекает',
  'field.label.total-of': 'Всего от',
  'field.label.campaign': 'Кампания',
  'field.label.renewal': 'Продление',
  'field.label.batch-id': 'Batch ID',
  'field.label.assigned': 'Назначен',
  'field.label.subscribe': 'Подписка',
  'field.label.started': 'Начинается',
  'field.label.promo-code': 'Промокод',
  'field.label.activity': 'Активность',
  'field.label.established': 'Создана',
  'field.label.cashless': 'Безналичный',
  'field.label.computers': 'Компьютеры',
  'field.label.description': 'Описание',
  'field.label.cloud-from': 'Облако от',
  'field.label.amount-from': 'Сумма от',
  'field.label.expired-item': 'Истекла',
  'field.label.store-id': 'ID магазина',
  'field.label.confirmed': 'Подтвержден',
  'field.label.expired-list': 'Истекшие',
  'field.label.order-type': 'Тип ордера',
  'field.label.pc-from': 'Компьютеров от',
  'field.label.start-date': 'Дата начала',
  'field.label.generated': 'Сгенерирован',
  'field.label.enter-key': 'Введите ключ',
  'field.label.campaign-id': 'ID кампании',
  'field.label.payment-type': 'Тип оплаты',
  'field.label.serial-key': 'Серийный ключ',
  'field.label.purchase-type': 'Тип покупки',
  'field.label.features': 'Возможности',
  'field.label.feature': 'Возможность',
  'field.label.license-type': 'Тип лицензии',
  'field.label.product-name': 'Наименование',
  'field.label.enter-email': 'Введите email',
  'field.label.used-from': 'Использовано от',
  'field.label.created-date': 'Дата создания',
  'field.label.user-name': 'Имя пользователя',
  'field.label.campaign-type': 'Тип кампании',
  'field.label.created-date-from': 'Создан с',
  'field.label.added-date-from': 'Добавлен с',
  'field.label.auto-renewal': 'Автообновление',
  'field.label.finish-date': 'Дата завершения',
  'field.label.generated-keys': 'Сгенерированы',
  'field.label.expired-date-from': 'Истекает с',
  'field.label.license-id': 'Лицензионный ключ',
  'field.label.sku-upgrade': 'SKU для апгрейда',
  'field.label.amount-keys': 'Количество ключей',
  'field.label.duration': 'Продолжительность',
  'field.label.quota.computer': 'Квота компьютеров',
  'field.label.quota.cloud': 'Квота облачного хранилища',
  'field.label.vat': 'Добавить НДС',
  'field.label.subscription-type': 'Тип подписки',
  'field.label.completed-date-from': 'Выполнен с',
  'field.label.subscribed': 'Подписан на рассылку',
  'field.label.enter-campaign': 'Введите кампанию',
  'field.label.generated-date-from': 'Сгенерирован с',
  'field.label.actual-date-from': 'Кампания активна с',
  'field.label.subscription-period': 'Период подписки',
  'field.label.last-backup': 'Последняя резервная копия',
  'field.label.subscription-action': 'Действие подписки',
  'field.label.single-promo-code': 'Одноразовый промокод',
  'field.label.campaign-start-date': 'Дата начала кампании',
  'field.label.campaign-published': 'Кампания опубликована',
  'field.label.campaign-finish-date': 'Дата завершения кампании',
  'field.label.enter-order-store': 'Введите ордер или ID магазина',
  'field.label.renewable-license-key': 'Продлеваемый лицензионный ключ',

  // ? Form field validate
  'form.field.validate.wrong-value': 'Не верное значение',
  'form.field.validate.min-value': 'Минимальное значение {min}',
  'form.field.validate.max-value': 'Максимальное значение {max}',
  'form.field.validate.unique-name': 'Данная кампания уже существует',
  'form.field.validate.field-invalid-name':
    'Поле может содержать только латинские и кириллические буквы, цифры, тире и нижнее подчеркивание.',
  'form.field.validate.sku.unique-id': 'Данный SKU уже существует',
  'form.field.validate.sku.invalid-id': 'Некорректный идентификатор SKU',

  /**
   * ? Errors
   */
  'error.label.transfer-license.account-not-selected':
    'Не выбран аккаунт для переноса',
  'error.label.transfer-license.transfer-not-confirmed':
    'Необходимо подтвердить перенос лицензионного ключа в поле выше',
  'error.label.assign-license.account-not-selected':
    'Не выбран аккаунт для назначения',

  /**
   * ? COMPONENT: Tabs
   */
  'component.tab.keys.label.assigned-licenses': 'Привязанные',
  'component.tab.keys.label.available-licenses': 'Свободные',
  'component.tab.user.label.active-licenses': 'Активные лицензии ({count})',
  'component.tab.user.label.expired-licenses': 'Истекшие лицензии ({count})',
  'component.tab.user.label.orders': 'Ордеры ({count})',
  'component.tab.user.label.backups': 'Резервные копии ({count})',

  /**
   * ? COMPONENT: Tags
   */
  'component.tag.title.success': 'Активен',
  'component.tag.title.danger': 'Неактивен',
  'component.tag.title.info': 'Информация',

  /**
   * ? COMPONENT: Buttons
   */
  'component.button.name.show': 'Показать',
  'component.button.name.close': 'Закрыть',
  'component.button.name.create': 'Создать',
  'component.button.name.delete': 'Удалить',
  'component.button.name.save': 'Сохранить',
  'component.button.name.cancel': 'Отменить',
  'component.button.name.apply': 'Применить',
  'component.button.name.revoke': 'Отозвать',
  'component.button.name.copy': 'Скопировать',
  'component.button.name.assign': 'Назначить',
  'component.button.name.collapse': 'Свернуть',
  'component.button.name.edit': 'Редактировать',
  'component.button.name.transfer': 'Перенести',
  'component.button.name.filters': 'Все фильтры',
  'component.button.name.publish': 'Опубликовать',
  'component.button.name.confirm': 'Подтвердить',
  'component.button.name.archive': 'Архивировать',
  'component.button.name.add-sku': 'Добавить SKU',
  'component.button.name.export': 'Экспортировать',
  'component.button.name.generate': 'Сгенерировать',
  'component.button.name.reset-all': 'Сбросить все',
  'component.button.name.unselect': 'Отменить выбор',
  'component.button.name.update-data': 'Обновить данные',
  'component.button.name.export-all': 'Экспортировать все',
  'component.button.name.add-campaign': 'Добавить кампанию',
  'component.button.name.delete-account': 'Удалить аккаунт',
  'component.button.name.delete-accounts': 'Удалить аккаунты',
  'component.button.name.delete-user': 'Удалить пользователя',
  'component.button.name.generate-keys': 'Сгенерировать ключи',
  'component.button.name.delete-backups': 'Удалить резервные копии',
  'component.button.name.transfer-key-to-another-account':
    'Перенести ключ в другой аккаунт',
  'component.button.name.assign-key-to-user': 'Назначить ключ пользователю',

  /**
   * ? COMPONENT: Radio buttons
   */
  'component.radio-button.name.all': 'Все',
  'component.radio-button.name.he-any': 'Любой',
  'component.radio-button.name.she-any': 'Любая',
  'component.radio-button.name.he-active': 'Активен',
  'component.radio-button.name.she-active': 'Активная',
  'component.radio-button.name.inactive': 'Неактивен',
  'component.radio-button.name.expired': 'Истекшая',
  'component.radio-button.name.trial': 'Пробная',
  'component.radio-button.name.subscription': 'Подписка',
  'component.radio-button.name.new': 'Покупка',
  'component.radio-button.name.upgrade': 'Апгрейд',
  'component.radio-button.name.renew': 'Продление',

  /**
   * ? COMPONENT: Header
   */
  'component.header.title.undefined': 'Раздел не найден',
  'component.header.title.users': 'Пользователи',
  'component.header.title.licenses': 'Лицензионные ключи',
  'component.header.title.orders': 'Ордеры',
  'component.header.title.skus': 'SKU',
  'component.header.title.campaigns': 'Кампании',
  'component.header.title.assigned-licenses': 'Назначенные ключи',
  'component.header.title.available-licenses': 'Свободные ключи',
  'component.header.profile-menu.title.admin-panel': 'Панель администратора ЛК',

  /**
   * ? COMPONENT: Pagination
   */
  'component.pagination.show-lines': 'Показывать строк:',

  /**
   * ? COMPONENT: Table
   */
  'component.table.label.no-name': 'Имя не указано',
  'component.table.label.unspecified': 'Не указано',
  'component.table.label.not-found': 'Не найден',
  'component.table.label.no-data': 'Нет данных',
  'component.table.label.unknown': 'Неизвестный',
  'component.table.label.infinity-date': 'Бесконечная',
  'component.table.content.no-data': 'Нет данных для отображения',
  'component.table.content.loading-data': 'Список пользователей загружается',
  'component.table.content.min-search':
    'Для корректного поиска введите не менее 2-х символов',
  'component.table.dropdown.text.complete-account':
    'Сгенерировать ссылку на активацию аккаунта',
  'component.table.dropdown.text.confirmation-email':
    'Сгенерировать ссылку на верификацию Email',
  'component.table.text.transfer-license':
    'Перенести {keys} в аккаунт “{user}”',
  'component.table.text.total-amount': 'Итоговая сумма: {amount} руб',

  /**
   * ? COMPONENT: Modal
   */
  'component.modal-generate-keys.title': 'Генерация лицензионных ключей',
  'component.modal-activation-type.field.user-name': 'Имя пользователя',
  'component.modal-activation-type.title.account-activation-link':
    'Ссылка на активацию аккаунта',
  'component.modal-activation-type.title.email-verification-link':
    'Ссылка на верификацию почтового адреса',
  'component.modal-delete-user.title':
    'Вы действительно хотите удалить пользователя?',
  'component.modal-delete-user.description':
    'Данные пользователя {user} будут удалены безвозвратно',
  'component.modal-delete-users.description':
    'Данные пользователей {user} будут удалены безвозвратно',
  'component.modal-transfer-license.title':
    'Перенос лицензионного ключа в другой аккаунт',
  'component.modal-transfer-license.description':
    'Выберите аккаунт, в который вы хотите перенести ключ',
  'component.modal-assign-license.title': 'Назначение лицензионного ключа',
  'component.modal-assign-license.description':
    'Выберите аккаунт, которому вы хотите назначить ключ',
  'component.modal-revoke-license.title':
    'Вы действительно хотите отозвать лицензии?',
  'component.modal-revoke-license.description':
    'Лицензии будут отозваны безвозвратно',
  'component.modal-delete-user-backup.title':
    'Вы действительно хотите удалить резервную копию?',
  'component.modal-delete-user-backups.title':
    'Вы действительно хотите удалить резервные копии?',
  'component.modal-delete-user-backups.description':
    'Данные будут удалены безвозвратно',
  'component.modal-campaign-addition.title': 'Добавление кампании',
  'component.modal-campaign-editing.title': 'Редактирование кампании',
  'component.modal-campaign-deletion.title':
    'Вы действительно хотите удалить кампанию?',
  'component.modal-campaign-deletion.description':
    'Данные кампании «{name}» будут удалены безвозвратно',
  'component.modal-sku-adding.title': 'Создание SKU',
  'component.modal-sku-editing.title': 'Редактирование SKU',
  'component.modal-sku-deletion.title': 'Удалить SKU?',
  'component.modal-sku-archiving.title': 'Архивировать SKU?',
  'component.modal-sku-publish.title': 'Опубликовать SKU?',
  'component.modal-sku-price-editing.title': 'Изменение цены',
  'component.modal-sku-features.title': 'Просмотр возможностей SKU',
  'component.modal-sku-editing-features.title':
    'Редактирование возможностей SKU',
  'component.modal-sku-editing-upgrades.title': 'Выбор SKU для апгрейда',
  'component.modal-sku-editing-upgrades.description':
    'Выберите из списка SKU, на которые можно будет перейти из текущего SKU. Для того, чтобы SKU был подходящим для перехода, его квоты компютеров и облачного хранилища должны быть не меньше, чем у текущего SKU.',
  'component.modal-sku-deletion.description':
    'SKU {id} ({description}) будет удален безвозвратно',
  'component.modal-sku-deletion-upgrades.description':
    'SKU {id} ({description}) будет удален из списка SKU для апгрейда безвозвратно',
  'component.modal-sku-archiving.description':
    'SKU {id} ({description}) будет архивирован',
  'component.modal-sku-publish.description':
    'SKU {id} ({description}) будет опубликован',
  'component.modal-sku-publish-softline.description':
    'Опубликовать в магазине Софтлайн',

  /**
   * ? COMPONENT: Alert
   */
  'component.alert.warning.message.some-data-not-uploaded':
    'Часть данных не была загружена',
  'component.alert.warning.message.data-loss-on-transfer':
    'При переносе ключа соответствующие резервные копии будут утеряны',
  'component.alert.message.actual-data': 'Данные актуальны на {date}.',
  'component.alert.message.update-process': 'Идет процесс обновления данных.',
  'component.alert.warning.message.failed-update':
    'Не удалось обновить данные, попробуйте снова.',

  /**
   * ? COMPONENT: Notify
   */
  'component.notify.message-success.copy-campaign-id':
    'Идентификатор кампании скопирован',
  'component.notify.message-error.copy-campaign-id':
    'Не удалось скопировать идентификатор кампании',
  'component.notify.message-success.copy-order-id':
    'Идентификатор ордера скопирован',
  'component.notify.message-error.copy-order-id':
    'Не удалось скопировать идентификатор ордера',
  'component.notify.message-success.copy-license-id':
    'Идентификатор лицензионного ключа скопирован',
  'component.notify.message-error.copy-license-id':
    'Не удалось скопировать идентификатор лицензионного ключа',
  'component.notify.message-success.copy-sku-id':
    'Идентификатор SKU скопирован',
  'component.notify.message-error.copy-sku-id':
    'Не удалось скопировать идентификатор SKU',
  'component.notify.message-success.copy-link': 'Ссылка успешно скопирована',
  'component.notify.message-error.copy-link': 'Не удалось скопировать ссылку',
  'component.notify.message-success.user-deleted': 'Пользователь был удален',
  'component.notify.message-error.user-deleted': 'Не удалось удалить аккаунт',
  'component.notify.message-success.transferred-license':
    'Лицензия {id} перенесена',
  'component.notify.message-success.transferred-license-list':
    'Лицензии {id} перенесены',
  'component.notify.message-success.assigned-license':
    'Лицензия {id} назначена',
  'component.notify.message-error.license-not-found':
    'Лицензия {id} не найдена',
  'component.notify.message-error.account-not-found':
    'Аккаунт {attr} не найден',
  'component.notify.message-error.invalid-license-id':
    'Неверный идентификатор {id} лицензии',
  'component.notify.message-error.invalid-account-id':
    'Неверный идентификатор {id} аккаунта',
  'component.notify.message-error.source-account-not-found':
    'Аккаунт источника {email} не найден',
  'component.notify.message-error.source-account-invalid':
    'Недопустимый идентификатор у пользователя источника {email}',
  'component.notify.message-error.destination-account-invalid':
    'Недопустимый идентификатор для назначенного пользователя {email}',
  'component.notify.message-error.softline-key-cannot-be-assigned':
    'Данный ключ не может быть назначен пользователю, это ключ магазина Softline. Если возникла проблема, обратитесь пожалуйста к администратору.',
  'component.notify.message-success.revoked-license':
    'Лицензия {id} успешно отозвана',
  'component.notify.message-success.revoked-license-list':
    'Лицензии {id} успешно отозваны',
  'component.notify.message-error.revoked-license-list.parameters-missing':
    'Не выбрана ни одна лицензия',
  'component.notify.message-success.delete-user-backup':
    'Резервная копия успешно удалена',
  'component.notify.message-success.delete-user-backups':
    'Резервные копии успешно удалены',
  'component.notify.message-success.generate-license':
    'Лицензионный ключ сгенерирован',
  'component.notify.message-error.email-already-verified':
    'Аккаунт {email} уже активирован, обновите страницу.',
  'component.notify.message-success.campaign-added':
    'Кампания «{name}» добавлена',
  'component.notify.message-success.campaign-changed':
    'Кампания «{name}» изменена',
  'component.notify.message-error.invalid-campaign-id':
    'Неверный идентификатор {id} кампании',
  'component.notify.message-error.campaign-not-found': 'Кампания не найдена',
  'component.notify.message-error.campaign-already-exists':
    'Кампания уже существует',
  'component.notify.message-error.operation-not-available':
    'Операция недоступна',
  'component.notify.message-success.company-published':
    'Кампания {name} опубликована',
  'component.notify.message-success.company-archived':
    'Кампания {name} добавлена в архив',
  'component.notify.message-success.company-deleted': 'Кампания {name} удалена',
  'component.notify.message-success.generated-keys':
    '{date} {keys} для SKU {sku}',
  'component.notify.message-success.sku-deleted': 'Черновик SKU {id} удален',
  'component.notify.message-success.sku-upgrade-deleted':
    'SKU для апгрейда удален из списка',
  'component.notify.message-success.sku-archived': 'SKU {id} архивирован',
  'component.notify.message-error.sku-not-found': 'SKU не найден',
  'component.notify.message-error.sku-upgrade-id-invalid':
    'К сожалению, нельзя добавить SKU {names} для апгрейда. Квоты компьютеров или облачного хранилища должны быть больше, чем у текущей конфигурации SKU.',
  'component.notify.message-success.sku-published': 'SKU {id} опубликован',

  /**
   * ? COMPONENT: Tooltip
   */
  'component.tooltip.users.account-cannot-be-delete':
    'Удаление невозможно до тех пор, пока есть хотя бы одна активная лицензия',
  'component.tooltip.users.backups-cannot-be-delete':
    'Удаление резервных копий доступно только если у всех выбранных аккаунтов нет лицензий или истекла пробная лицензия',
  'component.tooltip.users.accounts-cannot-be-delete':
    'Удаление выбранных аккаунтов доступно только если в них нет лицензий или истекла пробная лицензия',

  /**
   * ? COMPONENT: Filters
   */
  'component.filters.title': 'Фильтры',

  /**
   * ? PAGE: User
   */
  'page.user.sub-title.cloud-usage': 'Использование облака',
  'page.user.subscribed': 'Подписан',
  'page.user.unsubscribed': 'Не подписан',
  'page.user.phone.not-specified': 'Не указан',
  'page.user.text.non-exist':
    'Пользователя с идентификатором {id} не существует',

  /**
   * ? PAGE: License
   */
  'page.license.sub-title.refs': 'Ссылки',
  'page.license.sub-title.orders': 'Ордеры',
  'page.license.sub-title.user': 'Пользователь',
  'page.license.sub-title.chronology': 'Хронология',
  'page.license.sub-title.key-not-used': 'Ключ еще не использован',
  'page.license.text.non-exist':
    'Лицензия с идентификатором {id} не существует',

  /**
   * ? PAGE: Order
   */
  'page.order.sub-title.refs': 'Ссылки',
  'page.order.sub-title.position': 'Позиции',
  'page.order.sub-title.user': 'Пользователь',
  'page.order.sub-title.chronology': 'Хронология',
  'page.order.text.non-exist': 'Ордер с идентификатором {id} не существует',

  /**
   * ? PAGE: Campaign
   */
  'page.campaign.sub-title.used': 'Использовано',
  'page.campaign.sub-title.assigned': 'Привязанные',
  'page.campaign.sub-title.available': 'Свободные',
  'page.campaign.sub-title.chronology': 'Хронология',
  'page.campaign.text.non-exist':
    'Кампании с идентификатором {id} не существует',

  /**
   * ? PAGE: Sku
   */
  'page.sku.text.non-exist': 'SKU с идентификатором {id} не существует',
  'page.sku.sub-title.chronology': 'Хронология',
  'page.sku.action.edit': 'Изменить',
  'page.sku.sub-title.upgrades': 'SKU для апгрейда',
  'page.sku.text.empty-features': 'Не выбраны',
  'page.sku.text.empty-upgrades': 'Ещё не выбраны',
  'page.sku.text.archived': 'Архивирован',
  'page.sku.text.left-features': '+ ещё {features}',
  'page.sku.feature-text.browse-all': 'Просмотреть все',
  'page.sku.feature-text.browse-and-select': 'Просмотреть и выбрать',
  'page.sku.feature-text.select': 'Выбрать',
  'page.sku.feature-text.browse-and-select-from-all':
    'Просмотреть и выбрать из всех',
}
