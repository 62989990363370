import type { MutationTree } from 'vuex'
import type { LicensesState } from '@/store/modules/admin/licenses/types'
import type {
  ILicensesLocalMeta,
  TGeneratedLocalKeys,
  ILicenseCampaignsLocalMeta,
} from '@/store/modules/admin/licenses/types'
import type {
  SkuResponse,
  IReportCampaignItem,
  AssignedLicenseResponse,
  AvailableLicenseResponse,
} from '@/api/types'
type LicensesTree = MutationTree<LicensesState>

import { isEmptyObject } from '@/helpers/checks'
import { setLoading } from '@/helpers/mutation-functions'

export const mutations: LicensesTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_ACTIVE_TAB_LICENSES
   * ? Установить активный таб для лицензионных ключей
   *
   * @param {LicensesState} state data
   * @param {string} tab активный таб лицензионных ключей
   * @returns {void}
   */
  SET_ACTIVE_TAB_LICENSES: (state: LicensesState, tab: string): void => {
    state.activeTab = tab
  },

  /**
   * ADD_CAMPAIGNS
   * ? Добавить список кампаний
   *
   * @param {LicensesState} state data
   * @param {IReportCampaignItem[]} campaigns список кампаний
   * @returns {void}
   */
  ADD_CAMPAIGNS: (
    state: LicensesState,
    campaigns: IReportCampaignItem[]
  ): void => {
    state.campaigns.push(...campaigns)
  },

  /**
   * SET_CAMPAIGNS
   * ? Установить список кампаний
   *
   * @param {LicensesState} state data
   * @param {IReportCampaignItem[]} campaigns список кампаний
   * @returns {void}
   */
  SET_CAMPAIGNS: (
    state: LicensesState,
    campaigns: IReportCampaignItem[]
  ): void => {
    state.campaigns = campaigns
  },

  /**
   * RESET_CAMPAIGNS
   * ? Сбросить список кампаний
   *
   * @param {LicensesState} state data
   * @returns {void}
   */
  RESET_CAMPAIGNS: (state: LicensesState): void => {
    state.campaigns = []
  },

  /**
   * SET_ASSIGNED_LICENSES
   * ? Установить назначенные лицензии
   *
   * @param {LicensesState} state data
   * @param {AssignedLicenseResponse[]} licenses назначенные лицензии
   * @returns {void}
   */
  SET_ASSIGNED_LICENSES: (
    state: LicensesState,
    licenses: AssignedLicenseResponse[]
  ): void => {
    state.reportAssignedLicenses = licenses
  },

  /**
   * CLEAR_ASSIGNED_LICENSES
   * ? Очистить назначенные лицензии
   *
   * @param {LicensesState} state data
   * @returns {void}
   */
  CLEAR_ASSIGNED_LICENSES: (state: LicensesState): void => {
    state.reportAssignedLicenses = []
  },

  /**
   * SET_ASSIGNED_LICENSES_CURRENT_META
   * ? Установить текущие мета данные назначенных лицензий
   *
   * @param {LicensesState} state data
   * @param {ILicensesLocalMeta} meta текущие мета данные назначенных лицензий
   * @returns {void}
   */
  SET_ASSIGNED_LICENSES_CURRENT_META: (
    state: LicensesState,
    meta: ILicensesLocalMeta
  ): void => {
    if (isEmptyObject(meta)) {
      state.currentAssignedLicensesMeta = structuredClone(
        state.defaultAssignedLicensesMeta
      )
      return
    }

    state.currentAssignedLicensesMeta = {
      ...state.currentAssignedLicensesMeta,
      ...meta,
    }
  },

  /**
   * SET_LICENSE_CAMPAIGNS_CURRENT_META
   * ? Установить текущие мета данные списка кампаний
   *
   * @param {LicensesState} state data
   * @param {ILicenseCampaignsLocalMeta} meta текущие мета данные списка кампаний
   * @returns {void}
   */
  SET_LICENSE_CAMPAIGNS_CURRENT_META: (
    state: LicensesState,
    meta: ILicenseCampaignsLocalMeta
  ): void => {
    if (isEmptyObject(meta)) {
      state.currentLicenseCampaignsMeta = structuredClone(
        state.defaultLicenseCampaignsMeta
      )
      return
    }

    state.currentLicenseCampaignsMeta = {
      ...state.currentLicenseCampaignsMeta,
      ...meta,
    }
  },

  /**
   * SET_AVAILABLE_LICENSES
   * ? Установить свободные лицензии
   *
   * @param {LicensesState} state data
   * @param {Array<AvailableLicenseResponse>} licenses свободные лицензии
   * @returns {void}
   */
  SET_AVAILABLE_LICENSES: (
    state: LicensesState,
    licenses: Array<AvailableLicenseResponse>
  ): void => {
    state.reportAvailableLicenses = licenses
  },

  /**
   * CLEAR_AVAILABLE_LICENSES
   * ? Очистить свободные лицензии
   *
   * @param {LicensesState} state data
   * @returns {void}
   */
  CLEAR_AVAILABLE_LICENSES: (state: LicensesState): void => {
    state.reportAvailableLicenses = []
  },

  /**
   * SET_AVAILABLE_LICENSES_CURRENT_META
   * ? Установить текущие мета данные свободных лицензий
   *
   * @param {LicensesState} state data
   * @param {ILicensesLocalMeta} meta текущие мета данные свободных лицензий
   * @returns {void}
   */
  SET_AVAILABLE_LICENSES_CURRENT_META: (
    state: LicensesState,
    meta: ILicensesLocalMeta
  ): void => {
    if (isEmptyObject(meta)) {
      state.currentAvailableLicensesMeta = structuredClone(
        state.defaultAvailableLicensesMeta
      )
      return
    }

    state.currentAvailableLicensesMeta = {
      ...state.currentAvailableLicensesMeta,
      ...meta,
    }
  },

  /**
   * SET_SKUS
   * ? Установить список всех sku
   *
   * @param {LicensesState} state data
   * @param {Array<SkuResponse>} skus список всех sku
   * @returns {void}
   */
  SET_SKUS: (state: LicensesState, skus: Array<SkuResponse>): void => {
    state.skus = skus
  },

  /**
   * SET_GENERATED_KEYS
   * ? Установить сгенерированные ключи
   *
   * @param {LicensesState} state data
   * @param {TGeneratedLocalKeys} generatedKeys сгенерированные ключи
   * @returns {void}
   */
  SET_GENERATED_KEYS: (
    state: LicensesState,
    generatedKeys: TGeneratedLocalKeys
  ): void => {
    state.generatedKeys = generatedKeys
  },

  /**
   * DELETE_GENERATED_KEYS
   * ? Удалить сгенерированные ключи
   *
   * @param {LicensesState} state data
   * @returns {void}
   */
  DELETE_GENERATED_KEYS: (state: LicensesState): void => {
    state.generatedKeys = {}
  },
}
