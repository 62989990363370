import { read, utils, writeFile } from 'xlsx'

export const exportToXLSX = (data: string, name: string) => {
  const formatData = read(data, { type: 'string' })
  writeFile(formatData, `${name}.xlsx`)
}

export const exportJSONToXLSX = (data: unknown[], name: string) => {
  const workbook = utils.book_new()
  const worksheet = utils.json_to_sheet(data)

  utils.book_append_sheet(workbook, worksheet, name)
  writeFile(workbook, `${name}.xlsx`)
}
