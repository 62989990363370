import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { ReportOrderResponse, IReportCampaignItem } from '@/api/types'
import type {
  OrdersState,
  IOrdersLocalMeta,
  IOrderCampaignsLocalMeta,
} from '@/store/modules/admin/orders/types'

type OrdersTree = GetterTree<OrdersState, RootState>

import {
  getFormattedDate,
  getFormattedPrice,
  getFormattedOrderType,
  getFormattedUserEmail,
  getFormattedSimpleValue,
  getFormattedDiscountDifference,
} from '@/helpers/formatted-functions'

export const getters: OrdersTree = {
  /**
   * reportOrders
   * ? Список ордеров
   *
   * @param {OrdersState} state data
   * @returns {Array<ReportOrderResponse>} список ордеров
   */
  reportOrders: (state): Array<ReportOrderResponse> => {
    return state.reportOrders.map((order) => {
      const {
        email,
        amount,
        quantity,
        order_type,
        partner_id,
        campaign_id,
        payment_type,
        purchase_type,
        creation_date,
        completion_date,
        external_order_name,
        amount_without_discount,
      } = order

      return {
        ...order,
        formatEmail: getFormattedUserEmail(email),
        formatTotalPrice: getFormattedPrice(amount, 2),
        formatQuantity: getFormattedSimpleValue(quantity),
        formatOrderType: getFormattedOrderType(order_type),
        formatCreationDate: getFormattedDate(creation_date),
        formatPaymentType: getFormattedSimpleValue(payment_type),
        formatCompletionDate: getFormattedDate(completion_date),
        formatPrice: getFormattedPrice(amount_without_discount, 2),
        formatPurchaseType: getFormattedSimpleValue(purchase_type),
        formatPartnerId: getFormattedSimpleValue(partner_id, true),
        formatCampaignId: getFormattedSimpleValue(campaign_id, true),
        formatExternalOrderName: getFormattedSimpleValue(
          external_order_name,
          true
        ),
        formatDiscountDiff: getFormattedDiscountDifference(
          amount_without_discount,
          amount
        ),
      }
    })
  },

  /**
   * campaigns
   * ? Список кампаний
   *
   * @param {OrdersState} state data
   * @returns {IReportCampaignItem[]} список кампаний
   */
  campaigns: ({ campaigns }: OrdersState): IReportCampaignItem[] => {
    return campaigns.map((campaign) => {
      const { name } = campaign

      return {
        ...campaign,
        formatCampaignName: getFormattedSimpleValue(name),
      }
    })
  },

  /**
   * currentOrderCampaignsMeta
   * ? Текущие мета данные кампаний
   *
   * @param {OrdersState} state data
   * @returns {IOrderCampaignsLocalMeta} текущие мета данные кампаний
   */
  currentOrderCampaignsMeta: (state): IOrderCampaignsLocalMeta => {
    return state.currentOrderCampaignsMeta
  },

  /**
   * currentReportOrdersMeta
   * ? Текущие мета данные ордеров
   *
   * @param {OrdersState} state data
   * @returns {IOrdersLocalMeta} текущие мета данные ордеров
   */
  currentReportOrdersMeta: (state): IOrdersLocalMeta => {
    return state.currentReportOrdersMeta
  },

  /**
   * isLoadingCampaigns
   * ? Процесс загрузки списка кампаний
   *
   * @param {OrdersState} state data
   * @returns {boolean} процесс загрузки списка кампаний
   */
  isLoadingCampaigns: (state): boolean => {
    return state.isLoadingCampaigns
  },

  /**
   * isLoadingReportOrders
   * ? Процесс загрузки списка ордеров
   *
   * @param {OrdersState} state data
   * @returns {boolean} процесс загрузки списка ордеров
   */
  isLoadingReportOrders: (state): boolean => {
    return state.isLoadingReportOrders
  },

  /**
   * isLoadingExportOrders
   * ? Процесс экспорта списка ордеров
   *
   * @param {OrdersState} state data
   * @returns {boolean} процесс экспорта списка ордеров
   */
  isLoadingExportOrders: (state): boolean => {
    return state.isLoadingExportOrders
  },
}
