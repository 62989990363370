import type {
  IOperationState,
  TOperationModule,
} from '@/store/modules/client/operation/types'

import { getters } from '@/store/modules/client/operation/getters'
import { actions } from '@/store/modules/client/operation/actions'
import { mutations } from '@/store/modules/client/operation/mutations'

const state: IOperationState = {
  operation: {},
  operationId: '',
  operationTimeoutId: 0,
  isLoadingGetOperation: false,
  isLoadingCreateOperation: false,
}

export const operation: TOperationModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
