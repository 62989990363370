import type { MutationTree } from 'vuex'
import type { ReportOrderResponse, IReportCampaignItem } from '@/api/types'
import type {
  OrdersState,
  IOrdersLocalMeta,
  IOrderCampaignsLocalMeta,
} from '@/store/modules/admin/orders/types'

type OrdersTree = MutationTree<OrdersState>

import { isEmptyObject } from '@/helpers/checks'
import { setLoading } from '@/helpers/mutation-functions'

export const mutations: OrdersTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_REPORT_ORDERS
   * ? Установить список ордеров
   *
   * @param {OrdersState} state data
   * @param {Array<ReportOrderResponse>} orders список ордеров
   * @returns {void}
   */
  SET_REPORT_ORDERS: (
    state: OrdersState,
    orders: Array<ReportOrderResponse>
  ): void => {
    state.reportOrders = orders
  },

  /**
   * CLEAR_REPORT_ORDERS
   * ? Очистить список ордеров
   *
   * @param {OrdersState} state data
   * @returns {void}
   */
  CLEAR_REPORT_ORDERS: (state: OrdersState): void => {
    state.reportOrders = []
  },

  /**
   * SET_REPORT_ORDERS_CURRENT_META
   * ? Установить текущие мета данные списка ордеров
   *
   * @param {OrdersState} state data
   * @param {IOrdersLocalMeta} meta текущие мета данные списка ордеров
   * @returns {void}
   */
  SET_REPORT_ORDERS_CURRENT_META: (
    state: OrdersState,
    meta: IOrdersLocalMeta
  ): void => {
    if (isEmptyObject(meta)) {
      state.currentReportOrdersMeta = structuredClone(
        state.defaultReportOrdersMeta
      )
      return
    }

    state.currentReportOrdersMeta = {
      ...state.currentReportOrdersMeta,
      ...meta,
    }
  },

  /**
   * SET_ORDER_CAMPAIGNS_CURRENT_META
   * ? Установить текущие мета данные списка кампаний
   *
   * @param {OrdersState} state data
   * @param {IOrderCampaignsLocalMeta} meta текущие мета данные списка кампаний
   * @returns {void}
   */
  SET_ORDER_CAMPAIGNS_CURRENT_META: (
    state: OrdersState,
    meta: IOrderCampaignsLocalMeta
  ): void => {
    if (isEmptyObject(meta)) {
      state.currentOrderCampaignsMeta = structuredClone(
        state.defaultOrderCampaignsMeta
      )
      return
    }

    state.currentOrderCampaignsMeta = {
      ...state.currentOrderCampaignsMeta,
      ...meta,
    }
  },

  /**
   * ADD_CAMPAIGNS
   * ? Добавить список кампаний
   *
   * @param {OrdersState} state data
   * @param {IReportCampaignItem[]} campaigns список кампаний
   * @returns {void}
   */
  ADD_CAMPAIGNS: (
    state: OrdersState,
    campaigns: IReportCampaignItem[]
  ): void => {
    state.campaigns.push(...campaigns)
  },

  /**
   * SET_CAMPAIGNS
   * ? Установить список кампаний
   *
   * @param {OrdersState} state data
   * @param {IReportCampaignItem[]} campaigns список кампаний
   * @returns {void}
   */
  SET_CAMPAIGNS: (
    state: OrdersState,
    campaigns: IReportCampaignItem[]
  ): void => {
    state.campaigns = campaigns
  },

  /**
   * CLEAR_CAMPAIGNS
   * ? Очистить список кампаний
   *
   * @param {OrdersState} state data
   * @returns {void}
   */
  CLEAR_CAMPAIGNS: (state: OrdersState): void => {
    state.campaigns = []
  },
}
