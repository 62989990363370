import type { MutationTree } from 'vuex'
import type {
  TAccount,
  SkuResponse,
  OrderResponse,
  ProductResponse,
  IReportCampaignItem,
} from '@/api/types'
import type { LicenseState } from '@/store/modules/admin/license/types'

type LicenseTree = MutationTree<LicenseState>

import { setLoading } from '@/helpers/mutation-functions'

export const mutations: LicenseTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_CURRENT_LICENSE
   * ? Установить текущую лицензию
   *
   * @param {LicenseState} state data
   * @param {ProductResponse} license текущая лицензия
   * @returns {void}
   */
  SET_CURRENT_LICENSE: (
    state: LicenseState,
    license: ProductResponse
  ): void => {
    state.license = license
  },

  /**
   * SET_CURRENT_USER
   * ? Установить текущего пользователя
   *
   * @param {LicenseState} state data
   * @param {TAccount} user текущий пользователь
   * @returns {void}
   */
  SET_CURRENT_USER: (state: LicenseState, user: TAccount): void => {
    state.user = user
  },

  /**
   * SET_CURRENT_ORDER
   * ? Установить текущий ордер
   *
   * @param {LicenseState} state data
   * @param {OrderResponse} order текущий ордер
   * @returns {void}
   */
  SET_CURRENT_ORDER: (state: LicenseState, order: OrderResponse): void => {
    state.order = order
  },

  /**
   * SET_CURRENT_SKU
   * ? Установить текущий sku
   *
   * @param {LicenseState} state data
   * @param {SkuResponse} sku текущий sku
   * @returns {void}
   */
  SET_CURRENT_SKU: (state: LicenseState, sku: SkuResponse): void => {
    state.sku = sku
  },

  /**
   * SET_CURRENT_CAMPAIGN
   * ? Установить текущую кампанию
   *
   * @param {LicenseState} state data
   * @param {IReportCampaignItem} campaign текущая кампания
   * @returns {void}
   */
  SET_CURRENT_CAMPAIGN: (
    state: LicenseState,
    campaign: IReportCampaignItem
  ): void => {
    state.campaign = campaign
  },

  /**
   * CLEAR_LICENSE_STATES
   * ? Очистить все состояния текущей лицензии
   *
   * @param {LicenseState} state data
   * @returns {void}
   */
  CLEAR_LICENSE_STATES: (state: LicenseState): void => {
    state.sku = null
    state.user = null
    state.order = null
    state.license = null
  },
}
