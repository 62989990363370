import type { MutationTree } from 'vuex'
import type {
  IArchivesState,
  ArchivesLocalMeta,
  IArchiveVersionPayload,
} from '@/store/modules/client/archives/types'
import type { IWrBackupItemResponse } from '@/api/types'

type ArchivesTree = MutationTree<IArchivesState>

import Vue from 'vue'
import { isEmptyObject } from '@/helpers/checks'
import { setLoading } from '@/helpers/mutation-functions'

export const mutations: ArchivesTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_ARCHIVES
   * ? Установить список архивов
   *
   * @param {IArchivesState} state data
   * @param {IWrBackupItemResponse[]} archives список архивов
   * @returns {void}
   */
  SET_ARCHIVES: (
    state: IArchivesState,
    archives: IWrBackupItemResponse[]
  ): void => {
    state.archives = archives
  },

  /**
  /**
   * SET_ARCHIVE_VERSIONS
   * ? Установить список версий архивов
   *
   * @param {IArchivesState} state data
   * @param {IArchiveVersionPayload} payload список версий резервных копий
   * @returns {void}
   */
  SET_ARCHIVE_VERSIONS: (
    state: IArchivesState,
    payload: IArchiveVersionPayload
  ): void => {
    const { name, versions } = payload
    Vue.set(state.versions, name, versions)
  },

  /**
   * DELETE_ARCHIVE
   * ? Удалить архив
   *
   * @param {IArchivesState} state data
   * @param {string} archive название архива
   * @returns {void}
   */
  DELETE_ARCHIVE: (state: IArchivesState, archive: string): void => {
    state.archives = state.archives.filter(({ name }) => name !== archive)
  },

  /**
   * CLEAR_ARCHIVES
   * ? Очистить список архивов
   *
   * @param {IArchivesState} state data
   * @returns {void}
   */
  CLEAR_ARCHIVES: (state: IArchivesState): void => {
    state.archives = []
  },

  /**
   * SET_ARCHIVES_CURRENT_META
   * ? Установить текущие мета данные списка архивов
   *
   * @param {IArchivesState} state data
   * @param {ArchivesLocalMeta} meta текущие мета данные списка архивов
   * @returns {void}
   */
  SET_ARCHIVES_CURRENT_META: (
    state: IArchivesState,
    meta: ArchivesLocalMeta
  ): void => {
    if (isEmptyObject(meta)) {
      state.currentArchivesMeta = structuredClone(state.defaultArchivesMeta)
      return
    }

    state.currentArchivesMeta = {
      ...state.currentArchivesMeta,
      ...meta,
    }
  },
}
