import type {
  BackupsState,
  BackupsModule,
  BackupsLocalMeta,
} from '@/store/modules/client/backups/types'

import { getters } from '@/store/modules/client/backups/getters'
import { actions } from '@/store/modules/client/backups/actions'
import { mutations } from '@/store/modules/client/backups/mutations'

const getBackupsMeta = (): BackupsLocalMeta => {
  return {
    page: 1,
    display_name: '',
    direction: 'desc',
    order_by: 'created_at',
  }
}

const state: BackupsState = {
  backups: [],
  versions: {},
  groupLoadingBackupVersions: {},
  isLoadingBackups: false,
  isLoadingBackupDelete: false,
  isLoadingBackupDownload: false,
  currentBackupsMeta: getBackupsMeta(),
  defaultBackupsMeta: getBackupsMeta(),
}

export const backups: BackupsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
