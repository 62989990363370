import type {
  ICampaignState,
  TCampaignModule,
} from '@/store/modules/admin/campaign/types'

import { getters } from '@/store/modules/admin/campaign/getters'
import { actions } from '@/store/modules/admin/campaign/actions'
import { mutations } from '@/store/modules/admin/campaign/mutations'

const state: ICampaignState = {
  campaign: {},
  licenses: [],
  isLoadingCampaign: false,
  isLoadingEditCampaign: false,
  isLoadingDeleteCampaign: false,
  isLoadingPublishCampaign: false,
  isLoadingArchiveCampaign: false,
}

export const campaign: TCampaignModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
