import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  IErrorResponse,
  ISessionPayload,
  ISessionResponse,
} from '@/api/types'
import type {
  ISessionState,
  IRecreateSession,
} from '@/store/modules/client/session/types'

type TSessionAction = ActionTree<ISessionState, RootState>
type TSessionContext = ActionContext<ISessionState, RootState>

import api from '@/api'
import { API_ERROR } from '@/config/constants'

const {
  PASSWORD_REQUIRED,
  SESSION_NOT_FOUND,
  INVALID_SESSION_ID,
  INCORRECT_PASSWORD,
} = API_ERROR

const hasSessionError = (error: IErrorResponse): boolean =>
  [
    PASSWORD_REQUIRED,
    SESSION_NOT_FOUND,
    INVALID_SESSION_ID,
    INCORRECT_PASSWORD,
  ].includes(error.code)

export const actions: TSessionAction = {
  /**
   * getSession
   * ? Получить сессию для проверки ее активности
   *
   * @param {TSessionContext} ctx context
   * @param {string} sessionId идентификатор сессии
   * @returns {Promise<ISessionResponse>} данные сессии
   */
  getSession: (
    { commit }: TSessionContext,
    sessionId: string
  ): Promise<ISessionResponse> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'get-session',
    })

    return api.webrestore.getSession(sessionId).finally(() =>
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'get-session',
      })
    )
  },

  /**
   * createSession
   * ? Создать сессию для зашифрованных бэкапов
   *
   * @param {TSessionContext} ctx context
   * @param {ISessionPayload} data тело запроса
   * @param {string} data.backup_name имя бэкапа
   * @param {string} data.password пароль от бэкапа
   * @returns {Promise<void>}
   */
  createSession: (
    { commit }: TSessionContext,
    data: ISessionPayload
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'create-session',
    })

    return api.webrestore
      .createSession(data)
      .then((data) => commit('SET_SESSION', data))
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'create-session',
        })
      )
  },

  /**
   * awaitingUserConfirmation
   * ? Ожидать подтверждения ввода пароля для создания сессии
   *
   * @param {Function} externalPromise внешняя функция
   * @returns {Promise<string>} внешние данные обещаний
   */
  awaitingUserConfirmation: (
    { commit, getters, dispatch },
    externalPromise: () => Promise<string>
  ): Promise<string> => {
    let time = 0
    const stepTime = 1000
    const finishTime = 5 * 60 * 1000 // 5 минут

    /**
     * При возникновении ошибок
     * - открывает модальное окно
     */
    commit('SET_VISIBILITY_ENCRYPTED_MODAL', true)

    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        const hasSessionId = getters['hasSessionId']
        const isVisibleEncryptedModal = getters['isVisibleEncryptedModal']

        time += stepTime

        /**
         * Если обнаружился id сессии для текущего бэкапа или архива
         * - очищаем интервал
         * - выполняем обработку внешней функции
         */
        if (hasSessionId) {
          clearInterval(intervalId)

          /**
           * В случае успеха у внешней функции возвращаем данные обещания
           * При возникновении ошибок внешней функции обрабатываем ее в методе handleSessionErrors
           */
          externalPromise()
            .then((data) => resolve(data))
            .catch((error) =>
              dispatch('handleSessionErrors', {
                error,
                externalPromise,
              }).then((data) => resolve(data))
            )

          return
        }

        /**
         * При закрытии модального окна
         * - очищаем интервал
         */
        if (!isVisibleEncryptedModal) {
          clearInterval(intervalId)
        }

        /**
         * Если в течении 5 минут нет никаких действий
         * - очищаем интервал
         * - очищаем ошибку
         * - закрывается модальное окно
         * - пользователя перекидывает на станицу списков бэкапов или архивов
         */
        if (time >= finishTime) {
          clearInterval(intervalId)

          commit('CLEAR_SERVER_ERROR', null, { root: true })
          commit('SET_VISIBILITY_ENCRYPTED_MODAL', false)
        }
      }, stepTime)
    })
  },

  /**
   * handleSessionErrors
   * ? Обработать ошибки сессии
   *
   * @param {IRecreateSession} params параметры сессии
   * @returns {Promise<string | IErrorResponse>} внешние данные обещаний
   */
  handleSessionErrors: (
    { commit, dispatch, getters },
    params: IRecreateSession
  ): Promise<string | IErrorResponse> => {
    const { error, externalPromise } = params

    if (!hasSessionError(error)) {
      return Promise.reject(error)
    }

    if ([PASSWORD_REQUIRED, INCORRECT_PASSWORD].includes(error.code)) {
      /**
       * В случае ошибок, PASSWORD_REQUIRED или INCORRECT_PASSWORD
       *  - появляется модальное окно для ввода пароля
       *  - вешается таймер
       *  - ждем пока пользователь введет пароль
       *  - после подтверждения пароля создается сессия
       *  - закрывается модальное окно
       *  - пользователь может работать с бэкапами
       */

      return dispatch('awaitingUserConfirmation', externalPromise)
    }

    /**
     * В случае ошибок, SESSION_NOT_FOUND или INVALID_SESSION_ID
     *  - удаляем ранее созданную сессию
     *  - пересоздаем сессию
     */
    commit('DELETE_SESSION', getters.currentDecodedFullBackupOrArchiveName)

    return dispatch('generalSessionCreation', externalPromise)
  },

  /**
   * generalSessionCreation
   * ? Создать сессию с выполнением переданного запроса
   *
   * @param {TSessionContext} ctx context
   * @param {Function<Promise<string>>} externalPromise внешняя функция с обещанием
   * @returns {Promise<string | IErrorResponse>} внешние данные
   */
  generalSessionCreation: (
    { dispatch, getters }: TSessionContext,
    externalPromise: () => Promise<string>
  ): Promise<string | IErrorResponse> => {
    /**
     * Если уже сессия создана для выбранного бэкапа или архива
     * - выполняем обработку внешней функции
     * - при возникновении ошибок внешней функции обрабатываем ее в методе handleSessionErrors
     */
    if (getters['hasSessionId']) {
      return externalPromise().catch((error) =>
        dispatch('handleSessionErrors', { error, externalPromise })
      )
    }

    /**
     * Если сессия не создана
     * - выполняем создании с пустым паролем
     */
    return dispatch('createSession', {
      password: '',
      backup_name: getters['currentBackupOrArchiveName'],
    })
      .then(() => {
        /**
         * В случае успешно созданной сессии (на текущий момент отработает только для незашифрованных бэкапов или архивов, т.к. ввода пароля не требуется)
         * - выполняем обработку внешней функции
         * - при возникновении ошибок внешней функции обрабатываем ее в методе handleSessionErrors
         */
        return externalPromise().catch((error) =>
          dispatch('handleSessionErrors', { error, externalPromise })
        )
      })
      .catch((error) =>
        dispatch('handleSessionErrors', { error, externalPromise })
      )
  },
}
