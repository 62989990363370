import type { MutationTree } from 'vuex'
import type { SkuState } from '@/store/modules/admin/sku/types'
import { setLoading } from '@/helpers/mutation-functions'
import type { SkuResponse } from '@/api/types'

type SkuTree = MutationTree<SkuState>

export const mutations: SkuTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_CURRENT_SKU
   * ? Установить текущий SKU
   *
   * @param {SkuState} state data
   * @param {SkuResponse} sku текущий Sku
   * @returns {void}
   */
  SET_CURRENT_SKU: (state: SkuState, sku: SkuResponse): void => {
    state.currentSku = sku
  },

  /**
   * CLEAR_CURRENT_SKU
   * ? Очистить текущий SKU
   *
   * @param {SkuState} state data
   * @returns {void}
   */
  CLEAR_CURRENT_SKU(state: SkuState): void {
    state.currentSku = null
  },
}
