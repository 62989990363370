import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { IReportCampaignItem } from '@/api/types'
import type {
  ICampaignsState,
  ICampaignsLocalMeta,
} from '@/store/modules/admin/campaigns/types'

type TCampaignsTree = GetterTree<ICampaignsState, RootState>

import {
  getStatus,
  getFormattedDate,
  getFormattedSimpleValue,
  getFormattedLicenseProductName,
} from '@/helpers/formatted-functions'

export const getters: TCampaignsTree = {
  /**
   * reportCampaigns
   * ? Список кампаний
   *
   * @param {ICampaignsState} state data
   * @returns {IReportCampaignItem[]} список кампаний
   */
  reportCampaigns: ({
    reportCampaigns,
  }: ICampaignsState): IReportCampaignItem[] => {
    return reportCampaigns.map((campaign) => {
      const {
        id,
        name,
        state,
        product_id,
        start_date,
        finish_date,
        description,
        created_date,
      } = campaign

      return {
        ...campaign,
        formatStatus: getStatus(state),
        formatCampaignId: getFormattedSimpleValue(id),
        formatStartDate: getFormattedDate(start_date),
        formatFinishDate: getFormattedDate(finish_date),
        formatCreatedDate: getFormattedDate(created_date),
        formatCampaignName: getFormattedSimpleValue(name),
        formatDescription: getFormattedSimpleValue(description),
        formatProductName: getFormattedLicenseProductName(product_id),
      }
    })
  },

  /**
   * currentReportCampaignsMeta
   * ? Текущие мета данные кампаний
   *
   * @param {ICampaignsState} state data
   * @returns {ICampaignsLocalMeta} текущие мета данные кампаний
   */
  currentReportCampaignsMeta: (state: ICampaignsState): ICampaignsLocalMeta => {
    return state.currentReportCampaignsMeta
  },

  /**
   * isLoadingReportCampaigns
   * ? Процесс загрузки списка кампаний
   *
   * @param {ICampaignsState} state data
   * @returns {boolean} процесс загрузки списка кампаний
   */
  isLoadingReportCampaigns: (state: ICampaignsState): boolean => {
    return state.isLoadingReportCampaigns
  },

  /**
   * isLoadingAddCampaign
   * ? Процесс добавления кампании
   *
   * @param {ICampaignsState} state data
   * @returns {boolean} процесс добавления кампании
   */
  isLoadingAddCampaign: (state: ICampaignsState): boolean => {
    return state.isLoadingAddCampaign
  },

  /**
   * isLoadingExportCampaigns
   * ? Процесс экспорта списка кампаний
   *
   * @param {ICampaignsState} state data
   * @returns {boolean} процесс экспорта списка кампаний
   */
  isLoadingExportCampaigns: (state: ICampaignsState): boolean => {
    return state.isLoadingExportCampaigns
  },
}
