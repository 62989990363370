import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { adminLang } from './admin'
import { clientLang } from './client'
import { businessLang } from './business'
import { LOCALE } from '@/config/constants'
import { getPreferredLocale } from '@/helpers/locale'

Vue.use(VueI18n)

export default new VueI18n({
  locale: getPreferredLocale(),
  messages: {
    [LOCALE.EN]: { ...clientLang.en, ...businessLang.en },
    [LOCALE.RU]: { ...clientLang.ru, ...adminLang.ru, ...businessLang.ru },
  },
  pluralizationRules: {
    [LOCALE.RU]: function (choice, choicesLength) {
      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2
      }
      if (!teen && endsWithOne) {
        return 1
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return choicesLength < 4 ? 2 : 3
    },
  },
})
