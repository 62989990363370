import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type { IOperationPayload, TStateOperationResponse } from '@/api/types'
import type { IOperationState } from '@/store/modules/client/operation/types'

type TOperationAction = ActionTree<IOperationState, RootState>
type TOperationContext = ActionContext<IOperationState, RootState>

import api from '@/api'

export const actions: TOperationAction = {
  /**
   * getOperation
   * ? Получить состояние текущей операции
   *
   * @param {TOperationContext} ctx context
   * @param {string} id тело запроса для создания операции
   * @returns {Promise<TStateOperationResponse>} состояние текущей операции
   */
  getOperation: (
    { commit }: TOperationContext,
    id: string
  ): Promise<TStateOperationResponse> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'get-operation',
    })

    return api.webrestore
      .getOperation(id)
      .then((operation) => {
        commit('SET_OPERATION', operation)

        return operation
      })
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'get-operation',
        })
      )
  },

  /**
   * createOperation
   * ? Создать операцию на извлечение содержимого бэкапов из кэша
   *
   * @param {TOperationContext} ctx context
   * @param {IOperationPayload} data тело запроса для создания операции
   * @returns {Promise<string>} идентификатор операции
   */
  createOperation: (
    { commit, dispatch, rootGetters }: TOperationContext,
    data: IOperationPayload
  ): Promise<string> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'create-operation',
    })

    const fetchOperation = () => {
      const sessionId = rootGetters['session/sessionId']
      const payload = {
        ...data,
        backup_session_id: sessionId,
      }

      return api.webrestore
        .createOperation(payload)
        .then(({ id }) => {
          commit('SET_OPERATION_ID', id)

          return id
        })
        .finally(() =>
          commit('SET_LOADING_PROCESS', {
            loading: false,
            name: 'create-operation',
          })
        )
    }

    return dispatch('session/generalSessionCreation', fetchOperation, {
      root: true,
    })
  },
}
