import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { IOperationState } from '@/store/modules/client/operation/types'

type TOperationGetter = GetterTree<IOperationState, RootState>

export const getters: TOperationGetter = {
  /**
   * operationId
   * ? Получить идентификатор операции
   *
   * @param {IOperationState} state data
   * @returns {string} идентификатор операции
   */
  operationId: (state: IOperationState): string => {
    return state.operationId
  },

  /**
   * operationTimeoutId
   * ? Получить идентификатор таймаута операции
   *
   * @param {IOperationState} state data
   * @returns {number} идентификатор таймаута операции
   */
  operationTimeoutId: (state: IOperationState): number => {
    return state.operationTimeoutId
  },

  /**
   * hasOperationId
   * ? Признак существования идентификатора операции
   *
   * @param {IOperationState} state data
   * @returns {boolean} признак существования идентификатора операции
   */
  hasOperationId: (state: IOperationState): boolean => {
    return state.operationId !== ''
  },

  /**
   * isLoadingGetOperation
   * ? Процесс загрузки состояния операции
   *
   * @param {IOperationState} state data
   * @returns {boolean} процесс загрузки состояния операции
   */
  isLoadingGetOperation: (state: IOperationState): boolean => {
    return state.isLoadingGetOperation
  },

  /**
   * isLoadingCreateOperation
   * ? Процесс создания операции
   *
   * @param {IOperationState} state data
   * @returns {boolean} процесс создания операции
   */
  isLoadingCreateOperation: (state: IOperationState): boolean => {
    return state.isLoadingCreateOperation
  },
}
