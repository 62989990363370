import type { IWrBackupVersionItem } from '@/api/types'

import sumBy from 'lodash/sumBy'

interface IVersionsPayload {
  name: string
  versions: TVersions
}

type TMapVersions = Record<string, IWrBackupVersionItem[]>

type TVersions = IWrBackupVersionItem[] | TMapVersions

/**
 * getLastVersion
 * ? Получить список всех версий текущего бэкапа / архива
 *
 * @param {IVersionsPayload} params параметры
 * @param {string} params.name имя бэкапа / архива
 * @param {TVersions} params.versions мапа со всеми версиями бэкапов / архивов или список версий текущего бэкапа / архива
 * @returns {IWrBackupVersionItem[]} список всех версий текущего бэкапа / архива
 */
const getCurrentVersions = ({
  name,
  versions,
}: IVersionsPayload): IWrBackupVersionItem[] => {
  return Array.isArray(versions) ? versions : versions[name]
}

/**
 * getLastVersion
 * ? Получить последнюю версию текущего бэкапа / архива
 *
 * @param {IVersionsPayload} params параметры
 * @param {string} params.name имя бэкапа / архива
 * @param {TVersions} params.versions мапа со всеми версиями бэкапов / архивов или список версий текущего бэкапа / архива
 * @returns {IWrBackupVersionItem | null} последняя версия текущего бэкапа / архива
 */
export const getLastVersion = (
  params: IVersionsPayload
): IWrBackupVersionItem | null => {
  const currentVersions = getCurrentVersions(params)

  return Array.isArray(currentVersions) && currentVersions.length > 0
    ? currentVersions[currentVersions.length - 1]
    : null
}

/**
 * getTotalSize
 * ? Общий размер бэкапа / архива
 *
 * @param {IVersionsPayload} params параметры
 * @param {string} params.name имя бэкапа / архива
 * @param {TVersions} params.versions мапа со всеми версиями бэкапов / архивов или список версий текущего бэкапа / архива
 * @returns {number} общий размер
 */
export const getTotalSize = (params: IVersionsPayload): number => {
  const currentVersions = getCurrentVersions(params)

  return sumBy(currentVersions ?? [], 'size')
}

/**
 * getLastUpdatedDate
 * ? Дата последнего обновления бэкапа / архива
 *
 * @param {IVersionsPayload} params параметры
 *  @param {string} params.name имя бэкапа / архива
 * @param {TVersions} params.versions мапа со всеми версиями бэкапов / архивов или список версий текущего бэкапа / архива
 * @returns {string} дата последнего бэкапа / архива
 */
export const getLastUpdatedDate = (params: IVersionsPayload): string => {
  return getLastVersion(params)?.updated_at ?? ''
}
