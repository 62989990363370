import Vue from 'vue'
import UiScrollbar from './index.vue'
import { name, directive } from './directive'

export default {
  install() {
    Vue.directive(name, directive)
    Vue.component(UiScrollbar.name, UiScrollbar)
  },
}
