export const en = {
  /**
   * ? Product name
   */
  'product.name.cyber-backup': 'Cyber Backup',
  'product.edition.advanced': 'Advanced edition',
  'product.edition.standard': 'Standard edition',

  // ? Words
  'word.search': 'Search',

  // ? Dictionary
  'dictionary.key-count': '0 keys | {count} key | {count} keys',

  // ? Modals
  'modal.product-key.header.title': 'Key',
  'modal.download-product.header.title': 'Download distribution: {name}',

  /**
   * ? COMPONENT: Notify
   */
  'component.notify.message-success.company-updated':
    'Information on {name} has been changed',
  'component.notify.message-success.copy-serial-id': 'Serial key copied',
  'component.notify.message-error.copy-serial-id':
    'Failed to copy the serial key',

  /**
   * ? Edition staff
   */
  'edition.staff.name.mailbox': 'Mailbox',
  'edition.staff.name.mailbox-5': 'Mailbox • 5 mailboxes',
  'edition.staff.name.mailbox-25': 'Mailbox • 25 mailboxes',
  'edition.staff.name.mailbox-50': 'Mailbox • 50 mailboxes',
  'edition.staff.name.mailbox-100': 'Mailbox • 100 mailboxes',
  'edition.staff.name.mailbox-500': 'Mailbox • 500 mailboxes',
  'edition.staff.name.mailbox-1000': 'Mailbox • 1000 mailboxes',
  'edition.staff.name.workstation': 'Workstation',
  'edition.staff.name.virtual-host': 'Virtual Host',
  'edition.staff.name.server': 'Server',
  'edition.staff.name.universal-license': 'Universal',
  'edition.staff.name.postgresql': 'PostgreSQL',

  /**
   * ? FIELDS: label
   */

  'field.label.name:': 'Name:',
  'field.label.order:': 'Order:',
  'field.label.email:': 'Email:',
  'field.label.data:': 'Data:',
  'field.label.address:': 'Address:',
  'field.label.branch:': 'Branch:',
  'field.label.phone:': 'Phone:',
  'field.label.country:': 'Country:',
  'field.label.surname:': 'Surname:',
  'field.label.generated:': 'Generated:',
  'field.label.subscription-period:': 'Subscription period:',

  /**
   * ? FORMS: label
   */
  'form.field.label.inn': 'INN',
  'form.field.label.unp': 'UNP',
  'form.field.label.kpp': 'KPP',
  'form.field.label.ogrn': 'OGRN',
  'form.field.label.oked': 'OKED',
  'form.field.label.company': 'Company',
  'form.field.label.main-company': 'Main company',
  'form.field.label.key': 'Key',
  'form.field.label.keys': 'Keys',
  'form.field.label.generated': 'Generated',

  /**
   * ? Buttons: label
   */
  'button.action.copy': 'Copy',
  'button.action.edit': 'Edit',
  'button.action.browse-all': 'View all',
  'button.action.download-keys': 'Download keys',

  /**
   * ? FIELDS: validate
   */
  'form.field.validate.company-required': 'Choose a company.',

  /**
   * ? FIELDS placeholder
   */
  'field.placeholder.select.search-company': 'Search by name, TIN and address',

  /**
   * ? COMPONENT: Tag
   */
  'component.tag.name.trial-license': 'Trial',
  'component.tag.name.perpetual-license': 'Perpetual',
  'component.tag.name.its-you': "It's you",

  /**
   * ? COMPONENT: Buttons
   */
  'component.button.name.documentation': 'Documentation',
  'component.button.name.buy-full-version': 'Buy full version',
  'component.button.name.buy': 'Buy',
  'component.button.name.download-distribution': 'Download distribution',
  'component.button.name.close': 'Close',

  /**
   * ? COMPONENT: Select
   */
  'component.select.search.title.company-example':
    'Example search (up to 20 results are displayed):',
  'component.select.search.text.company-name':
    'Romashka LLC, 1234567890, St. Petersburg',

  /**
   * ? COMPONENT: Table
   */
  'component.table.cell.unlimited': 'Unlimited',
  'component.table.cell.perpetual-key': 'perpetual',
  'component.table.cell.order': 'Order {number}, {period}, {date}',

  /**
   * ? COMPONENT: Buttons
   */
  'component.button.name.try': 'Try',
  'component.button.name.re-enter': 'Re-enter',
  'component.button.name.buy-key': 'Buy a key',

  /**
   * ? COMPONENT: Popover
   */
  'component.popover.distribution.title': 'To use the trial version',
  'component.popover.distribution.download': '1. Download the distribution kit',
  'component.popover.distribution.install':
    '2. Install the product and activate the trial version in the product',

  /**
   * ? COMPONENT: Header
   */
  'component.header.title.products': 'Products',
  'component.header.title.undefined': 'Section not found',
  'component.header.title.personal-account': 'Personal account',

  /**
   * ? PAGE: Error
   */
  'page.errors.title.account-type-not-found': 'Account type not defined',

  /**
   * ? PAGE: Products
   */
  'page.products.card.title.no-key': "You don't have a key yet",
  'page.products.keys': 'Product keys',
  'page.products.order-number': 'Order number',
  'page.products.subscription-period': 'Subscription period',
  'page.products.card.title.try-product': 'You want to try the product',
  'page.products.card.text.perpetual-license':
    'Purchase a perpetual or subscription-based license',
  'page.products.card.text.trial-version':
    'Select a product and get access to a fully functional trial version',

  /**
   * ? PAGE: Distributions
   */
  'page.distributions.product-list': 'List of products',
  'page.distributions.versions:': 'Versions:',

  /**
   * ? PAGE: Profile
   */
  'page.profile.button.remove-profile': 'Delete profile',
  'page.profile.edit-company.title': 'Changing company information',
  'page.profile.not-specified': 'Not specified',
  'page.profile.company-unselected': 'Unselected company',
}
