import type { MutationTree } from 'vuex'
import type {
  IOperationState,
  TPartialStateOperationResponse,
} from '@/store/modules/client/operation/types'

type TOperationMutation = MutationTree<IOperationState>

import { setLoading } from '@/helpers/mutation-functions'

export const mutations: TOperationMutation = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_OPERATION_ID
   * ? Установить идентификатор операции
   *
   * @param {IOperationState} state data
   * @param {string} id идентификатор операции
   * @returns {void}
   */
  SET_OPERATION_ID: (state: IOperationState, id: string): void => {
    state.operationId = id
  },

  /**
   * DELETE_OPERATION_ID
   * ? Удалить идентификатор операции
   *
   * @param {IOperationState} state data
   * @returns {void}
   */
  DELETE_OPERATION_ID: (state: IOperationState): void => {
    state.operationId = ''
  },

  /**
   * SET_OPERATION_TIMEOUT_ID
   * ? Установить идентификатор задержки операции
   *
   * @param {IOperationState} state data
   * @param {number} id идентификатор задержки операции
   * @returns {void}
   */
  SET_OPERATION_TIMEOUT_ID: (state: IOperationState, id: number): void => {
    state.operationTimeoutId = id
  },

  /**
   * SET_OPERATION
   * ? Установить текущее состояние операции
   *
   * @param {IOperationState} state data
   * @param {TPartialStateOperationResponse} operation текущее состояние операции
   * @returns {void}
   */
  SET_OPERATION: (
    state: IOperationState,
    operation: TPartialStateOperationResponse
  ): void => {
    state.operation = operation
  },
}
