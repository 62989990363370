import type { IStateIterator, IModules, StateType } from './types'

const clonedStates: Partial<IStateIterator> = {}
const currentStates: Partial<IStateIterator> = {}
const getState = (modules: IModules, clone = false): IStateIterator => {
  return Object.entries(modules).reduce((acc: IStateIterator, curr) => {
    const [key, { state }] = curr

    acc[key] = clone ? structuredClone(state) : state

    return acc
  }, {})
}

export const saveStates = (modules: IModules) => {
  Object.assign(clonedStates, getState(modules, true))
  Object.assign(currentStates, getState(modules, false))
}

export const clearStates = () => {
  const moduleKeys = Object.keys(clonedStates)

  moduleKeys.forEach((key) => {
    const clonedState = clonedStates[key] as StateType
    const currentState = currentStates[key] as StateType

    Object.keys(currentState ?? []).forEach((subKey: string) => {
      currentState[subKey as keyof StateType] =
        clonedState[subKey as keyof StateType]
    })
  })
}
