import type { MutationTree } from 'vuex'
import type { ProductsState } from '@/store/modules/client/products/types'
import type {
  QuotaResponse,
  ProductsResponse,
  AutoRenewOptions,
} from '@/api/types'

import Vue from 'vue'
import { v4 as uuid } from 'uuid'
import groupBy from 'lodash/groupBy'
import { setLoading } from '@/helpers/mutation-functions'

type ProductTree = MutationTree<ProductsState>

export const mutations: ProductTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_PRODUCTS
   * ? Добавить список продуктов
   *
   * @param {ProductsState} state data
   * @param {ProductsResponse} products список продуктов
   * @returns {void}
   */
  SET_PRODUCTS: (state: ProductsState, products: ProductsResponse): void => {
    state.products = products
      .filter((item) => item.is_revoked === false)
      .map((item) => {
        return {
          ...item,
          activations: item?.activations ?? {
            used: 0,
            limit: 0,
          },
          subscription: item?.subscription ?? {
            is_auto: false,
            is_active: false,
          },
        }
      })
      .sort(
        (a, b) => Date.parse(b.expiration_date) - Date.parse(a.expiration_date)
      )
  },

  /**
   * SET_CATEGORIES
   * ? Добавить список категорий продуктов
   *
   * @param {ProductsState} state data
   * @returns {void}
   */
  SET_CATEGORIES: (state: ProductsState): void => {
    state.categories = Object.entries(
      groupBy(state.products, (item) => item.sku.product_id)
    ).map(([, products]) => {
      const additionalInfo = products.reduce(
        (acc, item) => {
          acc.name = item.sku.product_id
          acc.activations.used += item.activations?.used ?? 0
          acc.activations.limit += item.activations?.limit ?? 0

          return acc
        },
        {
          name: '',
          activations: {
            used: 0,
            limit: 0,
          },
        }
      )

      return {
        products,
        id: uuid(),
        ...additionalInfo,
      }
    })
  },

  /**
   * SET_QUOTAS
   * ? Добавить список квот
   *
   * @param {ProductsState} state data
   * @param {Array<QuotaResponse>} quotas список квот
   * @returns {void}
   */
  SET_QUOTAS: (state: ProductsState, quotas: Array<QuotaResponse>): void => {
    state.quotas = quotas
  },

  /**
   * SET_AUTO_RENEW
   * ? Включить/выключить авто продление подписки
   *
   * @param {ProductsState} state data
   * @param {AutoRenewOptions} options опции AutoRenewOptions
   * @returns {void}
   */
  SET_AUTO_RENEW: (state: ProductsState, options: AutoRenewOptions): void => {
    const { id, isActivate } = options
    const product = state.products.find((product) => product.id === id)

    if (product) {
      product.subscription.is_active = isActivate
    }
  },

  /**
   * DEACTIVATE_MACHINE_COUNTER
   * ? Деактивировать счетчик компьютера
   *
   * @param {ProductsState} state data
   * @param {string} id идентификатор продукта
   * @returns {void}
   */
  DEACTIVATE_MACHINE_COUNTER: (state: ProductsState, id: string): void => {
    const product = state.products.find((item) => item.id === id)

    if (product) {
      const {
        activations: { used },
      } = product

      if (used > 0) {
        Vue.set(product.activations, 'used', used - 1)
      }
    }
  },
}
