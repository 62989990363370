import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  IWrBackupItemData,
  IWrBackupVersionItem,
  IWrBackupItemResponse,
} from '@/api/types'
import type {
  BackupState,
  IBackupItems,
  BackupLocalMeta,
  IBackupPathParams,
  IDirectoryBackupMeta,
  IWrLocaleBackupContact,
  IDirectoryBackupOperation,
  IWrLocaleBackupItemResponse,
} from '@/store/modules/client/backup/types'

type BackupTree = GetterTree<BackupState, RootState>

import { App } from '@/main'
import cloneDeep from 'lodash/cloneDeep'
import { getTotalSize, getLastUpdatedDate } from '@/helpers/storage'
import {
  getWrIcons,
  ICON_TYPE_CONTENT,
  ICON_TYPE_CATEGORIES,
  WR_MIME_TYPE_CATEGORY,
  MOBILE_CATEGORY_BACKUPS,
} from '@/helpers/wr-icons'
import {
  getFormattedDate,
  getFormattedSize,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

const getContact = (
  data: IWrBackupItemData | undefined
): Partial<IWrLocaleBackupContact> => {
  if (data === undefined) {
    return {}
  }

  const phone = data.phones?.[0] ?? null
  const email = data.emails?.[0] ?? null

  return {
    phone,
    email,
    amountPhones: data.phones?.length ?? 0,
    amountEmails: data.emails?.length ?? 0,
    formatPhone: getFormattedSimpleValue(phone),
    formatEmail: getFormattedSimpleValue(email),
  }
}

export const getters: BackupTree = {
  /**
   * mapBackupData
   * ? Объект бэкапов со списком директорий/файлов, статусом и id операции
   *
   * @param {BackupState} state data
   * @returns {Partial<IDirectoryBackupOperation>} объект бэкапов со списком директорий/файлов, статусом и id операции
   */
  mapBackupData: (state: BackupState): Partial<IDirectoryBackupOperation> => {
    return state.mapBackupData
  },

  /**
   * mapBackupMeta
   * ? Объект бэкапов с мета данными
   *
   * @param {BackupState} state data
   * @returns {Partial<IDirectoryBackupMeta>} объект бэкапов с мета данными
   */
  mapBackupMeta: (state: BackupState): Partial<IDirectoryBackupMeta> => {
    return state.mapBackupMeta
  },

  /**
   * defaultBackupMeta
   * ? Дефолтные мета данные содержимого бэкапа
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {BackupLocalMeta} дефолтные мета данные содержимого бэкапа
   */
  defaultBackupMeta: (state: BackupState, getters): BackupLocalMeta => {
    return {
      ...state.defaultBackupMeta,
      order_by:
        getters.isSubtypeBackupMobile &&
        (state.currentFolder === '' || getters.isBackupMobileCategoryContacts)
          ? 'name'
          : 'updated_at',
    }
  },

  /**
   * treeDirectoryPath
   * ? Список сформированных путей директорий дерева
   *
   * @param {BackupState} state data
   * @returns {string[]} список сформированных путей директорий дерева
   */
  treeDirectoryPath: (state: BackupState): string[] => {
    return state.treeDirectoryPath
  },

  /**
   * currentView
   * ? Текущий вид резервных копий
   *
   * @param {BackupState} state data
   * @returns {string} текущий вид резервных копий
   */
  currentView: (state: BackupState): string => {
    return state.currentView
  },

  /**
   * currentBackupFolder
   * ? Имя текущей директории (папки) бэкапа
   *
   * @param {BackupState} state data
   * @returns {string} имя текущей директории (папки) бэкапа
   */
  currentBackupFolder: (state: BackupState): string => {
    return state.currentFolder
  },

  /**
   * currentBackupSubtype
   * ? Тип текущей резервной копии
   *
   * @param {BackupState} state data
   * @returns {string} тип текущей резервной копии
   */
  currentBackupSubtype: (state: BackupState): string => {
    return state.currentBackupSubtype
  },

  /**
   * currentBackupName
   * ? Имя текущей резервной копии
   *
   * @param {BackupState} state data
   * @returns {string} имя текущей резервной копии
   */
  currentBackupName: (state: BackupState): string => {
    return state.currentBackupName
  },

  /**
   * currentBackupDisplayName
   * ? Текущее отображаемое имя бэкапа
   *
   * @param {BackupState} state data
   * @returns {string} текущее отображаемое имя бэкапа
   */
  currentBackupDisplayName: (state: BackupState): string => {
    return state.currentBackup?.display_name ?? ''
  },

  /**
   * currentBackupVersion
   * ? Текущая версия резервной копии
   *
   * @param {BackupState} state data
   * @returns {IWrBackupVersionItem | null} текущая версия резервной копии
   */
  currentBackupVersion: (state: BackupState): IWrBackupVersionItem | null => {
    return state.currentBackupVersion
  },

  /**
   * currentBackupVersionName
   * ? Название текущей версия резервной копии
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {string} название текущей версия резервной копии
   */
  currentBackupVersionName: (state: BackupState, getters): string => {
    return getters.currentBackupVersion?.name ?? ''
  },

  /**
   * currentBackupVersionList
   * ? Список версий текущей резервной копий
   *
   * @param {BackupState} state data
   * @returns {IWrBackupVersionItem[]} список версий текущей резервной копий
   */
  currentBackupVersionList: (state: BackupState): IWrBackupVersionItem[] => {
    return cloneDeep(state.currentBackupVersionList).sort(
      (a: IWrBackupVersionItem, b: IWrBackupVersionItem) =>
        Number(b.name) - Number(a.name)
    )
  },

  /**
   * currentDirectoryBackupItem
   * ? Текущий элемент директории бэкапа
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {IBackupItems} текущий элемент директории бэкапа
   */
  currentDirectoryBackupItem: (state: BackupState, getters): IBackupItems => {
    return state.mapBackupData[getters.currentBackupPathParams.backupPath] ?? {}
  },

  /**
   * currentDirectoryBackupMeta
   * ? Текущие мета данные директории бэкапа
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {BackupLocalMeta} текущие мета данные директории бэкапа
   */
  currentDirectoryBackupMeta: (
    state: BackupState,
    getters
  ): BackupLocalMeta => {
    return (
      state.mapBackupMeta[getters.currentBackupPathParams.backupPath] ??
      state.defaultBackupMeta
    )
  },

  /**
   * currentBackup
   * ? Текущая резервная копия
   *
   * @param {BackupState} state data
   * @returns {IWrLocaleBackupItemResponse | null} дерево мета данных директорий резервной копии
   */
  currentBackup: (state: BackupState): IWrLocaleBackupItemResponse | null => {
    if (state.currentBackup === null) {
      return null
    }

    const { size, name, subtype, updated_at, display_name } =
      state.currentBackup
    const params = { name, versions: state.currentBackupVersionList }
    const totalSize = getTotalSize(params)
    const lastUpdatedDate = getLastUpdatedDate(params)

    return {
      ...state.currentBackup,
      icon: getWrIcons(subtype),
      formatSize: getFormattedSize(size),
      formatTotalBackupSize: getFormattedSize(totalSize),
      formatUpdatedItemDate: getFormattedDate(updated_at),
      formatDisplayName: getFormattedSimpleValue(display_name),
      formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
      formatLastBackupUpdatedDateTime: getFormattedDate(lastUpdatedDate, true),
    }
  },

  /**
   * currentBackupDirectory
   * ? Текущая (выбранная) директория резервной копии
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {IWrBackupItemResponse[]} текущая (выбранная) директория резервной копии
   */
  currentBackupDirectory: (
    state: BackupState,
    getters
  ): IWrBackupItemResponse[] => {
    const backupItems = getters.currentDirectoryBackupItem.items ?? []

    return backupItems.map((backup: IWrBackupItemResponse) => {
      const { size, data, type, subtype, updated_at } = backup

      const iconType =
        type === 'dir' &&
        Object.values(MOBILE_CATEGORY_BACKUPS).includes(subtype)
          ? ICON_TYPE_CATEGORIES
          : ICON_TYPE_CONTENT

      return {
        ...backup,
        ...getContact(data),
        formatSize: getFormattedSize(size),
        icon: getWrIcons(subtype ?? type, iconType),
        formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
      }
    })
  },

  /**
   * currentBackupPathParams
   * ? Параметры текущего пути директории
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @param {RootState} rootState root data
   * @returns {IBackupPathParams} параметры текущего пути директории
   */
  currentBackupPathParams: (
    state: BackupState,
    getters,
    rootState
  ): IBackupPathParams => {
    const pathMatch = rootState.nav?.route?.params?.pathMatch
    const {
      treeDirectoryPath,
      currentBackupName,
      isSubtypeBackupMobile,
      currentBackupVersionName,
      currentBackupDisplayName,
    } = getters

    const backup = currentBackupName
    const path = treeDirectoryPath.join('/')
    const [backupVersion, ...dirPath] = pathMatch ? pathMatch.split('/') : []
    const version = currentBackupVersionName || backupVersion
    const queryBackupPath = `${backup}/${pathMatch ?? version}`
    const queryPath = dirPath.length > 0 ? dirPath.join('/') : path
    const getDirectories = () =>
      treeDirectoryPath.map((name: string) => {
        if (isSubtypeBackupMobile) {
          const mobileCategory = `mobile/${name}`
          const typeCategory = WR_MIME_TYPE_CATEGORY[mobileCategory]

          return typeCategory ? App.$i18n.t(typeCategory.label) : name
        }

        return name
      })
    const folder = treeDirectoryPath.at(-1) ?? currentBackupDisplayName
    const directories = [currentBackupDisplayName, ...getDirectories()]
    const backupPath = [backup, version, ...treeDirectoryPath].join('/')

    return {
      path,
      backup,
      folder,
      version,
      queryPath,
      backupPath,
      directories,
      queryBackupPath,
    }
  },

  /**
   * hasBackupVersion
   * ? Признак что версия бэкапа существует
   *
   * @param {BackupState} state data
   * @returns {boolean} признак что версия бэкапа существует
   */
  hasBackupVersion: (state: BackupState): boolean => {
    return state.currentBackupVersion !== null
  },

  /**
   * isEncryptedBackup
   * ? Признак зашифрованного бэкапа
   *
   * @param {BackupState} state data
   * @returns {boolean} признак зашифрованного бэкапа
   */
  isEncryptedBackup: (state: BackupState): boolean => {
    return Boolean(state.currentBackup?.encrypted)
  },

  /**
   * isCachedCurrentDirectoryBackupItem
   * ? Признак того, что элементы бэкапа за кешированы
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {boolean} признак того, что элементы бэкапа за кешированы
   */
  isCachedCurrentDirectoryBackupItem: (
    state: BackupState,
    getters
  ): boolean => {
    return getters.currentBackupPathParams.backupPath in state.mapBackupData
  },

  /**
   * isLoadingCurrentBackup
   * ? Процесс загрузки текущей резервной копий
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки текущей резервной копий
   */
  isLoadingCurrentBackup: (state: BackupState): boolean => {
    return state.isLoadingCurrentBackup
  },

  /**
   * isLoadingUrlBackupImage
   * ? Процесс загрузки текущего пути изображения
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки текущего пути изображения
   */
  isLoadingUrlBackupImage: (state: BackupState): boolean => {
    return state.isLoadingUrlBackupImage
  },

  /**
   * isSubtypeBackupMobile
   * ? Признак мобильной резервной копии
   *
   * @param {BackupState} state data
   * @returns {boolean} признак мобильной резервной копии
   */
  isSubtypeBackupMobile: (state: BackupState): boolean => {
    return state.currentBackupSubtype === 'mobile'
  },

  /**
   * isBackupMobileCategoryContacts
   * ? Признак мобильной резервной копии
   *
   * @param {BackupState} state data
   * @returns {boolean} признак мобильной резервной копии
   */
  isBackupMobileCategoryContacts: (state: BackupState): boolean => {
    const [, contacts] = MOBILE_CATEGORY_BACKUPS.CONTACTS.split('/')

    return state.currentFolder === contacts
  },

  /**
   * isLoadingCurrentBackupVersionList
   * ? Процесс загрузки списка версий текущей резервной копий
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки списка версий текущей резервной копий
   */
  isLoadingCurrentBackupVersionList: (state: BackupState): boolean => {
    return state.isLoadingCurrentBackupVersionList
  },

  /**
   * isLoadingFirstDirectoryBackupItems
   * ? Процесс загрузки первых директорий резервной копии
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки первых директорий резервной копии
   */
  isLoadingFirstDirectoryBackupItems: (state: BackupState): boolean => {
    return state.isLoadingFirstDirectoryBackupItems
  },

  /**
   * isLoadingNextDirectoryBackupItems
   * ? Процесс загрузки следующих директорий архива
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки следующих директорий архива
   */
  isLoadingNextDirectoryBackupItems: (state: BackupState): boolean => {
    return state.isLoadingNextDirectoryBackupItems
  },
}
