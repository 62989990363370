/**
 * escapeCommasInString
 * ? Экранировать запятые в строке
 *
 * @param {string} value строка
 * @returns {string} результирующая строка
 */
const escapeCommasInString = (value: string): string => {
  const comma = ','

  if (!value.includes(comma)) {
    return value
  }

  return value.split('').reduce((acc, symbol) => {
    acc += symbol === comma ? `\\${symbol}` : symbol

    return acc
  }, '')
}

/**
 * escapeCommas
 * ? Экранировать значения строк в массиве
 *
 * @param {string[]} data строка
 * @returns {string} результирующая строка
 */
export const escapeCommas = (data: string[]): string => {
  return data.map((value) => escapeCommasInString(value)).join(',')
}

/**
 * formatToPascalCase
 * ? Отформатировать строку в нотацию PascalCase
 *
 * @param {string} value строка
 * @param {string} separator разделить строки (по умолчанию пробел)
 * @returns {string} отформатированная строка строка
 */
export const formatToPascalCase = (value: string, separator = ' '): string => {
  return value
    .split(separator)
    .map((item) => {
      const [first, ...rest] = item.split('')
      return `${first.toUpperCase()}${rest.join('')}`
    })
    .join('')
}

/**
 * formatToCamelCase
 * ? Отформатировать строку в нотацию CamelCase
 *
 * @param {string} value строка
 * @param {string} separator разделить строки (по умолчанию пробел)
 * @returns {string} отформатированная строка строка
 */
export const formatToCamelCase = (value: string, separator = ' '): string => {
  const [first, ...rest] = formatToPascalCase(value, separator).split('')

  return `${first.toLowerCase()}${rest.join('')}`
}
