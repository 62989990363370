import type { OrderState, OrderModule } from '@/store/modules/admin/order/types'

import { getters } from '@/store/modules/admin/order/getters'
import { actions } from '@/store/modules/admin/order/actions'
import { mutations } from '@/store/modules/admin/order/mutations'

const state: OrderState = {
  sku: null,
  user: null,
  order: null,
  license: null,
  isLoadingSku: false,
  isLoadingOrder: false,
  isLoadingLicense: false,
  isLoadingUserAccount: false,
  isLoadingAllDataOrder: false,
}

export const order: OrderModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
