import type { RouteMeta } from 'vue-router'

import { initQueryParams } from '@/helpers'
import { isNumeric, isStringAsBoolean } from '@/helpers/checks'
import { setItemStorage } from '@/helpers/local-storage'
import { TABLE_TYPE, TABLE_KEY, QUERY_KEYS } from '@/config/constants'

const {
  TABLE_USER_ORDERS,
  TABLE_USER_BACKUPS,
  TABLE_REPORT_USERS,
  TABLE_REPORT_ORDERS,
  TABLE_REPORT_LICENSES,
  TABLE_REPORT_CAMPAIGNS,
} = TABLE_KEY

/**
 * getFormattedQueries
 * ? Получить отформатированные квери параметры
 *
 * @param {RouteMeta} query квери параметры
 * @returns {RouteMeta} отформатированные квери параметры
 */
const getFormattedQueries = (query: RouteMeta) => {
  return Object.fromEntries(
    Object.entries(query).map(([key, value]) => [
      key,
      isStringAsBoolean(value)
        ? JSON.parse(value)
        : isNumeric(value)
        ? Number(value)
        : value,
    ])
  )
}

/**
 * getQueryGroups
 * ? Получить отформатированные квери параметры
 *
 * @param {string} type тип определяющий таблицу
 * @param {RouteMeta} query квери параметры
 * @returns {Record<string, string | number | boolean>} сгруппированные квери параметры
 */
export const getQueryGroups = (
  type: string,
  query: Record<string, string | number | boolean>
) => {
  const defaultGroups = Object.fromEntries(
    Object.entries(QUERY_KEYS[type]).map(([key]) => [key, {}])
  )

  return Object.entries(query).reduce((acc, [key, value]) => {
    type T = keyof typeof acc

    const [[groupName]] = Object.entries(QUERY_KEYS[type]).filter(([, keys]) =>
      keys.includes(key)
    )

    acc[groupName as T] = {
      ...acc[groupName as T],
      [key]: value,
    }

    return acc
  }, defaultGroups)
}

/**
 * setQueryParams
 * ? Установить первичные квери параметры в хранилище для дальнейших манипуляций
 *
 * @returns {void}
 */
export const setQueryParams = () => {
  const { page, query } = initQueryParams()
  const formattedQuery = getFormattedQueries(query)

  if (page === 'users') {
    setItemStorage(
      TABLE_TYPE[TABLE_REPORT_USERS],
      getQueryGroups(TABLE_REPORT_USERS, formattedQuery)
    )
  }

  if (page === 'orders') {
    setItemStorage(
      TABLE_TYPE[TABLE_REPORT_ORDERS],
      getQueryGroups(TABLE_REPORT_ORDERS, formattedQuery)
    )
  }

  if (page === 'campaigns') {
    setItemStorage(
      TABLE_TYPE[TABLE_REPORT_CAMPAIGNS],
      getQueryGroups(TABLE_REPORT_CAMPAIGNS, formattedQuery)
    )
  }

  if (page === 'licenses') {
    setItemStorage(
      TABLE_TYPE[TABLE_REPORT_LICENSES],
      getQueryGroups(TABLE_REPORT_LICENSES, formattedQuery)
    )
  }

  if (query.tab === 'orders') {
    setItemStorage(
      TABLE_TYPE[TABLE_USER_ORDERS],
      getQueryGroups(TABLE_USER_ORDERS, formattedQuery)
    )
  }

  if (query.tab === 'backups') {
    setItemStorage(
      TABLE_TYPE[TABLE_USER_BACKUPS],
      getQueryGroups(TABLE_USER_BACKUPS, formattedQuery)
    )
  }
}
