import { App } from '@/main'
import { IWrIcon, IWrIcons, IWrTypeIcon } from '@/helpers/types'

export const ICON_TYPE_LIST = 'list'

export const ICON_TYPE_CONTENT = 'content'

export const ICON_TYPE_CATEGORIES = 'categories'

export const MOBILE_CATEGORY_BACKUPS = {
  MEDIA: 'mobile/media',
  CONTACTS: 'mobile/contacts',
  MESSAGES: 'mobile/messages',
  CALENDARS: 'mobile/calendars',
}

const { MEDIA, CONTACTS, MESSAGES, CALENDARS } = MOBILE_CATEGORY_BACKUPS

/**
 * getWrIcons
 * ? Получить файловые иконки для Web Restore
 *
 * @param {string} mimeType формат документа (определяет бэк)
 * @param {string} iconType формат файловых иконок для списка "list" или для содержимого "content" (определяется локально)
 * @returns {IWrIcon} объект файловой иконки
 */
export const getWrIcons = (
  mimeType: string,
  iconType = ICON_TYPE_CONTENT
): IWrIcon => {
  const WR_MIME_TYPE: IWrTypeIcon = {
    list: WR_MIME_TYPE_LIST,
    content: WR_MIME_TYPE_CONTENT,
    categories: WR_MIME_TYPE_CATEGORY,
    default: WR_MIME_TYPE_CONTENT,
  }
  const WR_ICON_TYPE = WR_MIME_TYPE[iconType] ?? WR_MIME_TYPE.default
  const { name, label, subLabel } =
    WR_ICON_TYPE[mimeType] ?? WR_ICON_TYPE.default

  return {
    name,
    label: String(App.$i18n.t(label as string)),
    subLabel: String(App.$i18n.t(subLabel as string)),
  }
}

export const WR_MIME_TYPE_CATEGORY: IWrIcons = {
  [MEDIA]: {
    name: 'wr-image',
    label: 'page.backup.mobile-name.gallery',
    subLabel: 'page.backup.mobile.view-and-download',
  },
  [CONTACTS]: {
    name: 'wr-contacts',
    label: 'page.backup.mobile-name.contacts',
    subLabel: 'page.backup.mobile.view-and-download',
  },
  [MESSAGES]: {
    name: 'wr-messages',
    label: 'page.backup.mobile-name.messages',
    subLabel: 'page.backup.mobile.download-only',
  },
  [CALENDARS]: {
    name: 'wr-calendar',
    label: 'page.backup.mobile-name.calendars',
    subLabel: 'page.backup.mobile.download-only',
  },
}

const WR_MIME_TYPE_LIST: IWrIcons = {
  default: {
    name: 'wr-file',
    label: 'document.label.wr-file',
  },
  file: {
    name: 'wr-file-backup',
    label: 'document.label.wr-file-backup',
  },
  disk: {
    name: 'wr-disk-backup',
    label: 'document.label.wr-disk-backup',
  },
  mobile: {
    name: 'wr-mobile-backup',
    label: 'document.label.wr-mobile-backup',
  },
  'entire-pc': {
    name: 'wr-full-backup',
    label: 'document.label.wr-full-backup',
  },
}

const WR_MIME_TYPE_CONTENT: IWrIcons = {
  default: {
    name: 'wr-file',
    label: 'document.label.wr-file',
  },
  dir: {
    name: 'wr-folder',
    label: 'document.label.wr-folder',
  },
  file: {
    name: 'wr-file',
    label: 'document.label.wr-file',
  },
  'text/csv': {
    name: 'wr-csv',
    label: 'document.label.wr-csv',
  },
  'application/x-msdos-program': {
    name: 'wr-dll',
    label: 'document.label.wr-dll',
  },
  'application/msword': {
    name: 'wr-doc',
    label: 'document.label.wr-doc',
  },
  'application/msword-template': {
    name: 'wr-dot',
    label: 'document.label.wr-dot',
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    name: 'wr-docx',
    label: 'document.label.wr-docx',
  },
  'text/vnd.graphviz': {
    name: 'wr-dot',
    label: 'document.label.wr-dot',
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': {
    name: 'wr-dotx',
    label: 'document.label.wr-dotx',
  },
  'application/vnd.ms-outlook': {
    name: 'wr-email',
    label: 'document.label.wr-email',
  },
  'application/vnd.microsoft.portable-executable': {
    name: 'wr-exe',
    label: 'document.label.wr-exe',
  },
  'application/x-ms-dos-executable': {
    name: 'wr-exe',
    label: 'document.label.wr-exe',
  },
  'font/collection': {
    name: 'wr-font',
    label: 'document.label.wr-ttc',
  },
  'font/otf': {
    name: 'wr-font',
    label: 'document.label.wr-otf',
  },
  'font/sfnt': {
    name: 'wr-font',
    label: 'document.label.wr-sfnt',
  },
  'font/ttf': {
    name: 'wr-font',
    label: 'document.label.wr-ttf',
  },
  'font/woff': {
    name: 'wr-font',
    label: 'document.label.wr-woff',
  },
  'font/woff2': {
    name: 'wr-font',
    label: 'document.label.wr-woff2',
  },
  'application/vnd.dtg.local.html': {
    name: 'wr-html',
    label: 'document.label.wr-html',
  },
  'text/html': {
    name: 'wr-html',
    label: 'document.label.wr-html',
  },
  'image/vnd.sealedmedia.softseal.jpg': {
    name: 'wr-image',
    label: 'document.label.wr-jpg',
  },
  'image/jpeg': {
    name: 'wr-image',
    label: 'document.label.wr-jpeg',
  },
  'image/gif': {
    name: 'wr-image',
    label: 'document.label.wr-gif',
  },
  'image/png': {
    name: 'wr-image',
    label: 'document.label.wr-png',
  },
  'image/tiff': {
    name: 'wr-image',
    label: 'document.label.wr-tiff',
  },
  'image/tiff-fx': {
    name: 'wr-image',
    label: 'document.label.wr-tiff-fx',
  },
  'image/bmp': {
    name: 'wr-image',
    label: 'document.label.wr-bmp',
  },
  'application/x-iso9660-image': {
    name: 'wr-iso',
    label: 'document.label.wr-iso',
  },
  'application/x-cd-image': {
    name: 'wr-iso',
    label: 'document.label.wr-iso',
  },
  'application/vnd.efi.iso': {
    name: 'wr-iso',
    label: 'document.label.wr-iso',
  },
  'application/vnd.apple.keynote': {
    name: 'wr-keynote',
    label: 'document.label.wr-keynote',
  },
  'audio/mpeg': {
    name: 'wr-audio',
    label: 'document.label.wr-mp3',
  },
  'audio/mp4': {
    name: 'wr-audio',
    label: 'document.label.wr-mp4',
  },
  'audio/flac': {
    name: 'wr-audio',
    label: 'document.label.wr-flac',
  },
  'audio/mpa': {
    name: 'wr-audio',
    label: 'document.label.wr-mpa',
  },
  'audio/ac3': {
    name: 'wr-audio',
    label: 'document.label.wr-ac3',
  },
  'audio/sp-midi': {
    name: 'wr-audio',
    label: 'document.label.wr-sp-midi',
  },
  'audio/midi': {
    name: 'wr-audio',
    label: 'document.label.wr-sp-midi',
  },
  'audio/x-au': {
    name: 'wr-audio',
    label: 'document.label.wr-x-au',
  },
  'audio/x-aiff': {
    name: 'wr-audio',
    label: 'document.label.wr-x-aiff',
  },
  'audio/x-wav': {
    name: 'wr-audio',
    label: 'document.label.wr-x-wav',
  },
  'audio/x-ms-wma': {
    name: 'wr-audio',
    label: 'document.label.wr-x-ms-wma',
  },
  'application/vnd.oasis.opendocument.presentation': {
    name: 'wr-odp',
    label: 'document.label.wr-odp',
  },
  'application/vnd.collabio.xodocuments.presentation': {
    name: 'wr-odp',
    label: 'document.label.wr-xodp',
  },
  'application/vnd.oasis.opendocument.spreadsheet': {
    name: 'wr-ods',
    label: 'document.label.wr-ods',
  },
  'application/vnd.collabio.xodocuments.spreadsheet': {
    name: 'wr-ods',
    label: 'document.label.wr-xods',
  },
  'application/vnd.oasis.opendocument.text': {
    name: 'wr-odt',
    label: 'document.label.wr-odt',
  },
  'application/vnd.collabio.xodocuments.document': {
    name: 'wr-odt',
    label: 'document.label.wr-xodt',
  },
  'application/pdf': {
    name: 'wr-pdf',
    label: 'document.label.wr-pdf',
  },
  'application/vnd.openxmlformats-officedocument.presentationml.template': {
    name: 'wr-potx',
    label: 'document.label.wr-potx',
  },
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': {
    name: 'wr-ppsx',
    label: 'document.label.wr-ppsx',
  },
  'application/vnd.ms-powerpoint': {
    name: 'wr-ppt',
    label: 'document.label.wr-ppt',
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    name: 'wr-pptx',
    label: 'document.label.wr-ppt',
  },
  'image/vnd.adobe.photoshop': {
    name: 'wr-raw',
    label: 'document.label.wr-photoshop',
  },
  'image/x-sony-arw': {
    name: 'wr-raw',
    label: 'document.label.wr-sony-arw',
  },
  'image/x-sony-srf': {
    name: 'wr-raw',
    label: 'document.label.wr-sony-srf',
  },
  'image/x-sony-sr2': {
    name: 'wr-raw',
    label: 'document.label.wr-sony-sr2',
  },
  'image/x-canon-cr2': {
    name: 'wr-raw',
    label: 'document.label.wr-canon-cr2',
  },
  'image/x-canon-crw': {
    name: 'wr-raw',
    label: 'document.label.wr-canon-crw',
  },
  'image/x-adobe-dng': {
    name: 'wr-raw',
    label: 'document.label.wr-adobe-dng',
  },
  'image/x-kodak-kdc': {
    name: 'wr-raw',
    label: 'document.label.wr-kodak-kdc',
  },
  'image/x-kodak-dcr': {
    name: 'wr-raw',
    label: 'document.label.wr-kodak-dcr',
  },
  'image/x-epson-erf': {
    name: 'wr-raw',
    label: 'document.label.wr-epson-erf',
  },
  'image/x-minolta-mrw': {
    name: 'wr-raw',
    label: 'document.label.wr-minolta-mrw',
  },
  'image/x-nikon-nef': {
    name: 'wr-raw',
    label: 'document.label.wr-nikon-nef',
  },
  'image/x-nikon-nrw': {
    name: 'wr-raw',
    label: 'document.label.wr-nikon-nrw',
  },
  'image/x-olympus-orf': {
    name: 'wr-raw',
    label: 'document.label.wr-olympus-orf',
  },
  'image/x-pentax-pef': {
    name: 'wr-raw',
    label: 'document.label.wr-pentax-pef',
  },
  'image/x-fuji-raf': {
    name: 'wr-raw',
    label: 'document.label.wr-fuji-raf',
  },
  'image/x-panasonic-raw': {
    name: 'wr-raw',
    label: 'document.label.wr-panasonic-raw',
  },
  'text/rtf': {
    name: 'wr-rtf',
    label: 'document.label.wr-rtf',
  },
  'application/rtf': {
    name: 'wr-rtf',
    label: 'document.label.wr-rtf',
  },
  'text/plain': {
    name: 'wr-txt',
    label: 'document.label.wr-txt',
  },
  'text/x-vcard': {
    name: 'wr-vcard',
    label: 'document.label.wr-vcard',
  },
  'text/vcard': {
    name: 'wr-vcard',
    label: 'document.label.wr-vcard',
  },
  'image/svg+xml': {
    name: 'wr-vector',
    label: 'document.label.wr-svg',
  },
  'application/postscript': {
    name: 'wr-vector',
    label: 'document.label.wr-eps',
  },
  'image/x-coreldraw': {
    name: 'wr-vector',
    label: 'document.label.wr-cdr',
  },
  'application/vnd.corel-draw': {
    name: 'wr-vector',
    label: 'document.label.wr-cdr',
  },
  'image/x-eps': {
    name: 'wr-vector',
    label: 'document.label.wr-eps',
  },
  'image/x-coreldrawtemplate': {
    name: 'wr-vector',
    label: 'document.label.wr-cdt',
  },
  'image/webp': {
    name: 'wr-image',
    label: 'document.label.wr-webp',
  },
  'image/wmf': {
    name: 'wr-vector',
    label: 'document.label.wr-wmf',
  },
  'video/mpeg': {
    name: 'wr-video',
    label: 'document.label.wr-mpeg',
  },
  'video/mp4': {
    name: 'wr-video',
    label: 'document.label.wr-mp4',
  },
  'video/x-msvideo': {
    name: 'wr-video',
    label: 'document.label.wr-avi',
  },
  'video/x-sgi-movie': {
    name: 'wr-video',
    label: 'document.label.wr-movie',
  },
  'video/raw': {
    name: 'wr-video',
    label: 'document.label.wr-raw',
  },
  'video/avi': {
    name: 'wr-video',
    label: 'document.label.wr-avi',
  },
  'video/quicktime': {
    name: 'wr-video',
    label: 'document.label.wr-qt',
  },
  'video/x-matroska': {
    name: 'wr-video',
    label: 'document.label.wr-mkv',
  },
  'video/x-ms-wm': {
    name: 'wr-video',
    label: 'document.label.wr-wm',
  },
  'video/x-ms-wmv': {
    name: 'wr-video',
    label: 'document.label.wr-wmv',
  },
  'video/x-ms-wmx': {
    name: 'wr-video',
    label: 'document.label.wr-wmx',
  },
  'application/vnd.ms-excel': {
    name: 'wr-xls',
    label: 'document.label.wr-xls',
  },
  'application/vnd.ms-excel.sheet.macroEnabled.12': {
    name: 'wr-xls',
    label: 'document.label.wr-xls',
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    name: 'wr-xlsx',
    label: 'document.label.wr-xls',
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': {
    name: 'wr-xltx',
    label: 'document.label.wr-xls',
  },
  'application/zip': {
    name: 'wr-archive',
    label: 'document.label.wr-zip',
  },
  'application/vnd.rar': {
    name: 'wr-archive',
    label: 'document.label.wr-rar',
  },
  'application/x-tar': {
    name: 'wr-archive',
    label: 'document.label.wr-tar',
  },
  'application/x-bzip2': {
    name: 'wr-archive',
    label: 'document.label.wr-bz2',
  },
  'application/x-bzip': {
    name: 'wr-archive',
    label: 'document.label.wr-bz2',
  },
  'application/gzip': {
    name: 'wr-archive',
    label: 'document.label.wr-gzip',
  },
  'application/x-7z-compressed': {
    name: 'wr-archive',
    label: 'document.label.wr-7z',
  },
  'application/vnd.ms-cab-compressed': {
    name: 'wr-archive',
    label: 'document.label.wr-cab',
  },
  'application/java-archive': {
    name: 'wr-archive',
    label: 'document.label.wr-jar',
  },
  'application/x-java-archive': {
    name: 'wr-archive',
    label: 'document.label.wr-jar',
  },
  'application/x-gtar-compressed': {
    name: 'wr-archive',
    label: 'document.label.wr-gtar',
  },
  'application/vnd.android.ota': {
    name: 'wr-apk',
    label: 'document.label.wr-ota',
  },
  'application/vnd.android.package-archive': {
    name: 'wr-apk',
    label: 'document.label.wr-apk',
  },
  'application/fb2': {
    name: 'wr-ebook',
    label: 'document.label.wr-fb2',
  },
  'application/x-fictionbook+xml': {
    name: 'wr-ebook',
    label: 'document.label.wr-fb2',
  },
  'text/fb2+xml': {
    name: 'wr-ebook',
    label: 'document.label.wr-fb2',
  },
  'application/epub+zip': {
    name: 'wr-ebook',
    label: 'document.label.wr-epub',
  },
  'application/x-mobipocket-ebook': {
    name: 'wr-ebook',
    label: 'document.label.wr-mobi',
  },
}
