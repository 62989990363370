import type { RootState } from '@/store/types'
import type { ActionTree, ActionContext } from 'vuex'
import type { SkuForm } from '@/components/forms/admin/types'
import type { SkuFeature, SkuState } from '@/store/modules/admin/sku/types'
import type { SkuNamesPayload, TUpgradeSkuPayload } from '@/api/types'
import api from '@/api'
import { getSkuPayload } from '@/store/modules/admin/sku/index'

type SkuTree = ActionTree<SkuState, RootState>
type SkuContext = ActionContext<SkuState, RootState>

export const actions: SkuTree = {
  /**
   * addSku
   * ? Создать новый SKU
   *
   * @param {SkuContext} ctx context
   * @param {SkuForm} form форма для создания/редактирования SKU
   * @returns {Promise<void>}
   */
  addSku({ commit, rootGetters }: SkuContext, form: SkuForm): Promise<void> {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'add-sku',
    })

    const featureIds = rootGetters['skus/features']
      .filter((feature: SkuFeature) => !feature.system)
      .map((feature: SkuFeature) => feature.id)

    return api.lk.createSku(getSkuPayload(form, featureIds)).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'add-sku',
      })
    })
  },

  /**
   * editSku
   * ? Изменить SKU
   *
   * @param {SkuContext} ctx context
   * @param {SkuForm} form форма для создания/редактирования SKU
   * @returns {Promise<void>}
   */
  editSku(
    { commit, dispatch, getters }: SkuContext,
    form: SkuForm
  ): Promise<void> {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'edit-sku',
    })

    const featureIds = getters['skuFeatures']
      .filter((feature: SkuFeature) => feature.active)
      .map((feature: SkuFeature) => feature.id)

    return api.lk
      .editSku(getSkuPayload(form, featureIds))
      .then(() => dispatch('fetchCurrentSku', form.id))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'edit-sku',
        })
      })
  },

  /**
   * editNamesSku
   * ? Изменить название или описание SKU
   *
   * @param {SkuContext} ctx context
   * @param {SkuForm} payload параметры SKU
   * @returns {Promise<void>}
   */
  editNamesSku(
    { commit, dispatch }: SkuContext,
    payload: SkuNamesPayload
  ): Promise<void> {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'edit-sku',
    })
    return api.lk
      .editNamesSku(payload)
      .then(() => dispatch('fetchCurrentSku', payload.id))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'edit-sku',
        })
      })
  },

  /**
   * editSku
   * ? Изменить список возможностей SKU
   *
   * @param {SkuContext} ctx context
   * @param {string[]} featureIds список возможностей
   * @returns {Promise<void>}
   */
  editSkuFeatures(
    { commit, dispatch, state, getters }: SkuContext,
    featureIds: string[]
  ): Promise<void> {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'edit-sku',
    })
    const sku = state['currentSku']
    if (!sku) {
      return Promise.resolve()
    }

    const activeSystemSkuFeatureIds = getters['skuFeatures']
      .filter((feature: SkuFeature) => feature.active && feature.system)
      .map((feature: SkuFeature) => feature.id)

    return api.lk
      .editSkuFeatures(sku.id, [...featureIds, ...activeSystemSkuFeatureIds])
      .then(() => dispatch('fetchCurrentSku', sku.id))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'edit-sku',
        })
      })
  },

  /**
   * editPriceSku
   * ? Изменить цену SKU
   *
   * @param {SkuContext} ctx context
   * @param {number} price цена SKU
   * @returns {Promise<void>}
   */
  editPriceSku(
    { commit, dispatch, state }: SkuContext,
    price: number
  ): Promise<void> {
    const sku = state['currentSku']
    if (!sku) {
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'editing-price-sku',
    })
    return api.lk
      .editPriceSku(sku.id, price)
      .then(() => dispatch('fetchCurrentSku', sku.id))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'editing-price-sku',
        })
      })
  },

  /**
   * editSkuUpgrades
   * ? Изменить апгрейды SKU
   *
   * @param {SkuContext} ctx context
   * @param {TUpgradeSkuPayload} upgrades цена SKU
   * @returns {Promise<void>}
   */
  editSkuUpgrades(
    { commit, dispatch, state }: SkuContext,
    upgrades: TUpgradeSkuPayload
  ): Promise<void> {
    const sku = state['currentSku']
    if (!sku) {
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'editing-upgrades-sku',
    })

    return api.lk
      .editUpgradesSku(sku.id, upgrades)
      .then(() => dispatch('fetchCurrentSku', sku.id))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'editing-upgrades-sku',
        })
      })
  },

  /**
   * deleteSku
   * ? Удалить текущий SKU
   *
   * @param {SkuContext} ctx context
   * @param {string} id идентификатор SKU
   * @returns {Promise<void>}
   */
  deleteSku: ({ commit }: SkuContext, id: string): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'delete-sku' })

    return api.lk.deleteSku(id).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'delete-sku',
      })
    })
  },

  /**
   * archiveSku
   * ? Архивировать текущий SKU
   *
   * @param {SkuContext} ctx context
   * @param {string} id идентификатор SKU
   * @returns {Promise<void>}
   */
  archiveSku: ({ commit, dispatch }: SkuContext, id: string): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'archiving-sku' })

    return api.lk
      .archiveSku(id)
      .then(() => dispatch('fetchCurrentSku', id))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'archiving-sku',
        })
      })
  },

  /**
   * publishSku
   * ? Опубликовать текущий SKU
   *
   * @param {SkuContext} ctx context
   * @param {boolean} publishToSoftLine опубликовать в магазине Софтлайн
   * @returns {Promise<void>}
   */
  publishSku: async (
    { commit, state, dispatch }: SkuContext,
    publishToSoftLine: boolean
  ): Promise<void> => {
    const sku = state['currentSku']
    if (!sku) {
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'publish-sku' })

    // Нужно установить store_id для SKU если необходимо опубликовать SKU в магазине Софтлайн
    if (publishToSoftLine && !sku.store_id) {
      await api.lk.editSku({ ...sku, store_id: 'softline' })
    }

    return api.lk
      .publishSku(sku)
      .then(() => dispatch('fetchCurrentSku', sku.id))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'publish-sku',
        })
      })
  },

  /**
   * fetchCurrentSku
   * ? Получить текущий SKU
   *
   * @param {SkuContext} ctx context
   * @param {string} id идентификатор SKU
   * @returns {Promise<void>}
   */
  fetchCurrentSku({ commit, dispatch }: SkuContext, id: string): Promise<void> {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'current-sku',
    })

    return api.lk
      .getCurrentSku(id)
      .then((sku) => commit('SET_CURRENT_SKU', sku))
      .then(() => dispatch('skus/fetchSkus', null, { root: true }))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'current-sku',
        })
      })
  },
}
