import { App } from '@/main'
import { setMetaTitle } from '@/helpers'
import { ROLES, LOCALE } from '@/config/constants'
import {
  hasItemStorage,
  getItemStorage,
  setItemStorage,
} from '@/helpers/local-storage'

/**
 * changeLocale
 * ? Изменить локализацию приложения
 *
 * @param {string} locale передаваемая локализация
 * @param {boolean} isSaveToLocaleStorage признак сохранения значения в LS
 * @returns {void}
 */
export const changeLocale = (locale: string, isSaveToLocaleStorage = false) => {
  const _i18n = App.$i18n

  if (!_i18n) {
    return
  }

  const locales = {
    [LOCALE.RU]: LOCALE.RU,
    [LOCALE.EN]: LOCALE.EN,
    default: _i18n.locale === LOCALE.RU ? LOCALE.EN : LOCALE.RU,
  }

  _i18n.locale = locales[locale] ?? locales.default

  setMetaTitle()

  if (isSaveToLocaleStorage) {
    setItemStorage('locale', {
      lk_user: _i18n.locale,
    })
  }
}

/**
 * getPreferredLocale
 * ? Получить (предпочитаемую / выбранную / дефолтную) локализацию для интерфейса
 *
 * @returns {string} локализацию
 */
export const getPreferredLocale = () => {
  const role = getItemStorage('role')
  const locales = {
    ru: LOCALE.RU,
    en: LOCALE.EN,
    [LOCALE.RU]: LOCALE.RU,
    [LOCALE.EN]: LOCALE.EN,
  }

  if (role !== ROLES.USER) {
    return LOCALE.RU
  }

  const browserLocale = navigator.language
  const hasLocale = hasItemStorage('locale')
  const defaultLocale = process.env.VUE_APP_LANGUAGE || LOCALE.RU

  if (hasLocale) {
    const { [role]: selectedLocale } = getItemStorage('locale')

    return locales[selectedLocale] ?? defaultLocale
  }

  return locales[browserLocale] ?? defaultLocale
}
