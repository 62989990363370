import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  OrdersState,
  IOrdersLocalPayload,
  ICampaignsLocalPayload,
  IExportOrdersLocalPayload,
} from '@/store/modules/admin/orders/types'

import api from '@/api'
import { getMetaGroups } from '@/helpers'
import { EReportType } from '@/config/enums'
import { exportToXLSX } from '@/helpers/xlsx'
import { replaceRouteQuery } from '@/helpers/router'
import { TABLE_KEY, TABLE_TYPE } from '@/config/constants'
import { getItemStorage, setItemStorage } from '@/helpers/local-storage'

const { TABLE_REPORT_ORDERS, LIST_SERVICE_CAMPAIGNS } = TABLE_KEY

type OrdersTree = ActionTree<OrdersState, RootState>
type OrdersContext = ActionContext<OrdersState, RootState>

export const actions: OrdersTree = {
  /**
   * fetchReportOrders
   * ? Извлечь список ордеров
   *
   * @param {OrdersContext} ctx context
   * @param {IOrdersLocalPayload} payload параметры конфигурации запроса ордеров
   * @returns {Promise<void>}
   */
  fetchReportOrders: async (
    { state, commit, dispatch, rootGetters }: OrdersContext,
    payload: IOrdersLocalPayload
  ): Promise<void> => {
    const sessionId =
      rootGetters['reportSession/ordersSession'].id ||
      (await dispatch('reportSession/createSession', EReportType.ORDERS, {
        root: true,
      }))
    const metaState = state.currentReportOrdersMeta
    const currentMetaGroups = getMetaGroups(
      TABLE_REPORT_ORDERS,
      payload?.meta,
      metaState.meta
    )
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = { ...paging, ...sort, ...search, ...filters }
    const ordersPayload = {
      ...combinedMetaList,
      session_id: sessionId,
    }

    setItemStorage(TABLE_TYPE[TABLE_REPORT_ORDERS], currentMetaGroups)

    commit('SET_REPORT_ORDERS_CURRENT_META', { meta: currentMetaGroups })
    commit('SET_LOADING_PROCESS', { loading: true, name: 'report-orders' })

    return api.report
      .getReportOrders(ordersPayload)
      .then(({ data, meta }) => {
        const searchValue = String(search?.query ?? '')
        const orders = searchValue.length === 1 ? [] : data

        commit('SET_REPORT_ORDERS', orders)
        commit('SET_REPORT_ORDERS_CURRENT_META', {
          items: meta.limit,
          total: meta.total,
        })

        replaceRouteQuery(combinedMetaList)
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'report-orders',
        })
      })
  },

  /**
   * fetchCampaigns
   * ? Извлечь список кампаний
   *
   * @param {OrdersContext} ctx context
   * @param {ICampaignsLocalPayload} payload параметры конфигурации запроса кампаний
   * @returns {Promise<void>}
   */
  fetchCampaigns: async (
    { state, commit }: OrdersContext,
    payload: ICampaignsLocalPayload
  ): Promise<void> => {
    const { installType = 'add' } = payload
    const commitMeta = installType === 'add' ? 'ADD_CAMPAIGNS' : 'SET_CAMPAIGNS'
    const metaState = state.currentOrderCampaignsMeta
    const currentMetaGroups = getMetaGroups(
      LIST_SERVICE_CAMPAIGNS,
      payload.meta,
      metaState.meta
    )
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = { ...paging, ...sort, ...search, ...filters }
    const campaignsPayload = { ...combinedMetaList }

    commit('SET_ORDER_CAMPAIGNS_CURRENT_META', { meta: currentMetaGroups })
    commit('SET_LOADING_PROCESS', { loading: true, name: 'campaigns' })

    return api.lk
      .getCampaigns(campaignsPayload)
      .then(({ data, meta }) => {
        commit(commitMeta, data)
        commit('SET_ORDER_CAMPAIGNS_CURRENT_META', {
          page: meta.page,
          total: meta.total,
        })
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'campaigns',
        })
      })
  },

  /**
   * exportOrders
   * ? Экспортировать ордера в файл
   *
   * @param {IExportOrdersLocalPayload} payload промежуточные данные
   * @param {OrdersContext} param0 context
   * @returns {Promise<void>}
   */
  exportOrders: (
    { commit, rootGetters }: OrdersContext,
    { type, filename }: IExportOrdersLocalPayload
  ) => {
    const { search, filters } = getItemStorage(type) ?? {}
    const ordersPayload = {
      ...search,
      ...filters,
      content_type: 'text/csv',
      session_id: rootGetters['reportSession/ordersSession'].id,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'export-orders' })

    return api.report
      .exportOrders(ordersPayload)
      .then((file) => exportToXLSX(file, filename))
      .finally(() => {
        commit('SET_LOADING_PROCESS', { loading: false, name: 'export-orders' })
      })
  },
}
