import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  IReportSessionState,
  IReportSessionParams,
} from '@/store/modules/admin/report-session/types'

type TReportSessionAction = ActionTree<IReportSessionState, RootState>
type TReportSessionContext = ActionContext<IReportSessionState, RootState>

import api from '@/api'
import { DELAY } from '@/config/constants'
import { EReportType } from '@/config/enums'
import { formatToCamelCase } from '@/helpers/strings'

export const actions: TReportSessionAction = {
  /**
   * createSession
   * ? Создать сессию
   *
   * @param {TReportSessionContext} ctx context
   * @param {EReportType} reportType тип репорта
   * @returns {Promise<string>} идентификатор сессии репорта
   */
  createSession: (
    { commit }: TReportSessionContext,
    reportType: EReportType
  ): Promise<string> => {
    const data = { report_type: reportType }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'create-report-session',
    })

    return api.report
      .createReportSession(data)
      .then(({ id }) => {
        commit('SET_REPORT_SESSION_ID', { id, reportType })

        return id
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'create-report-session',
        })
      })
  },

  /**
   * fetchSession
   * ? Извлечь сессию
   *
   * @param {TReportSessionContext} ctx context
   * @param {IReportSessionParams} options параметры сессии
   * @returns {Promise<void>}
   */
  fetchSession: (
    { commit, dispatch, getters }: TReportSessionContext,
    { id, reportType }: IReportSessionParams
  ): Promise<void> => {
    const session = getters[`${formatToCamelCase(reportType, '_')}Session`]
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'get-report-session',
    })

    return api.report
      .getReportSession(id)
      .then((status) => {
        clearTimeout(session.timeoutId)

        commit('SET_REPORT_SESSION_STATUS', { status, reportType })
        commit('SET_REPORT_SESSION_TIMEOUT_ID', {
          reportType,
          timeoutId: setTimeout(() => {
            if (!session.observe) {
              clearTimeout(session.timeoutId)
              return
            }

            dispatch('fetchSession', { id, reportType })
          }, DELAY.REPORT_SESSION),
        })
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'get-report-session',
        })
      })
  },
}
